import { Box, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import SampleClassCard from "./SampleClassCard";
import { sampleClass_data } from "@/constants/dataPublicPages";

const SampleClassesSection: React.FC = () => {
  const classes = sampleClass_data.classes;
  return (
    <Box py={16}>
      <Container maxW="container.xl">
        <Heading as="h2" size="2xl" mb={8} textAlign="center" color="white">
          Çevrimiçi İngilizce derslerimizden örnekler
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
          {classes.map((classItem, index) => (
            <SampleClassCard key={index} {...classItem} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default SampleClassesSection;
