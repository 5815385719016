import React, { useEffect } from 'react';
import { Box, SimpleGrid, Heading, Spinner, Text, Center } from '@chakra-ui/react';
import { FaBook, FaUserGraduate, FaChalkboardTeacher, FaUsers, FaShoppingCart, FaVideo } from 'react-icons/fa';
import StatCard from '@/components/admin/stats/StatCard';
import ActiveCoursesList from '@/components/admin/stats/ActiveCoursesList';
import IncomingZoomSessionsList from '@/components/admin/stats/IncomingZoomSessionsList';
 
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { fetchDashboardData } from '@/features/admin/dashboard/adminDashboardActions';

const AdminDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { stats, activeCourses, incomingZoomSessions, isLoading, error } = 
    useAppSelector((state) => state.adminDashboard);

  useEffect(() => {
    dispatch(fetchDashboardData());
    
    // Optional: Set up auto-refresh
    const intervalId = setInterval(() => {
      dispatch(fetchDashboardData());
    }, 5 * 60 * 1000); // Refresh every 5 minutes

    return () => clearInterval(intervalId);
  }, [dispatch]);

  if (isLoading && !stats) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  if (!stats) {
    return null;
  }

  return (
    <Box>
      <Heading as="h1" size="xl" mb={6} color="gray.700">
        Admin Dashboard
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 4, lg: 4 }} spacing={6} mb={6}>
        <StatCard 
          label="Active Courses" 
          value={stats.activeCourses.count} 
          icon={<FaBook />} 
          change={stats.activeCourses.trend} 
          bg={'blue.600'}
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Learners" 
          value={stats.learners.count} 
          icon={<FaUserGraduate />} 
          change={stats.learners.trend} 
          bg={'red.600'}
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Coaches" 
          value={stats.teachers.count} 
          icon={<FaChalkboardTeacher />} 
          change={stats.teachers.trend} 
          bg='orange.400'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Telegram Channels" 
          value={stats.telegramChannels.count} 
          icon={<FaChalkboardTeacher />} 
          change={stats.telegramChannels.trend} 
          bg='teal.500'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Total Courses" 
          value={stats.totalCourses.count} 
          icon={<FaBook />} 
          change={stats.totalCourses.trend} 
          bg='green.500'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Total Users" 
          value={stats.totalUsers.count} 
          icon={<FaUsers />} 
          change={stats.totalUsers.trend} 
          bg='purple.600'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Total Sold Course" 
          value={stats.totalSoldCourses.count} 
          icon={<FaShoppingCart />} 
          change={stats.totalSoldCourses.trend} 
          bg='green.700'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <StatCard 
          label="Private Lessons" 
          value={stats.zoomSessions.count} 
          icon={<FaVideo />} 
          change={stats.zoomSessions.trend} 
          bg='pink.500'
          boxShadow="dark-lg"
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={6}>
        <ActiveCoursesList courses={activeCourses} />
        <IncomingZoomSessionsList sessions={incomingZoomSessions} />
      </SimpleGrid>

      
    </Box>
  );
};

export default AdminDashboard;