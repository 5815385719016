import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { Card, CardBody, Heading } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NewUserCountChart: React.FC = () => {
  const data: ChartData<'bar'> = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'New Users',
        data: [50, 60, 55, 70],
        backgroundColor: 'rgba(136, 132, 216, 0.6)',
      },
      {
        label: 'New Learners',
        data: [30, 35, 40, 45],
        backgroundColor: 'rgba(130, 202, 157, 0.6)',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'New User Count (Weekly)',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>New User Count (Weekly)</Heading>
        <Bar data={data} options={options} />
      </CardBody>
    </Card>
  );
};

export default NewUserCountChart;