import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExtendedUser } from "./userTypes";
import { fecthUser } from "./userActions";

interface IUserState {
  currentUser: IExtendedUser | null;
  isLoading: boolean; // Loading state for async operations
  error: string | null; // Error message, if any
}
const initialState: IUserState = {
  currentUser: null,
  isLoading: false,
  error: null,
};
 
const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
     
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fecthUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(
        fecthUser.fulfilled,
        (state, action: PayloadAction<IExtendedUser>) => {
          state.error = null;
          state.isLoading = false;
          state.currentUser = action.payload;
        }
      )
      .addCase(fecthUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError } = UserSlice.actions;
export default UserSlice.reducer;
