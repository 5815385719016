import * as yup from "yup";
import { DayOfWeek, IPrerequisites } from "@/features/admin/course/adminCourseTypes";

//export type CourseFormData = Omit<ICourse, "id" | "status" | "currentEnrollments">;
// Form data type without computed/auto fields
export type CourseFormData = {
  name: string;
  description: string;
  typeId: string;
  startDate: string;
  endDate: string;
  primaryCoachId: string;
  secondaryCoachId?: string;
  price: number;
  maxEnrollments: number;
  moduleDuration: number;
  prerequisites: IPrerequisites;
  zoomLink: string;
  zoomTime: {
    dayOfWeek: DayOfWeek;
    hour: number;
    minute: number;
    duration: number;
  };
  isActive: boolean;
};

// Validation schema
export const courseSchema = yup.object().shape({
  name: yup.string().min(3, "Course name must be at least 3 characters").required("Course name is required"),
  description: yup.string().required("Description is required"),
  typeId: yup.string().required("Course type is required"),
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),
  /*startDate: yup.date().required("Start date is required").min(new Date(), "Start date must be in the future"),
    endDate: yup
      .date()
      .required("End date is required")
      .test("endDate", "End date must be after start date", function (endDate) {
        const startDate = this.parent.startDate;
        if (!startDate || !endDate) return true;
        return new Date(endDate) > new Date(startDate);
      }),*/
  primaryCoachId: yup.string().required("Primary coach is required"),
  secondaryCoachId: yup.string(),
  price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
  maxEnrollments: yup.number().required("Maximum enrollments is required").min(1, "Must allow at least one enrollment"),
  moduleDuration: yup.number().required("Module duration is required").min(1, "Duration must be at least 1 day"),
  prerequisites: yup.object({
    levelTestScore: yup.number().min(0, "Score must be between 0 and 100").max(100, "Score must be between 0 and 100"),
    requiredCourseTypeId: yup.string(),
  }),
  zoomLink: yup.string().required("Zoom meeting link is required").url("Must be a valid URL"),
  zoomTime: yup.object().shape({
    dayOfWeek: yup.string().required("Meeting day is required").oneOf(Object.values(DayOfWeek), "Invalid day of week"),
    hour: yup.number().required("Hour is required").min(0, "Hour must be between 0 and 23").max(23, "Hour must be between 0 and 23").integer("Hour must be a whole number"),
    minute: yup
      .number()
      .required("Minute is required")
      .min(0, "Minute must be between 0 and 59")
      .max(59, "Minute must be between 0 and 59")
      .integer("Minute must be a whole number"),
    duration: yup
      .number()
      .required("Duration is required")
      .min(15, "Duration must be at least 15 minutes")
      .max(240, "Duration cannot exceed 4 hours")
      .integer("Duration must be a whole number"),
  }),
  isActive: yup.boolean().default(true),
});
