import React from "react";
import { Card, CardHeader, CardBody, FormControl, FormLabel, FormErrorMessage, Select, Stack, Heading } from "@chakra-ui/react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { ICoachUser } from "@/features/admin/coaches/adminCoachTypes";
import { CourseFormData } from "../courseFormConstants";
import { formMode } from "@/types/globalTypes";
import { Users } from "lucide-react";

interface InstructorsSectionProps {
  register: UseFormRegister<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
  coaches: ICoachUser[];
  
}

const InstructorsSection: React.FC<InstructorsSectionProps> = ({ register, errors, mode, coaches }) => {
 

  return (
    <Card>
      <CardHeader bg="pink.500" py={3}>
        <Heading size="sm" color="white">
          Course Instructors
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          {/* Primary Coach Selection */}
          <FormControl isInvalid={!!errors.primaryCoachId}>
            <FormLabel>Primary Instructor</FormLabel>
            <Select {...register("primaryCoachId")} icon={<Users />} isReadOnly={mode === formMode.VIEW}>
              <option value="">Select Primary Instructor</option>
              {coaches.map((coach) => (
                <option key={coach.id} value={coach.id}>
                  {coach.user.username}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.primaryCoachId?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default InstructorsSection;
