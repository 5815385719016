// src/components/admin/stats/ActiveCoursesList.tsx
import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Progress, Card, CardBody, Heading, Skeleton } from "@chakra-ui/react";
import { ActiveCourse } from "@/features/admin/dashboard/adminDashboardTypes";

interface ActiveCoursesListProps {
  courses: ActiveCourse[];
  isLoading?: boolean;
}

const ActiveCoursesList: React.FC<ActiveCoursesListProps> = ({ courses, isLoading = false }) => {
  // Helper function to format date
  const formatDate = (date: Date): string => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <Heading size="md" mb={4}>
            Active Courses
          </Heading>
          <Box overflowX="auto">
            <Skeleton height="200px" />
          </Box>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>
          Active Courses
        </Heading>
        <Box overflowX="auto">
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Course Name</Th>
                <Th>Started On</Th>
                <Th>Will End On</Th>
                <Th>Remaining Days</Th>
                <Th>Progress</Th>
              </Tr>
            </Thead>
            <Tbody>
              {courses.map((course, index) => (
                <Tr key={index}>
                  <Td>{course.name}</Td>
                  <Td>{formatDate(course.startedOn)}</Td>
                  <Td>{formatDate(course.willEndOn)}</Td>
                  <Td>{course.remainingDays} days</Td>
                  <Td>
                    <Progress value={course.progress} size="sm" colorScheme={course.progress < 30 ? "purple" : course.progress < 60 ? "pink" : "green"} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};

export default ActiveCoursesList;
