import api from "@/utils/api";
import { IExtendedUser } from "./userTypes";
import {
  IFetchDataOptions,
  IFetchDataResult,
} from "@/components/common/ReusableTable/ReusableTable";
import { IUserDistributionData } from "@/components/admin/charts/UserDistributionChart";
import { AxiosError } from "axios";

 
export const getUserList = async (
  reqData: IFetchDataOptions
): Promise<IFetchDataResult<IExtendedUser>> => {
  const response = await api.get("users", { params: reqData });
  return response.data;
};
export const getUserDistribution = async (): Promise<
  IUserDistributionData | { error: string }
> => {
  try {
    const response = await api.get<IUserDistributionData>(
      "/users/distribution"
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.message || "Failed to fetch user distribution",
      };
    }
    return {
      error:
        "An unexpected error occurred while fetching the user distribution",
    };
  }
};
