import React, { useState } from "react";
 
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "@/hooks/useRedux";
import { requestPasswordReset } from "@/features/auth/authActions";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

type FormData = yup.InferType<typeof schema>;

const RequestPasswordReset: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      const resultAction = await dispatch(requestPasswordReset(data.email));
      if (requestPasswordReset.fulfilled.match(resultAction)) {
        toast({
          title: "Password Reset Requested",
          description: "A reset link has been sent to your email.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        reset(); // Reset the form
       
      } else if (requestPasswordReset.rejected.match(resultAction)) {
        throw new Error(resultAction.payload as string);
      }
    } catch (error) {
      toast({
        title: "Password Reset Request Failed",
        description: error instanceof Error ? error.message : "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        Reset Password
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" type="email" {...register("email")} />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            width="100%"
            isLoading={isLoading}
          >
            Request Password Reset
          </Button>
        </VStack>
      </form>
      <Text mt={4} textAlign="center">
        We'll send a password reset link to your email if an account exists.
      </Text>
    </Box>
  );
};

export default RequestPasswordReset;