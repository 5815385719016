import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICoach } from "./adminCoachTypes";
import { createCoachApi, getUserwithEmail, updateCoachApi } from "./adminCoachApi";
import { AxiosError } from "axios";
import { IExtendedUser } from "@/features/users/userTypes";

export const getCoachUserThunk = createAsyncThunk("adminCoach/getCoachUser", async (email: string, { rejectWithValue }) => {
  try {
    const coachUser: IExtendedUser = await getUserwithEmail(email);
    return coachUser;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Get Coach User failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createCoachThunk = createAsyncThunk("adminCoach/createNewCoach", async (data: ICoach, { rejectWithValue }) => {
  try {
    return await createCoachApi(data);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Coach failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateCoachThunk = createAsyncThunk("adminCoach/updateCoach", async (data: ICoach, { rejectWithValue }) => {
  try {
    return await updateCoachApi(data);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Coach failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});
