import { Box, Text, Image, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import VimeoPlayer from "../common/VimeoPlayer";
import { useDimensions } from "@chakra-ui/react";
import React from "react";

type SuccessStoryCardProps = {
  name: string;
  imageSrc: string;
  description?: string;
  videoId: number;
};

const SuccessStoryCard: React.FC<SuccessStoryCardProps> = ({
  name,
  imageSrc,
  description,
  videoId,
}) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(boxRef);

  const [showingComponent, setShowingComponent] = useState("image");

  const handleShowVideo = () => {
    setShowingComponent((state) => {
      return state === "image" ? "video" : "image";
    });
  };

  return (
    <Box position="relative" borderRadius="lg" overflow="hidden" ref={boxRef}>
      {showingComponent === "video" ? (
        <VimeoPlayer
          videoId={videoId}
          width={dimensions?.marginBox.width}
          height={dimensions?.marginBox.height}
          autoplay={true}
        />
      ) : (
        <>
          <Image
            src={imageSrc}
            alt={`${name}'s success story`}
            width="100%"
            height="auto"
            objectFit="cover"
          />
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bg="rgba(0,0,0,0.6)"
            color="white"
            p={4}
          >
            <Text fontSize="xl" fontWeight="bold">
              {name}
            </Text>
            {description && <Text fontSize="sm">{description}</Text>}
          </Box>
        </>
      )}

      <Icon
        as={showingComponent === "image" ? FaPlay : FaPause}
        position="absolute"
        top={4}
        right={4}
        color="white"
        boxSize={8}
        bg="rgba(0,0,0,0.6)"
        p={2}
        borderRadius="full"
        onClick={handleShowVideo}
      />
    </Box>
  );
};

export default SuccessStoryCard;
