import React from "react";

import OverlayContainer from "./OverlayContainer";
import DataLoadingOverlay from "./DataLoadingOverlay";
import ErrorAlertOverlay from "./ErrorAlertOverlay";

interface DataDisplayAreaProps {
  isLoading: boolean;
  error: string | null;
  children: React.ReactNode;
  minHeight?: string | number;
}

const DataDisplayArea: React.FC<DataDisplayAreaProps> = ({
  isLoading,
  error,
  children,
  minHeight = "400px",
}) => {
  return (
    <OverlayContainer>
      <div style={{ minHeight }}>{children}</div>
      <DataLoadingOverlay isLoading={isLoading} />
      {error && <ErrorAlertOverlay error={error} />}
    </OverlayContainer>
  );
};

export default DataDisplayArea;
