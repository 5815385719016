import React, { useState, useMemo, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { useReactTable, getCoreRowModel, getSortedRowModel, ColumnDef, SortingState, CellContext } from "@tanstack/react-table";
import TableHeader from "./ReusableTableHeader";
import TableBody from "./ReusableTableBody";
import TablePagination from "./ReusableTablePagination";
import DataDisplayArea from "../DataDisplayArea";



//fetch data function options interface
export interface IFetchDataOptions  {
  pageIndex: number;
  pageSize: number;
  sortBy: string | undefined;
  //sortBy: { id: string; desc: boolean } | undefined;
  searchText: string;
  searchColumn: string | undefined;
  
}

export interface IFetchDataResult<T> {
  data: T[];
  totalCount: number;
  error: string;
}

export interface IReusableTableColumnDef<T> {
  accessorKey: string;
  header: string;
  notSearchable?: boolean;

  cell?: (context: CellContext<T, unknown>) => React.ReactNode;
}
// Define the props for the ReusableTable component
interface ReusableTableProps<T extends object> {
  columns: Array<IReusableTableColumnDef<T>>;
  fetchData: (options: IFetchDataOptions ) => Promise<IFetchDataResult<T>>;
  initialPageSize: number;
  onView?: (row: T) => void;
  onEdit?: (row: T) => void;
  onDelete?: (row: T) => void;
  onCreate?: () => void;
  tableTitle?: string;
  enableSearch?: boolean;
  enableSorting?: boolean;
  refreshTrigger?: number;
}

function ReusableTable<T extends object>({
  columns,
  fetchData,
  initialPageSize,
  onView,
  onEdit,
  onDelete,
  onCreate,
  tableTitle = "Table",
  enableSearch = true,
  enableSorting = true,
  refreshTrigger = 0,
}: ReusableTableProps<T>) {
  // State management
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState<string | undefined>(undefined);
  const [data, setData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Memoize the columns configuration
  const tableColumns = useMemo<ColumnDef<T>[]>(
    () =>
      columns.map((col) => ({
        accessorKey: col.accessorKey as keyof T,
        header: col.header,
        cell: col.cell ? (info: CellContext<T, unknown>) => col.cell!(info) : (info) => info.getValue(),
        enableSorting: enableSorting,
      })),
    [columns, enableSorting]
  );

  // Function to fetch data
  const handleFetchData = useCallback(async () => {
    setIsLoading(true);

    const sortBy = sorting.length > 0 ? `${sorting[0].id}:${sorting[0].desc ? "desc" : "asc"}` : undefined;
    /*  const sortBy =  sorting.length > 0 ? { id: sorting[0].id, desc: sorting[0].desc }   : undefined; */
    const result = await fetchData({
      pageIndex,
      pageSize,
      sortBy,
      searchText,
      searchColumn,
      
    });

    if (result.error) {
      setError(result.error);
    }

    setData(result.data);
    setTotalCount(result.totalCount);
    setIsLoading(false);
  }, [fetchData, pageIndex, pageSize, sorting, searchText, searchColumn]);

  // Fetch data when component mounts or when fetch parameters change
  React.useEffect(() => {
    handleFetchData();
  }, [handleFetchData, refreshTrigger]);

  // Handle search
  const handleSearch = () => {
    setPageIndex(0); // Reset to first page when searching
    handleFetchData();
  };

  // Create the table instance
  const table = useReactTable({
    data,
    columns: tableColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(totalCount / pageSize),
  });

  return (
    <Box borderWidth={1} borderRadius="lg" overflow="auto" boxShadow="md">
      <DataDisplayArea isLoading={isLoading} error={error}>
        {/* Table Header component */}
        <TableHeader
          tableTitle={tableTitle}
          enableSearch={enableSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          searchColumn={searchColumn}
          setSearchColumn={setSearchColumn}
          handleSearch={handleSearch}
          columns={columns}
          onCreate={onCreate}
        />
        {/* Table Body component */}
        <TableBody table={table} enableSorting={enableSorting} onView={onView} onEdit={onEdit} onDelete={onDelete} />
        {/* Table Pagination component */}
        <TablePagination pageIndex={pageIndex} pageSize={pageSize} setPageIndex={setPageIndex} setPageSize={setPageSize} totalCount={totalCount} />
      </DataDisplayArea>
    </Box>
  );
}

export default ReusableTable;
