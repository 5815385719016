import { Flex } from "@chakra-ui/react";


interface Props  {
    children: React.ReactNode;
  }

export const ImagedBackgroundFlex = ({children} : Props ) => {
  return (
    <Flex
      id="margincollapse"
      minHeight={"100%"}
      backgroundImage="/assets/leveltest/backgroundInfoCard.jpg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      //bgGradient="linear(to-r, #FCE5FF, #F687B3)" //  #FF6B3D
      //bgColor={'pink.100'}

      justify="center"
      alignItems="center"
    >
      {children}
    </Flex>
  );
};
