import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbProps } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

// Define the props for our ReusableBreadcrumb component
interface ReusableBreadcrumbProps extends BreadcrumbProps {
  rootLabel?: string; // Label for the first (root) breadcrumb item
  rootPath?: string; // Path for the first (root) breadcrumb item
  labelsMap?: Record<string, string>; // Custom labels for path segments
  fontSize?: string | object;
  fontFamily?: string;
}

/**
 * ReusableBreadcrumb - A flexible breadcrumb component for React applications
 *
 * This component generates a breadcrumb based on the current URL path.
 * It can be customized with a root label/path and a map of custom labels for path segments.
 *
 * @param {string} rootLabel - The label for the first breadcrumb item (default: 'Home')
 * @param {string} rootPath - The path for the first breadcrumb item (default: '/')
 * @param {Object} labelsMap - An object mapping path segments to custom labels
 * @param {BreadcrumbProps} props - Additional props to pass to the Chakra UI Breadcrumb component
 */
const ReusableBreadcrumb: React.FC<ReusableBreadcrumbProps> = ({
  rootLabel = "Home",
  rootPath = "/",
  labelsMap = {},
  fontSize = "sm",
  fontFamily = "Roboto, sans-serif",
  ...breadcrumbProps
}) => {
  const location = useLocation();

  // Generate breadcrumb items based on the current path
  const generateBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter(Boolean);
    let currentPath = "";

    return paths.map((path, index) => {
      currentPath += `/${path}`;
      const isLast = index === paths.length - 1;
      const isEditPath = path.includes("edit");
      const isViewPath = path.includes("view");
      const isformPath = path.includes("form");
      const isNumeric = !isNaN(parseInt(path.charAt(0)));
      const isNullParam = path.includes('null')


      // Use custom label from labelsMap if available, otherwise capitalize the path segment
      const label = labelsMap[path] || path.charAt(0).toUpperCase() + path.slice(1);
     
      if (!isNumeric && !isNullParam && !isformPath)
      return (
         <BreadcrumbItem key={path} isCurrentPage={isLast}>
          <BreadcrumbLink
            //as={isEditPath ? 'span' : RouterLink}
            as={RouterLink}
            to={currentPath}
            color={isLast ? "blue.500" : "gray.500"}
            _disabled={{
              color: "gray.400",
              cursor: "not-allowed",
              textDecoration: "none",
            }}
            {...((isEditPath || isViewPath ) && {
              pointerEvents: "none",
              cursor: "not-allowed",
              opacity: 0.6,
            })}
          >
            {label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      );
    });
  };

  return (
    <Breadcrumb fontSize={fontSize} fontFamily={fontFamily} {...breadcrumbProps} separator={<ChevronRightIcon color="gray.500" />}>
      {/* Root breadcrumb item */}
      <BreadcrumbItem>
        <BreadcrumbLink as={RouterLink} to={rootPath}>
          {rootLabel}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {/* Dynamically generated breadcrumb items */}
      {generateBreadcrumbs()}
    </Breadcrumb>
  );
};

export default ReusableBreadcrumb;
