import React from 'react';
import { VStack, Button, Text, Divider, Tooltip, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaBook, FaQuestionCircle,  FaBell, FaEnvelope, FaChartBar, FaUsers, FaUserTie } from 'react-icons/fa';
import { MdOutlineSource, MdQuiz } from 'react-icons/md';

interface AdminSidebarProps {
  onClose?: () => void;
  isCollapsed?: boolean;
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({ onClose, isCollapsed = false }) => {
  const menuItems = [
    { icon: FaHome, label: 'Dashboard', to: '/admin/' },
    { icon: FaQuestionCircle, label: 'Level Tests', to: '/admin/level-tests' },
    { icon: FaUserTie , label: 'Coaches', to: '/admin/coaches' },
    { icon: FaBook, label: 'Courses', to: '/admin/courses' },
    { icon: MdQuiz, label: 'Quizzes', to: '/admin/quizzes' },
    { icon: FaUsers, label: 'User Management', to: '/admin/users' },
    { icon: MdOutlineSource , label: 'Resources', to: '/admin/resources' },
    { icon: FaBell, label: 'Notifications', to: '/admin/notifications' },
    { icon: FaEnvelope, label: 'Messages', to: '/admin/messages' },
    { icon: FaChartBar, label: 'Statistics', to: '/admin/statistics' },
    
    
  ];

  return (
    <VStack spacing={4} align="stretch" p={4}>
      {!isCollapsed && (
        <>
          <Text fontSize="xl" fontWeight="bold" mb={2} color="white">Admin Menu</Text>
          <Divider />
        </>
      )}
      {menuItems.map((item) => (
        <Tooltip key={item.to} label={isCollapsed ? item.label : ''} placement="right">
          <Box>
            <Button
              colorScheme='purple.500'
              leftIcon={<item.icon />}
              as={RouterLink}
              to={item.to}
              onClick={onClose}
              justifyContent={isCollapsed ? 'center' : 'flex-start'}
              width="100%"
              px={isCollapsed ? 2 : 4}
            >
              {!isCollapsed && item.label}
            </Button>
          </Box>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default AdminSidebar;