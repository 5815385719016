import { courses_data } from "@/constants/dataPublicPages";
import ImageCardSimple from "@/components/common/ImageCardSimple";

import {
  TimeIcon,
  ArrowUpDownIcon,
  ChatIcon,
  CalendarIcon,
  CheckIcon,
} from "@chakra-ui/icons";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const CourseDetailsSection: React.FC = () => {
  return (
    <>
      <Container maxW="container.xl" centerContent mb={10} mt={10}>
        {courses_data.courses.map((crs,idx) => (
          <Box key={idx} width="80%">
            <Card  m={5} bg={"pink.50"} >
              <CardHeader bg={"pink.700"} borderTopRadius="lg" color={"white"}>
                <Heading size="sm">{crs.title}</Heading>
              </CardHeader>
              <CardBody>
                <Box mb={2} color={"purple.700"}>
                  <HStack>
                    <Icon as={CalendarIcon} />
                    <Text>{crs.duration} </Text>
                  </HStack>
                  <HStack>
                    <Icon as={ChatIcon} />
                    <Text>{crs.modules}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={TimeIcon} />
                    <Text>{crs.ModuleDuration}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={ArrowUpDownIcon} />
                    <Text>{crs.teachers}</Text>
                  </HStack>
                </Box>
                <Divider />

                <VStack align="start" spacing={2} m={3}>
                  {crs.descriptions.map((desc, index) => (
                    <HStack key={index}>
                      <Icon as={CheckIcon} color={"green"} />
                      <Text>{desc}</Text>
                    </HStack>
                  ))}
                </VStack>
              </CardBody>
            </Card>
            <Card  bg={"pink.100"}>
              <Flex p={2} wrap="wrap" justify={"center"}>
                {crs.cards.map((card, crdkey) => {
                  return (
                    <ImageCardSimple
                      key={crdkey}
                      cardwidth="120px"
                      imageURL={card.imageURL}
                      imageSize="100px"
                      oneLinerText={card.oneLinerText}
                    />
                  );
                })}
              </Flex>
            </Card>
          </Box>
        ))}
      </Container>
    </>
  );
};

export default CourseDetailsSection;
