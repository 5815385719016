import { Card ,CardBody,Stack,Box,Text} from "@chakra-ui/react";
import React from "react";


interface ImageCardSimpleProps {
    cardwidth:string,
    imageSize: string,
    imageURL: string,
    oneLinerText : string,
    
}

const ImageCardSimple: React.FC<ImageCardSimpleProps> = ({cardwidth, imageSize,imageURL,oneLinerText}) => {
  return (
    <Card w={cardwidth} m={2}>
      <CardBody p={0}>
        <Box 
          backgroundImage={`url(${imageURL})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          w="100%"
          height={imageSize}
        />

        <Stack align="center" m={2}>
          <Text   fontSize="xs">
            {oneLinerText}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ImageCardSimple
