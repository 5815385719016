import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { Control, UseFormRegister, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CourseFormData } from '@/components/admin/courses/courseForm/courseFormConstants';
import { DayOfWeek } from '@/features/admin/course/adminCourseTypes';
import { ControlledTimeInput } from '@/components/common/ControlledTimeInput';
import { ControlledNumberInput } from '@/components/common/ReusableControlledNumberInput';
import { formMode } from '@/types/globalTypes';
import { Clock } from 'lucide-react';

interface ZoomSessionSectionProps {
  register: UseFormRegister<CourseFormData>;
  control: Control<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
  setValue: UseFormSetValue<CourseFormData>;
  watch: UseFormWatch<CourseFormData>;
}

const ZoomSessionSection: React.FC<ZoomSessionSectionProps> = ({
  register,
  control,
  errors,
  mode,
  setValue,
  watch
}) => {
  return (
    <VStack spacing={4} align="stretch">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        {/* Zoom Link */}
        <FormControl isInvalid={!!errors.zoomLink}>
          <FormLabel>Zoom Meeting Link</FormLabel>
          <Input
            {...register('zoomLink')}
            placeholder="https://zoom.us/j/..."
            isReadOnly={mode === formMode.VIEW}
          />
          <FormErrorMessage>
            {errors.zoomLink?.message}
          </FormErrorMessage>
        </FormControl>

        {/* Day of Week */}
        <FormControl isInvalid={!!errors.zoomTime?.dayOfWeek}>
          <FormLabel>Meeting Day</FormLabel>
          <Select
            {...register('zoomTime.dayOfWeek')}
            isReadOnly={mode === formMode.VIEW}
          >
            {Object.entries(DayOfWeek).map(([key, value]) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.zoomTime?.dayOfWeek?.message}
          </FormErrorMessage>
        </FormControl>

        {/* Meeting Time */}
        <FormControl isInvalid={!!errors.zoomTime?.hour || !!errors.zoomTime?.minute}>
          <FormLabel>Meeting Time</FormLabel>
          <ControlledTimeInput
            hourFieldName="zoomTime.hour"
            minuteFieldName="zoomTime.minute"
            control={control}
            setValue={setValue}
            watch={watch}
            isReadOnly={mode === formMode.VIEW}
            leftIcon={Clock}
          />
          <FormErrorMessage>
            {errors.zoomTime?.hour?.message || errors.zoomTime?.minute?.message}
          </FormErrorMessage>
        </FormControl>

        {/* Duration */}
        <FormControl isInvalid={!!errors.zoomTime?.duration}>
          <FormLabel>Duration (minutes)</FormLabel>
          <ControlledNumberInput
            name="zoomTime.duration"
            control={control}
            min={15}
            max={240}
            step={15}
            isReadOnly={mode === formMode.VIEW}
          />
          <FormErrorMessage>
            {errors.zoomTime?.duration?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </VStack>
  );
};

export default ZoomSessionSection;