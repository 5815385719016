import React, { useCallback, useState } from "react";
import { Box, Button, Center, Divider, Flex, FormControl, FormErrorMessage, HStack, Image, Input, Text, useToast, VStack } from "@chakra-ui/react";
import { UploadCloud } from "lucide-react";
import { uploadPhoto } from "@/features/admin/coaches/adminCoachApi";

// Component props interface
interface ImageUploaderProps {
  id: string; // Required ID for the upload
  onUploadSuccess?: ( ) => void; // Optional callback for successful upload
  onUploadError?: (error: string) => void; // Optional callback for upload errors
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ id, onUploadSuccess, onUploadError }) => {
  const APP_URL = import.meta.env.VITE_URL || "https://fluentchatter.com";

  // State management
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Stores the selected file object
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // Local URL for image preview
  const [isLoading, setIsLoading] = useState(false); // Loading state for upload process
  const [error, setError] = useState<string | null>(null); // Error state
  const toast = useToast(); // Chakra UI toast for notifications

  // Handle drag over event for drag and drop functionality
  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Handle file drop event
  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    // Validate file type (JPEG only as per backend requirements)
    if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
      setSelectedFile(file);
      setError(null);
      // Create a local preview URL for the dropped file
      const localPreviewUrl = URL.createObjectURL(file);
      setPreviewUrl(localPreviewUrl);
    } else {
      setError("Please select a valid JPEG image");
    }
  }, []);

  // Handle file selection from input
  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    // Validate file type (JPEG only)
    if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
      setSelectedFile(file);
      setError(null);
      // Create a local preview URL for the selected file
      const localPreviewUrl = URL.createObjectURL(file);
      setPreviewUrl(localPreviewUrl);
    } else {
      setError("Please select a valid JPEG image");
    }
  }, []);

  // Handle the upload process
  const handleUpload = async () => {
    // Guard clause: ensure we have both file and ID
    if (!selectedFile || !id) return;

    setIsLoading(true);
    setError(null);

    // Create form data for multipart/form-data upload
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", id);

    try {
      // Send upload request to backend

      await uploadPhoto(formData);
      onUploadSuccess?.();

      // Show success toast
      toast({
        title: "Upload Successful",
        description: "Image has been uploaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      // Handle upload errors
      const errorMessage = err instanceof Error ? err.message : "Failed to upload image";
      setError(errorMessage);
      onUploadError?.(errorMessage);

      // Show error toast
      toast({
        title: "Upload Failed",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // If no ID is provided, show error message
  if (!id) {
    return (
      <Center p={6} borderWidth={2} borderRadius="md" borderStyle="dashed" bg="gray.50">
        <Text color="gray.500">ID is required for image upload</Text>
      </Center>
    );
  }

  // Main component render
  return (
    <VStack spacing={4} width="full" maxW="md">
      {/* File Input Form */}
      <FormControl isInvalid={!!error}>
        {/* Hidden file input, triggered by clicking the drop zone */}
        <Input type="file" accept="image/jpeg,image/jpg" onChange={handleFileSelect} hidden id="file-upload" />

        {/* Drag and Drop Zone */}
        <Box
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          borderWidth={2}
          borderRadius="md"
          borderStyle="dashed"
          p={6}
          cursor="pointer"
          bg="gray.50"
          _hover={{ bg: "gray.100" }}
          onClick={() => document.getElementById("file-upload")?.click()}
        >
          <Center flexDirection="column">
            <UploadCloud className="mb-4" size={48} />
            <Text mb={2}>Drag and drop your image here or click to select</Text>
            <Text fontSize="sm" color="gray.500">
              Only JPEG files are accepted
            </Text>
          </Center>
        </Box>

        {/* Error Message Display */}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>

      <HStack w={"full"} justify={"space-evenly"}>
        {/* Preview of Selected Image */}
       
          <Flex direction="column" align="center" gap={1}>
            <Text fontSize={"small"}>Selected Image Preview:</Text>
            <Image src={previewUrl || ''} alt={'No Image Selected Yet'} boxSize="100px" objectFit="cover" borderRadius="md" w={"100px"} h={"100px"} m={5} border={"1px dashed gray"} bg={"gray"} />
          </Flex>
        
        <Divider orientation="vertical" h={"120px"} />
        {/* Display Uploaded Image and URL */}
       
          <Flex direction="column" align="center" gap={1}>
            <Text fontSize={"small"}>Uploaded Image Preview:</Text>
            <Image
              src={`${APP_URL}api/images/${id}.jpg`}
              alt="No Image"
              boxSize="100px"
              objectFit="cover"
              borderRadius="md"
              w={"100px"}
              h={"100px"}
              m={5}
              border={"1px dashed gray"}
              bg={"gray"}
            />
            
          </Flex>
       
      </HStack>
      {/* Upload Button */}
      <Button colorScheme="blue" onClick={handleUpload} isLoading={isLoading} isDisabled={!selectedFile} width="full">
        Upload Image
      </Button>
    </VStack>
  );
};

export default ImageUploader;
