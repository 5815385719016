import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminDashboardState, DashboardResponse } from './adminDashboardTypes';
import { fetchDashboardData } from './adminDashboardActions';

const initialState: AdminDashboardState = {
  stats: null,
  activeCourses: [],
  incomingZoomSessions: [],
  isLoading: false,
  error: null,
  
};

const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    resetDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action: PayloadAction<DashboardResponse>) => {
        state.isLoading = false;
        state.stats = action.payload.stats;
        state.activeCourses = action.payload.activeCourses;
        state.incomingZoomSessions = action.payload.incomingZoomSessions;
        
        state.error = null;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError, resetDashboard } = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;

// Selectors
export const selectDashboardStats = (state: { adminDashboard: AdminDashboardState }) => 
  state.adminDashboard.stats;

export const selectActiveCourses = (state: { adminDashboard: AdminDashboardState }) => 
  state.adminDashboard.activeCourses;

export const selectZoomSessions = (state: { adminDashboard: AdminDashboardState }) => 
  state.adminDashboard.incomingZoomSessions;