// src/components/admin/leveltest/Admin_LevelTest_Questions.tsx

import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store/store";
import { createNewQuestion, updateExistingQuestion, deleteExistingQuestion } from "@/features/admin/levelTest/adminLevelTestActions";
import { Question, CreateQuestionInput, UpdateQuestionInput } from "@/features/admin/levelTest/adminLevelTestTypes";
import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useToast } from "@chakra-ui/react";
import Admin_LevelTest_QuestionForm from "./LevelTest_QuestionForm";
import { getQuestionsForTest } from "@/features/admin/levelTest/adminLevelTestApi";
import { CellContext } from "@tanstack/react-table";
 
import { fetchData_ReusableTable } from "@/utils/helpers";

interface Admin_LevelTest_QuestionsProps {
  testId: string;
}

const LevelTest_Questions: React.FC<Admin_LevelTest_QuestionsProps> = ({ testId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [triggerkey, setTriggerKey] = useState(0);

  const triggerRefecth = () => {
    setTriggerKey((old) => old + 1);
  };

  const handleSubmit = async (questionData: CreateQuestionInput | UpdateQuestionInput) => {
    try {
      if (editingQuestion) {
        // It's an update operation
        await dispatch(updateExistingQuestion({ id: editingQuestion.id, questionData: questionData as UpdateQuestionInput })).unwrap();
        toast({
          title: "Question updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // It's a create operation
        await dispatch(createNewQuestion({ ...(questionData as CreateQuestionInput), testId })).unwrap();
        toast({
          title: "Question created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onClose();
      setEditingQuestion(null);
    } catch (error) {
      toast({
        title: "Error",
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      triggerRefecth();
    }
  };

  const handleDeleteQuestion = async (questionId: string) => {
    try {
      await dispatch(deleteExistingQuestion(questionId)).unwrap();
      toast({
        title: "Question deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting question",
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      triggerRefecth();
    }
  };

  const columns = [
    {
      accessorKey: "text",
      header: "Question",
      cell: (context: CellContext<Question, unknown>) => {
        const cellValue = context.getValue() as string;
        return cellValue.slice(0, 50) + (cellValue.length > 50 ? "..." : "");
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (context: CellContext<Question, unknown>) => {
        const cellValue = context.getValue() as string;
        return cellValue.charAt(0).toUpperCase() + cellValue.slice(1);
      },
    },
  ];

  

  return (
    <Box>
      <ReusableTable
        refreshTrigger={triggerkey}
        columns={columns}
        fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<Question>(options, () => getQuestionsForTest(testId)), [testId])}
        initialPageSize={10}
        tableTitle="Level Test Questions"
        onCreate={() => {
          setEditingQuestion(null);
          onOpen();
        }}
        onEdit={(row) => {
          setEditingQuestion(row as Question);
          onOpen();
        }}
        onDelete={(row) => handleDeleteQuestion((row as Question).id)}
      />
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={"blue.500"} color={"white"}>
            {editingQuestion ? "Edit Question" : "Add New Question"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Admin_LevelTest_QuestionForm initialData={editingQuestion} onSubmit={handleSubmit} onCancel={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LevelTest_Questions;
