import {
  Heading,
  VStack,
  Text,
  Image,
  Container,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { OurWay_data } from "@/constants/dataPublicPages";

const OurWay: React.FC<{color:string}> = ({color = 'white'}) =>{
  return (
    <Container maxW="container.xl" centerContent>
      <Flex wrap="wrap">
        <VStack
          align="flex-start"
          spacing={6}
          maxW={{ base: "100%", lg: "45%" }}
          mt={{ base: 12, lg: 0 }}
         color={color}
        >
          <Heading as="h3" size="xl">
          {OurWay_data.header}
          </Heading>
          {OurWay_data.list.map( (itm,index )=> <Text fontSize="md" key={index}>{itm}</Text> ) }
          
        </VStack>
        <Spacer />
        <VStack
          align="flex-start"
          spacing={6}
          maxW={{ base: "100%", lg: "45%" }}
          mt={{ base: 12, lg: 0 }}
          color={color}
        >
          <Image
            src="/assets/studentsuccess/StudentsEnjoys.webp"
            alt="Students enjoying Italian food"
            borderRadius="lg"
            objectFit="cover"
            w="100%"
          />
        </VStack>
      </Flex>
    </Container>
  );
};

export default OurWay;
