import { Box, Flex, ResponsiveValue, useBreakpointValue } from "@chakra-ui/react";

const Somecomp: React.FC = () => {
  const direction = useBreakpointValue<ResponsiveValue<"row" | "column">>({ base: "column", md: "row" })


  return (
    <Flex data-testid="faq-container" direction={direction} data-direction={direction}>
      <Box border="1px solid red">this red</Box>
      <Box border="1px solid GREEN">this GREEN </Box>
    </Flex>
  );
};

export default Somecomp;
