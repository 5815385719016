import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminCoursesState, ICourse, ICourseType, IModule } from "./adminCourseTypes";
import {
  createCourseThunk,
  createCourseTypeThunk,
  createModuleThunk,
  toggleCourseActiveThunk,
  toggleCourseEnrollmentThunk,
  updateCourseThunk,
  updateCourseTypeThunk,
  updateModuleThunk,
} from "./adminCourseActions";
import { IDefaultApiResponse } from "@/types/globalTypes";

const initialState: AdminCoursesState = {
  currentCourseType: null,
  currentModule: null,
  currentCourse: null,
  lastResponseMessage: "",
  isLoading: false,
  error: null,
};

const adminCourseSlice = createSlice({
  name: "adminCourse",
  initialState,
  reducers: {
    setCurrentCourseType: (state, action: PayloadAction<ICourseType | null>) => {
      state.error = null;
      state.currentCourseType = action.payload;
    },
    setCurrentModule: (state, action: PayloadAction<IModule | null>) => {
      state.error = null;
      state.currentModule = action.payload;
    },
    setCurrentCourse: (state, action: PayloadAction<ICourse | null>) => {
      state.error = null;
      state.currentCourse = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //Create Course Type
      .addCase(createCourseTypeThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCourseTypeThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createCourseTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Update Course Type
      .addCase(updateCourseTypeThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCourseTypeThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCourseTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Create Module
      .addCase(createModuleThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createModuleThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createModuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //update Module
      .addCase(updateModuleThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateModuleThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateModuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Create Course
      .addCase(createCourseThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCourseThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
        state.currentCourse = null; // Clear the current course after successful creation
      })
      .addCase(createCourseThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Update Course
      .addCase(updateCourseThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCourseThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCourseThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Toggle Course Active
      .addCase(toggleCourseActiveThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(toggleCourseActiveThunk.fulfilled, (state, action: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.error = null;
        state.currentCourse = action.payload;
      })
      .addCase(toggleCourseActiveThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      }) 
       //Toggle Course Enrollment Status
       .addCase(toggleCourseEnrollmentThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(toggleCourseEnrollmentThunk.fulfilled, (state, action: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.error = null;
        state.currentCourse = action.payload;
      })
      .addCase(toggleCourseEnrollmentThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      });
  },
});

export const { setCurrentCourseType, setCurrentModule, setCurrentCourse, clearError } = adminCourseSlice.actions;

export default adminCourseSlice.reducer;
