import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminCoachState, ICoachUser } from "./adminCoachTypes";
import { createCoachThunk, getCoachUserThunk, updateCoachThunk } from "./adminCoachActions";
import { IDefaultApiResponse } from "@/types/globalTypes";

import { IExtendedUser } from "@/features/users/userTypes";

const initialState: AdminCoachState = {
  isLoading: false,
  error: null,
  lastResponseMessage: null,
  currentCoach: null,
  //the state of finduserbyemail result - create coach form
  coachUser: null,
};

const adminCoachSlice = createSlice({
  name: "adminCoach",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setCurrentCoach: (state, action: PayloadAction<ICoachUser | null>) => {
      state.currentCoach = action.payload;
    },
    clearCoachUser: (state) => {
      state.coachUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCoachThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCoachThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.isLoading = false;
        state.error = null;
        state.lastResponseMessage = action.payload.message;
      })
      .addCase(createCoachThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateCoachThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCoachThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.isLoading = false;
        state.error = null;
        state.lastResponseMessage = action.payload.message;
      })
      .addCase(updateCoachThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(getCoachUserThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.coachUser = null
      })
      .addCase(getCoachUserThunk.fulfilled, (state, action: PayloadAction<IExtendedUser>) => {
        state.isLoading = false;
        state.error = null;
        state.coachUser = action.payload;
      })
      .addCase(getCoachUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.coachUser = null;
      });
  },
});

export const { clearError, setCurrentCoach ,clearCoachUser} = adminCoachSlice.actions;
export default adminCoachSlice.reducer;
