import {
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
  Image,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";

import GradientWaveSection from "../common/GradientWaveSection";
import { MdCheckCircle } from "react-icons/md";

import { howitworks_data } from "@/constants/dataPublicPages";
import CoursePlatform from "./CoursePlatform";

const HowItWorksSection: React.FC = () => {
  const aspects = howitworks_data.aspects;

  return (
    <GradientWaveSection>
      <Container maxW="container.xl" centerContent mb={10} color="white">
        <Heading as="h2" size="2xl" mb={8}>
          {howitworks_data.headertext}
        </Heading>
      </Container>
      <CoursePlatform />
      <Container maxW="container.xl" centerContent mb={10}>
        <Flex wrap="wrap">
          <VStack
            align="flex-start"
            spacing={6}
            maxW={{ base: "100%", lg: "45%" }}
            mt={{ base: 12, lg: 0 }}
            color="white"
          >
            <Image
              src={aspects[1].imageSrc}
              alt="Students enjoying Italian food"
              borderRadius="lg"
              objectFit="cover"
              w="100%"
            />
          </VStack>
          <Spacer />
          <VStack
            align="flex-start"
            spacing={6}
            maxW={{ base: "100%", lg: "45%" }}
            mt={{ base: 12, lg: 0 }}
            color="white"
          >
            <Heading as="h2" size="xl">
              {aspects[1].title}
            </Heading>
            <Text fontSize="md">{aspects[1].description}</Text>
            <Text fontSize="sm">{aspects[1].extended}</Text>
          </VStack>
        </Flex>
      </Container>

      <Container maxW="container.xl" centerContent>
        <Flex wrap="wrap">
          <VStack
            align="flex-start"
            spacing={6}
            maxW={{ base: "100%", lg: "45%" }}
            mt={{ base: 12, lg: 0 }}
            color="white"
          >
            <Heading as="h2" size="xl">
              {aspects[2].title}
            </Heading>
            <Text fontSize="md"> {aspects[2].description}</Text>
            <List spacing={3}>
              {aspects[2].list?.map((itm, idx) => (
                <ListItem key={idx}>
                  <ListIcon as={MdCheckCircle} color="yellow.500" /> {itm}{" "}
                </ListItem>
              ))}
            </List>
          </VStack>
          <Spacer />
          <VStack
            align="flex-start"
            spacing={6}
            maxW={{ base: "100%", lg: "45%" }}
            mt={{ base: 12, lg: 0 }}
            color="white"
          >
            <Image
              src={aspects[2].imageSrc}
              alt="Students enjoying Italian food"
              borderRadius="lg"
              objectFit="cover"
              w="100%"
            />
          </VStack>
        </Flex>
      </Container>
    </GradientWaveSection>
  );
};

export default HowItWorksSection;
