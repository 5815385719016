import ReusableTable, { IFetchDataOptions, IReusableTableColumnDef } from "@/components/common/ReusableTable/ReusableTable";

import { useCallback, useState } from "react";

import { IExtendedUser } from "@/features/users/userTypes";
import { getUserList } from "@/features/users/userApi";
import AnimatedCard from "@/components/common/AnimatedCard";
import { Box, CardBody, CardHeader, Checkbox, Divider, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import InfoCard from "@/components/common/InfoCard";
import { CellContext } from "@tanstack/react-table";
import { FaUserCheck, FaUserClock, FaUserGraduate, FaUserMinus, FaUserShield, FaUserTie } from "react-icons/fa";
import ReusableModal from "@/components/common/ReusableModal";
import UserProfile from "@/components/common/UserProfile";
import { enPaginationSide, fetchData_ReusableTable } from "@/utils/helpers";

const UsersList = () => {
  const [showView, setShowView] = useState(false);
  const [currentUser, setCurrentUser] = useState<IExtendedUser | null>(null);

  const columns: IReusableTableColumnDef<IExtendedUser>[] = [
    { accessorKey: "username", header: "Username" },
    { accessorKey: "email", header: "Email" },
    { accessorKey: "role", header: "Role" },
    {
      accessorKey: "isVerified",
      header: "Verified",
      notSearchable: true,
      cell: (context: CellContext<IExtendedUser, unknown>) => {
        const cellValue = context.getValue();

        return cellValue === true ? <Checkbox defaultChecked size="md" colorScheme="green" /> : <Checkbox size="md" colorScheme="red" />;
      },
    },
    { accessorKey: "failedLoginAttempts", header: "Failed Attmepts" },
  ];

  const handleViewUser = (user: IExtendedUser) => {
    if (user) {
      setCurrentUser(user);
      setShowView(true);
      //navigate(`/admin/level-tests/edit/${test.id}`);
    }
  };
  return (
    <AnimatedCard>
      <CardHeader bg={"pink.400"} color={"white"} p={3}>
        <Text>Fluent Chatter Users</Text>
      </CardHeader>
      <CardBody>
        <InfoCard title="Fluent Chatter Users List" image="/assets/infocards/LevelTest.png">
          <Text fontSize={"small"}>The list contains all users registered to the system database independtly of their 'roles' , and users can be in different states :</Text>

          <List spacing={3} ml={5} mt={5} fontSize={"small"}>
            <ListItem>
              <ListIcon as={FaUserMinus} color="gray.500" />
              Registered to the system , but not verified themselves
            </ListItem>
            <ListItem>
              <ListIcon as={FaUserCheck} color="orange.500" />
              Verified , but didn't get the level test yet
            </ListItem>
            <ListItem>
              <ListIcon as={FaUserClock} color="blue.500" />
              Passed the Level Test , But didn't enrolled to a course yet
            </ListItem>
            <ListItem>
              <ListIcon as={FaUserGraduate} color="green.500" />
              Enrolled to a course as a 'Learner'
            </ListItem>
            <Divider />
            <ListItem>
              <ListIcon as={FaUserShield} color="red.500" />
              Failed too many 'Login Attempt' and Locked by system for a while
            </ListItem>
            <Divider />
            <ListItem>
              <ListIcon as={FaUserTie} color="purple.500" />
              Registered by System as Admin or Teacher Role
            </ListItem>
          </List>
        </InfoCard>
        <Box>
          <ReusableTable
            // key={refreshTrigger} // just to trigger a refetch using state
            columns={columns}
            fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<IExtendedUser>(options, () => getUserList(options), enPaginationSide.SERVER), [])}
            initialPageSize={10}
            tableTitle="Our Users"
            //onCreate={handleCreateTest}
            onView={handleViewUser}
          />
        </Box>
        <ReusableModal
          isOpen={showView}
          onClose={() => {
            setShowView(false);
          }}
          title="User Details"
        >
          <UserProfile user={currentUser} showAdminFileds={true} />
        </ReusableModal>
      </CardBody>
    </AnimatedCard>
  );
};

export default UsersList;
