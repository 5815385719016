import {
  Heading,
  Card,
  CardBody,
  Avatar,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";

interface TeacherProps {
  name: string;
  imageUrl: string;
  origin: string;
  teachLanguages: string;
  speakLanguages: string;
}

const TeacherCard: React.FC<TeacherProps> = ({
  name,
  imageUrl,
  origin,
  teachLanguages,
  speakLanguages,
}) => (
  <Card maxW="sm" variant="outline" bg="pink.400" color='white' boxShadow="md">
    <CardBody>
      <VStack spacing={4} align="center">
        <Avatar size="xl" name={name} src={imageUrl} />
        <Heading as="h3" size="md">
          {name}
        </Heading>
        <VStack spacing={1} align="start" w="100%">
          <HStack>
            <Text fontWeight="bold">Nereliyim:</Text>
            <Text>{origin}</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold">Öğrettiğim Diller:</Text>
            <Text>{teachLanguages}</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold">Konuştuğum diller:</Text>
            <Text>{speakLanguages}</Text>
          </HStack>
        </VStack>
      </VStack>
    </CardBody>
  </Card>
);

export default TeacherCard;
