import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  HStack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {
  Question,
  CreateQuestionInput,
  UpdateQuestionInput,
} from "@/features/admin/levelTest/adminLevelTestTypes";

interface Admin_LevelTest_QuestionFormProps {
  initialData?: Question | null;
  onSubmit: (data: CreateQuestionInput | UpdateQuestionInput) => void;
  onCancel: () => void;
}

type OptionId = "A" | "B" | "C" | "D";
type Option = { id: OptionId; text: string };

type FormData = Omit<CreateQuestionInput, 'testId'> & {
  options: Option[];
  correctOptionId: OptionId;
};

const OPTION_IDS: OptionId[] = ["A", "B", "C", "D"];

const LevelTest_QuestionForm: React.FC<Admin_LevelTest_QuestionFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const [formData, setFormData] = useState<FormData>({
    text: initialData?.text || "",
    type: initialData?.type || "text",
    options: OPTION_IDS.map((id) => ({
      id,
      text: initialData?.options.find(opt => opt.id === id)?.text || "",
    })),
    correctOptionId: (initialData?.correctOptionId as OptionId) || "A",
    audioUrl: initialData?.audioUrl || "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev: FormData): FormData => ({
      ...prev,
      [name]: name === 'correctOptionId' ? value as OptionId : value,
    }));
  };

  const handleOptionChange = (id: OptionId, value: string) => {
    setFormData((prev: FormData): FormData => ({
      ...prev,
      options: prev.options.map((opt: Option): Option =>
        opt.id === id ? { id, text: value } : opt
      ),
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel>Question Text</FormLabel>
          <Textarea
            name="text"
            value={formData.text}
            onChange={handleChange}
            rows={4}
            resize="vertical"
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Question Type</FormLabel>
          <Select name="type" value={formData.type} onChange={handleChange}>
            <option value="text">Text</option>
            <option value="listening">Listening</option>
          </Select>
        </FormControl>

        {formData.type === "listening" && (
          <FormControl>
            <FormLabel>Audio URL</FormLabel>
            <Input
              name="audioUrl"
              value={formData.audioUrl}
              onChange={handleChange}
            />
          </FormControl>
        )}

        <FormControl isRequired>
          <FormLabel>Options</FormLabel>
          {formData.options.map((option : Option) => (
            <HStack key={option.id} mt={2}>
              <Text>{option.id}-</Text>
              <Input
                value={option.text}
                onChange={(e) => handleOptionChange(option.id, e.target.value)}
                isRequired
                bg={option.id === formData.correctOptionId ? "green.200" : "pink.200"}
              />
            </HStack>
          ))}
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Correct Option</FormLabel>
          <Select
            name="correctOptionId"
            value={formData.correctOptionId}
            onChange={handleChange}
          >
            {OPTION_IDS.map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </Select>
        </FormControl>

        <HStack spacing={4}>
          <Button type="submit" colorScheme="blue">
            {initialData ? "Update" : "Create"} Question
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default LevelTest_QuestionForm;