import React, { useEffect, useRef } from "react";
import Player from "@vimeo/player";

interface VimeoPlayerProps {
  videoId: number;
  width?: number;
  height?: number;
  autoplay: boolean;
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({
  videoId,
  //width = 640,
  //height = 360,
  autoplay = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const player = new Player(containerRef.current, {
      id: videoId,
      //  width: playerDimensions.w,
      //  height: playerDimensions.h,
      autoplay,
      responsive: true,
    });

    return () => {
      player.unload();
    };
  }, [videoId, autoplay]);

  //return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
  /*
  We've added an outer div with a padding-top of 56.25%. This creates a 16:9 aspect ratio container.
  The inner div (with the ref) is absolutely positioned within this container.
  This setup ensures that the player maintains its aspect ratio while still being responsive.
  */
  return (
    <div style={{ position: "relative", paddingTop: "56.25%", width: "100%" }}>
      <div
        ref={containerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default VimeoPlayer;
