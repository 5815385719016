// src/features/admin/levelTest/adminLevelTestActions.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
 
  setCurrentTest, 
  setQuestions, 
  addQuestion, 
  updateQuestion, 
  removeQuestion,
  setLoading, 
  setError 
} from './adminLevelTestSlice';
import { 
 // getAllLevelTests, 
  createLevelTest, 
  toggleLevelTestActive, 
  createQuestion, 
  updateQuestionApi, 
  deleteQuestion, 
  getQuestionsForTest
} from './adminLevelTestApi';
import { CreateLevelTestInput, CreateQuestionInput, LevelTest, UpdateQuestionInput } from './adminLevelTestTypes';


/* export const fetchAllLevelTests = createAsyncThunk<LevelTest[], void, { rejectValue: string }>(
  'adminLevelTest/fetchAllLevelTests',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const tests = await getAllLevelTests();
      return tests;
    } catch (error) {
      const errorMessage = (error as Error).message;
      dispatch(setError(errorMessage));
      throw error; // This will cause the thunk to be rejected
    } finally {
      dispatch(setLoading(false));
    }
  }
); */

export const createNewLevelTest = createAsyncThunk<LevelTest, CreateLevelTestInput>(
  'adminLevelTest/createNewLevelTest',
  async (testData: CreateLevelTestInput, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newTest = await createLevelTest(testData);
      dispatch(setCurrentTest(newTest));
      return newTest;
    } catch (error) {
      dispatch(setError((error as Error).message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const toggleTestActive = createAsyncThunk(
  'adminLevelTest/toggleTestActive',
  async (testId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const updatedTest = await toggleLevelTestActive(testId);
      dispatch(setCurrentTest(updatedTest));
      return updatedTest
    } catch (error) {
      dispatch(setError((error as Error).message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createNewQuestion = createAsyncThunk(
  'adminLevelTest/createNewQuestion',
  async (questionData: CreateQuestionInput, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuestion = await createQuestion(questionData);
      dispatch(addQuestion(newQuestion));
    } catch (error) {
      dispatch(setError((error as Error).message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchQuestionsForTest = createAsyncThunk(
    'adminLevelTest/fetchQuestionsForTest',
    async (testId: string, { dispatch }) => {
      try {
        dispatch(setLoading(true));
        const questions = await getQuestionsForTest(testId);
        dispatch(setQuestions(questions));
      } catch (error) {
        dispatch(setError((error as Error).message));
        throw error;
      } finally {
        dispatch(setLoading(false));
      }
    }
  );
export const updateExistingQuestion = createAsyncThunk(
  'adminLevelTest/updateExistingQuestion',
  async ({ id, questionData }: { id: string; questionData: UpdateQuestionInput }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const updatedQuestion = await updateQuestionApi(id, questionData);
      dispatch(updateQuestion(updatedQuestion));
    } catch (error) {
      dispatch(setError((error as Error).message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteExistingQuestion = createAsyncThunk(
  'adminLevelTest/deleteExistingQuestion',
  async (questionId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      await deleteQuestion(questionId);
      dispatch(removeQuestion(questionId));
    } catch (error) {
      dispatch(setError((error as Error).message));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);