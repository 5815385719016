import React from 'react';
import { VStack, Text, Button, Box } from '@chakra-ui/react';

interface NotificationListProps {
  onItemClick: (id: string) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({ onItemClick }) => {
  // TODO: Replace with actual notifications data
  const notifications = [
    { id: '1', title: 'New course available' },
    { id: '2', title: 'Assignment due soon' },
  ];

  return (
    <VStack spacing={2} align="stretch" p={2}>
      {notifications.map((notification) => (
        <Box key={notification.id} p={2} _hover={{ bg: 'gray.100' }} cursor="pointer" onClick={() => onItemClick(notification.id)}>
          <Text>{notification.title}</Text>
        </Box>
      ))}
      <Button size="sm" colorScheme="blue">Show All Notifications</Button>
    </VStack>
  );
};

export default NotificationList;