 
import { Box, Button, Container, Heading, keyframes, Text, VStack    } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
 
  const textColor = 'gray.600'
 // Define the pulse animation
 const pulseAnimation = keyframes`
 0% { transform: translate(-50%, -50%) scale(1); }
 50% { transform: translate(-50%, -50%) scale(1.1); }
 100% { transform: translate(-50%, -50%) scale(1); }
`;

// Define the number animation
const numberAnimation = keyframes`
 0% { transform: scale(1); }
 50% { transform: scale(1.1); }
 100% { transform: scale(1); }
`;

return (
 <Container maxW="container.xl" h="100vh" centerContent>
   <VStack 
     spacing={8} 
     justify="center" 
     align="center" 
     h="full"
     px={4}
   >
     <Box 
       position="relative" 
       w="full" 
       maxW="lg"
       textAlign="center"
     >
       {/* 404 Number */}
       <Heading
         fontSize={{ base: '150px', md: '200px' }}
         fontWeight="extrabold"
         bgGradient="linear(to-r, pink.400, purple.500)"
         bgClip="text"
         opacity={0.8}
         animation={`${numberAnimation} 3s ease-in-out infinite`}
       >
         404
       </Heading>

       {/* Decorative Circle */}
       <Box
         position="absolute"
         top="50%"
         left="50%"
         w="300px"
         h="300px"
         borderRadius="full"
         bg="pink.100"
         opacity={0.2}
         zIndex={-1}
         animation={`${pulseAnimation} 3s ease-in-out infinite`}
       />
     </Box>

     {/* Message */}
     <VStack spacing={4}>
       <Heading 
         size="xl" 
         color={textColor}
         textAlign="center"
       >
         Oops! Page Not Found
       </Heading>
       <Text
         fontSize="lg"
         color={textColor}
         textAlign="center"
         maxW="md"
       >
         The page you're looking for seems to have wandered off into cyberspace. Let's get you back on track!
       </Text>
     </VStack>

     {/* Action Button */}
     <Button
       size="lg"
       bgGradient="linear(to-r, pink.400, purple.500)"
       color="white"
       _hover={{
         bgGradient: "linear(to-r, pink.500, purple.600)",
         transform: "translateY(-2px)",
         boxShadow: "lg"
       }}
       onClick={() => navigate('/')}
       transition="all 0.2s"
     >
       Return Home
     </Button>
   </VStack>
 </Container>
  );
};

export default NotFound;