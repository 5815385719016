import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { mission_vision_data } from "@/constants/dataPublicPages";

const MissionVisionSection: React.FC = () => {
  return (
    <>
      <Global
        styles={`
          @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
        `}
      />

      <Box //border="2px solid blue"
        bgGradient="linear(to-r, #FF3366, #F687B3)"
        color='white'
        
      >
        <Container maxW="container.xl" py={10}>
          <VStack spacing={12} align="stretch">
            <Box>
              <Heading
                as="h2"
                size="xl"
                mb={4}
                fontFamily="'Indie Flower', cursive"
              >
                {mission_vision_data.mission_heading}
              </Heading>
              <Text
                fontSize="xl"
                fontFamily="'Indie Flower', cursive"
                lineHeight="1.6"
              >
                {mission_vision_data.mission_text}
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading
                as="h2"
                size="xl"
                mb={4}
                fontFamily="'Indie Flower', cursive"
              >
                {mission_vision_data.vision_heading}
              </Heading>
              <Text
                fontSize="xl"
                fontFamily="'Indie Flower', cursive"
                lineHeight="1.6"
              >
                {mission_vision_data.vision_text}
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export default MissionVisionSection;
