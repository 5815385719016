import DataDisplayArea from "@/components/common/DataDisplayArea";
import { LevelTest } from "@/features/admin/levelTest/adminLevelTestTypes";
import {
  Card,
  CardHeader,
  CardBody,
  FormControl,
  FormLabel,
  VStack,
  Text,
  Switch,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

type Props = {
  isLoading: boolean;
  error: string | null;
  test: LevelTest;
  toggleActive: () => Promise<void>;
};

const LevelTest_View = ({ isLoading, error, test, toggleActive }: Props) => {
  return (
    <Card>
      <CardHeader bg={"pink.600"} color={"white"} p={3}>
        <Text>Selected Level Test</Text>
      </CardHeader>
      <CardBody>
        <DataDisplayArea isLoading={isLoading} error={error}>
          <VStack spacing={4} align="stretch">
            <TableContainer>
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th>Prop</Th>
                    <Th>Data</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Version</Td>
                    <Td>{test.version}</Td>
                  </Tr>
                  <Tr>
                    <Td>Created At</Td>
                    <Td>{new Date(test.createdAt).toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td>Updated At</Td>
                    <Td> {new Date(test.updatedAt).toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>
                      {" "}
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="is-active" mb="0" color={'purple'}>
                          {test.isActive ? 'Active' : 'Passive'}
                        </FormLabel>
                        <Switch
                          id="is-active"
                          isChecked={test.isActive}
                          onChange={toggleActive}
                          colorScheme="purple"
                        />
                      </FormControl>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </DataDisplayArea>
      </CardBody>
    </Card>
  );
};

export default LevelTest_View;
