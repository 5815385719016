import React from 'react';
import { Card, CardProps, keyframes, usePrefersReducedMotion } from "@chakra-ui/react";

const growAnimation = keyframes`
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

interface AnimatedCardProps extends CardProps {
  children: React.ReactNode;
}

const AnimatedCard: React.FC<AnimatedCardProps> = ({ children, ...props }) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${growAnimation} 1s ease-out`;

  return (
    <Card
      {...props}
      animation={animation}
      initial={{ scale: 0.8, opacity: 0 }}
    >
      {children}
    </Card>
  );
};

export default AnimatedCard;