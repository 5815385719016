import React from "react";
import { Badge, Box, Card, CardHeader, Flex, keyframes, Text, usePrefersReducedMotion, useToast } from "@chakra-ui/react";
import { getCourseById } from "@/features/admin/course/adminCourseApi";
import { useAppDispatch } from "@/hooks/useRedux";
import { clearError, setCurrentCourse } from "@/features/admin/course/adminCourseSlice";
import { formMode } from "@/types/globalTypes";
import { useNavigate } from "react-router-dom";

interface ongoingCourses {
  count: number;
  courses: [
    {
      id: string;
      name: string;
      startDate: Date;
      endDate: Date;
      type: string;
      progressPercentage: number;
    }
  ];
}

interface OngoingCoursesProgressProps {
  data: ongoingCourses;
}

const getProgressColor = (progressPercentage: number, baseColor: string = "orange") => {
  // Ensure percentage is between 0 and 100
  const normalizedPercentage = Math.min(100, Math.max(0, progressPercentage));

  // Map percentage ranges to color intensities
  // Lower percentage = lighter color (higher intensity number)
  // Higher percentage = darker color (lower intensity number)
  if (normalizedPercentage < 20) return `${baseColor}.100`;
  if (normalizedPercentage < 40) return `${baseColor}.200`;
  if (normalizedPercentage < 60) return `${baseColor}.300`;
  if (normalizedPercentage < 80) return `${baseColor}.400`;
  return `${baseColor}.500`;
};

const expandWidth = (duration: number) => keyframes`
  from { width: 0%; opacity: 0}  to { width: ${duration}%; opacity: 0.8}`;

const OngoingCoursesProgress: React.FC<OngoingCoursesProgressProps> = ({ data }) => {
  const toast = useToast();
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();
  const handleCourseSelect = async (courseId: string) => {
    try {
      const response = await getCourseById(courseId);
      dispatch(clearError());
      dispatch(setCurrentCourse(response));
      navigate(`/admin/courses/courseform/${formMode.VIEW}/${courseId}`);
    } catch (error) {
      toast({
        title: "Error loading course data",
        description: error instanceof Error ? error.message : "Failed to load course data",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const courses = data.courses;
  //animation:
  const prefersReducedMotion = usePrefersReducedMotion();

  const today = new Date();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Helper function to get the number of days between two dates
  const getDaysBetween = (start: Date, end: Date) => {
    return (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
  };

  // Find the earliest start date and latest end date across all courses
  let earliestStartDate = new Date(Math.min(...courses.map((course) => new Date(course.startDate).getTime())));
  // Set the date to the first day of the month
  earliestStartDate = new Date(earliestStartDate.getFullYear(), earliestStartDate.getMonth(), 1);

  const latestEndDate = new Date(Math.max(...courses.map((course) => new Date(course.endDate).getTime())));

  // Calculate total number of days in the timeline
  const totalDays = getDaysBetween(earliestStartDate, latestEndDate);

  // Generate an array of all months to be displayed in the timeline
  const getMonthsBetween = (start: Date, end: Date) => {
    const months = [];
    const current = new Date(start);
    current.setDate(1); // Start from the first day of the month
    while (current <= end) {
      months.push(new Date(current));
      current.setMonth(current.getMonth() + 1);
    }
    return months;
  };

  const monthsToShow = getMonthsBetween(earliestStartDate, latestEndDate);

  // Calculate the position of the "today" marker
  const todayPosition = (getDaysBetween(earliestStartDate, today) / totalDays) * 100;

  //calculate height of calender:
  const calendarHeight = 50 + courses.length * 60;
  //gte month header

  return (
    <Card minH={"400px"} h={"100%"} borderRadius="lg" boxShadow="dark-lg" overflow="hidden">
      <CardHeader bg={"green.500"} color={"white"} p={3}>
        <Flex align="center" justify="space-between">
          <Text color="white">On Going Courses</Text>
          <Badge bg="green.700" color="white" borderRadius="full" px={2} py={0.5}>
            {courses.length} COURSES
          </Badge>
        </Flex>
      </CardHeader>

      <Box display="flex" position={"relative"} border="1px solid" borderColor="gray.200" minH={`${calendarHeight}px`} overflowX="hidden">
        {/* Month-end lines */}
        {monthsToShow.map((date, index) => {
          const monthStart = new Date(date);
          const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          const monthWidth = (getDaysBetween(monthStart, monthEnd) / totalDays) * 100;

          return (
            <React.Fragment key={`month-${index}`}>
              <Box key={index} w={`${monthWidth}%`} height={"30px"} flexGrow={0} flexShrink={0} p={1} textAlign="center" borderBottom={"1px solid grey"} fontSize={"0.8rem"}>
                {monthNames[date.getMonth()]}-{date.getFullYear().toString().substr(-2)}
              </Box>
              <Box key={`month-line-${index}`} flexGrow={0} flexShrink={0} width="2px" bg="gray.300" zIndex="0" />
            </React.Fragment>
          );
        })}

        {/* Today marker as a background element */}
        {today >= earliestStartDate && today <= latestEndDate && <Box position="absolute" left={`${todayPosition}%`} top="0" bottom="0" width="1px" bg="red.500" zIndex="3" />}

        {/* Courses */}

        {courses.map((course, index) => {
          const startDate = new Date(course.startDate);
          const endDate = new Date(course.endDate);

          // Calculate the start position as a percentage of total days
          const startOffset = (getDaysBetween(earliestStartDate, startDate) / totalDays) * 100;

          // Calculate the duration as a percentage of total days
          const duration = (getDaysBetween(startDate, endDate) / totalDays) * 100;
          //set the animation :
          const animation = prefersReducedMotion ? undefined : `${expandWidth(duration)} 0.35s ease-in-out forwards`;
          return (
            <Box
              key={`coursebox-${index}`}
              position="absolute"
              top={`${40 + index * 60}px`}
              left={`${startOffset}%`}
              width={`${duration}%`}
              height="50px"
              bg={getProgressColor(course.progressPercentage)}
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              zIndex="2"
              opacity={0.0}
              boxShadow="dark-lg"
              transition="width 1s ease-in-out"
              animation={animation}
              sx={{
                animationDelay: `${50 + index * 100}ms`, // Sequential delay based on index
              }}
              _hover={{
                backgroundColor: "green.500",
              }}
              cursor={"pointer"}
              onClick={() => handleCourseSelect(course.id)}
            >
              <Text color="black" fontWeight="bold" fontSize="sm" isTruncated>
                {course.name} ({new Date(course.startDate).toLocaleDateString()} - {new Date(course.endDate).toLocaleDateString()})
              </Text>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
};

export default OngoingCoursesProgress;
