import React from "react";
import {
  Box,
  
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import { heroData } from "@/constants/dataPublicPages";
// Import the images
import mobileBackground from "/assets/hero-mobile.jpg";
import desktopBackground from "/assets/hero-desktop.png";
import ButtonLink from "../common/ButtonLink";

const Hero: React.FC = () => {
  const headingSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const textSize = useBreakpointValue({ base: "md", md: "xl" });
  const contentWidth = useBreakpointValue({ base: "100%", md: "40%" });

  // Responsive subheading text
  const subheadingText = useBreakpointValue({
    base: heroData.subheadingText_mobile,
    md: heroData.subheadingText_desktop,
  });

  return (
    <Box
      position="relative"
      height={{ base: "50vh", md: "70vh" }}
      bg="gray.100"
      backgroundImage={{
        base: `url(${mobileBackground})`,
        md: `url(${desktopBackground})`,
      }}
      backgroundSize="cover" //'100vw 70vh'  //"cover"
      backgroundAttachment="fixed"
      //backgroundPosition="revert"
      backgroundRepeat="no-repeat"
    >
      <Container
        maxW="container.2xl"
        height="100%"
        //</Box> border='2px solid yellow'
      >
        <Flex //border='2px solid green'
          height="100%"
          width="100%"
          justify="start"
          align="start"
          direction={{ base: "column", md: "row" }}
          pt={{ base: 8, md: 0 }}
          pb={{ base: 8, md: 0 }}
        >
          <VStack // border='2px solid red'
            mt={{ base: 0, md: 20 }}
            ml={{ base: 0, md: 10 }}
            align={{ base: "center", md: "flex-start" }}
            spacing={4}
            width={contentWidth}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              as="h2"
              size={headingSize}
              colorScheme="purple"
              color="purple.700"
            >
              {heroData.headingText}
            </Heading>
            <Text fontSize={textSize} colorScheme="pink" color="pink.600">
              {subheadingText}
            </Text>
            <Flex width="100%" justify="start">
              <ButtonLink linkTo="/auth/register" text="Bize Katılın" />
            </Flex>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Hero;
