import React from 'react';
import {
  Box,
  Container,
  Text,
} from '@chakra-ui/react';

const AnnouncementBar: React.FC = () => {
  return (
    <Box bg="linear-gradient(90deg, #FF69B4, #FF1493)" py={2}>
      <Container maxW="container.xl">
        <Text color="white" textAlign="center" fontWeight="bold">
          Announcement Bar
        </Text>
      </Container>
    </Box>
  );
};

export default AnnouncementBar;