import LevelTest_List from "@/components/admin/leveltest/LevelTest_List";
import { Box } from "@chakra-ui/react";
import React from "react";

const AdminLeveltestPage: React.FC = () => {
  return (
    <Box>
      <LevelTest_List />
    </Box>
  );
};

export default AdminLeveltestPage;
