import React from "react";
import { Card, CardHeader, CardBody, Stack, Heading, Text, useToast, Switch, HStack, FormLabel, Spinner } from "@chakra-ui/react";

import { enumCourseStatus, ICourse } from "@/features/admin/course/adminCourseTypes";
import { useConfirmDialog } from "@/components/common/ConfirmationModal/useConfirmDialog";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { toggleCourseEnrollmentThunk } from "@/features/admin/course/adminCourseActions";
import { unwrapResult } from "@reduxjs/toolkit";

interface EnrollmentStatusProps {
  currentCourse: ICourse | null;
}

const EnrollmentStatus: React.FC<EnrollmentStatusProps> = ({ currentCourse }) => {
  const { isLoading } = useAppSelector((state) => state.adminCourse);
  const dispatch = useAppDispatch();
  const toast = useToast();
  let canToogleEnrollment = false;

  const { confirm, ConfirmationDialog } = useConfirmDialog();

  const handleToggleEnrollment = async () => {
    if (currentCourse) {
      try {
        const userConfirmed = await confirm(`Are you sure you want to ${currentCourse?.isClosedToEnrolments ? " re-open " : " close "}  the course to enrollments?`);
        if (userConfirmed) {
          // User clicked Yes
          // Get the result from the thunk
          const result = await dispatch(toggleCourseEnrollmentThunk(currentCourse.id));
          const updatedCourse = unwrapResult(result);
          console.log(currentCourse);
          toast({
            title: "Course Enrollment Status Toggled.",
            description: `Course is now :  ${updatedCourse.isClosedToEnrolments ? " closed " : " open "} to enrollments.`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        // User clicked No - do nothing
      } catch (error) {
        toast({
          title: "Error toggling course Enrollment status !",
          description: error instanceof Error ? error.message : "Failed to toogle course enrollment!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  if (currentCourse) {
    canToogleEnrollment = currentCourse.status === enumCourseStatus.Upcoming; // only upcoming course's enrollments status can be toggled
  }
  return (
    <Stack spacing={6}>
      <Card>
        <CardHeader bg={canToogleEnrollment ? "green.500" : "red.500"} py={3}>
          <Heading size="sm" color="white">
            Enrollment
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack spacing={4}>
            {currentCourse && (
              <>
                <Text fontSize={"small"}>A course's enrollment status can be switched only if the course is an upcoming course </Text>
                {canToogleEnrollment && (
                  <>
                    <HStack spacing={5}>
                      <FormLabel htmlFor="isClosedToEnrolments"> Is Closed to Enrollments ? </FormLabel>
                      {isLoading ? (
                        <Spinner color="red.500" />
                      ) : (
                        <Switch id="isClosedToEnrolments" colorScheme="purple" isChecked={currentCourse.isClosedToEnrolments} onChange={handleToggleEnrollment} />
                      )}
                    </HStack>
                    <ConfirmationDialog />
                  </>
                )}
              </>
            )}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default EnrollmentStatus;
