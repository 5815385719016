import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Progress,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import {
  setStartTime,
  setCompletedTime,
  setCurrentQuestionIndex,
  setUserAnswer,
  resetTest,
} from "@/features/levelTest/levelTestSlice";
import { submitTestAttempt } from "@/features/levelTest/levelTestActions";
import QuestionCard from "./QuestionCard";
import LevelTestInfoCard from "./LevelTestInfoCard";
import LevelTestResult from "./LevelTestResult";
import { Answer, UserTestAttempt } from "@/features/levelTest/levelTestTypes";
import AnimatedCard from "@/components/common/AnimatedCard";

const LevelTestScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    currentTest,
    currentQuestionIndex,
    userAnswers,
    testProgress,
    startedAt,
  } = useAppSelector((state) => state.levelTest);

  const [testStarted, setTestStarted] = useState(false);
  const [testCompleted, setTestCompleted] = useState(false);
  const [testSubmitted, setTestSubmitted] = useState(false);
  const [testAttempt, setTestAttempt] = useState<UserTestAttempt | null>(null);

  useEffect(() => {
    return () => {
      dispatch(resetTest());
    };
  }, [dispatch]);

  const handleStartTest = () => {
    //mark test starting time
    dispatch(setStartTime(Date.now()));
    //set state
    setTestStarted(true);
  };

  const handleAnswer = (currentAnswer: Answer) => {
    if (currentTest) {
      const currentQuestion = currentTest.questions[currentQuestionIndex];
      dispatch(
        setUserAnswer({ questionId: currentQuestion.id, answer: currentAnswer })
      );

      if (currentQuestionIndex < currentTest.questions.length - 1) {
        dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
      } else {
        setTestCompleted(true);
        finishTest();
      }
    }
  };

  const finishTest = async () => {
    if (currentTest) {
      //mark test starting time
      dispatch(setCompletedTime( Date.now()));
      const attempt: UserTestAttempt = {
        id: "",
        userId: "",
        testId: currentTest.id,
        startedAt:  new Date(startedAt!),
        completedAt: new Date(),
        answers: Object.entries(userAnswers).map(([questionId, answer]) => ({
          questionId,
          selectedOptionId: answer.selectedOptionId,
          timeSpent: answer.timeSpent,
          correctOptionId: answer.correctOptionId,
        })),
        score: 0,
        level: "A1/A2",
      };

      try {
        const result = await dispatch(submitTestAttempt(attempt)).unwrap();
        setTestAttempt(result);
        setTestSubmitted(true);
        toast({
          title: "Test Completed",
          description: "Your test has been submitted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Failed to submit test",
          description: error as string,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

   
  if (!testStarted) {
    return <LevelTestInfoCard onStartTest={handleStartTest} />;
  }

  if (testSubmitted && testAttempt) {
    return (
      <LevelTestResult/>
    );
  }

  if (!currentTest) {
    return <Text>Loading test...</Text>;
  }

  const currentQuestion = currentTest.questions[currentQuestionIndex];

  return (
    <Flex
      id="margincollapse"
      minHeight={"100%"}
      backgroundImage="/assets/leveltest/backgroundInfoCard.jpg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      //bgGradient="linear(to-r, #FCE5FF, #F687B3)" //  #FF6B3D
      //bgColor={'pink.100'}

      justify="center"
      alignItems="center"
    >
      <AnimatedCard mt={8} mb={8} w={["80%", "75%"]}>
        <VStack spacing={4} align="stretch" m="auto" mt={8} mb={8}>
          <Heading size="md" textAlign="center" color="pink.500">
            Level Test
          </Heading>
          <Text textAlign="center">
            Question {currentQuestionIndex + 1} of{" "}
            {currentTest.questions.length}
          </Text>
          <Progress value={testProgress} colorScheme="purple" ml={5} mr={5} />
          {!testCompleted && (
            <QuestionCard
              key={currentQuestion.id}
              question={currentQuestion}
              index={currentQuestionIndex + 1}
              onAnswer={handleAnswer}
              timeLimit={currentQuestion.type === "listening" ? 20 : 30}
            />
          )}
        </VStack>
      </AnimatedCard>
    </Flex>
  );
};

export default LevelTestScreen;
