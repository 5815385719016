import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { Card, CardBody, Heading } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ActiveCoursesUserCountsChart: React.FC = () => {
  const data: ChartData<'bar'> = {
    labels: ['A1/A2', 'B1/B2', 'Tourism Eng', 'Bussines Eng', 'Interview Eng'],
    datasets: [
      {
        label: 'Users',
        data: [120, 80, 60, 40, 30],
        backgroundColor: '#0088FE',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Active Courses User Counts',
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>
          Active Courses User Counts
        </Heading>
        <Bar data={data} options={options} />
      </CardBody>
    </Card>
  );
};

export default ActiveCoursesUserCountsChart;