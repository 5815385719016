import api from "@/utils/api";
import { ICourse, ICourseDashboardData, ICourseQueryOptions, ICourseType, IModule } from "./adminCourseTypes";
import { IDefaultApiResponse } from "@/types/globalTypes";
import { IFetchDataResult } from "@/components/common/ReusableTable/ReusableTable";

export const getCourseTypes = async (): Promise<ICourseType[]> => {
  const response = await api.get("/courses/types");
  return response.data;
};

export const createCourseTypeApi = async (data: ICourseType): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses/types", data);
  return { message: response.data.message };
};

export const updateCourseTypeApi = async (data: ICourseType): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/types/${data.id}`, data);
  return { message: response.data.message };
};

export const getModuleList = async (courseTypeId: string): Promise<IModule[]> => {
  const response = await api.get(`/courses/types/${courseTypeId}/modules`);
  return response.data;
};

export const createModuleApi = async (data: IModule): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses/modules", data);
  return { message: response.data.message };
};

export const updateModuleApi = async (data: IModule): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/modules/${data.id}`, data);
  return { message: response.data.message };
};

export const getCourses = async (queryOptions: ICourseQueryOptions): Promise<IFetchDataResult<ICourse>> => {
  // Make the API call with the query parameters
  const response = await api.get("/courses", { params: queryOptions });
  return response.data;
};

export const getCourseById = async (courseId: string): Promise<ICourse> => {
  // Make the API call with the query parameters
  const response = await api.get(`/courses/${courseId}`);
  return response.data;
};

export const createCourseApi = async (data: ICourse): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses", data);
  return { message: response.data.message };
};

export const updateCourseApi = async (data: ICourse): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/${data.id}`, data);
  return { message: response.data.message };
};

export const toggleCourseActiveApi = async (courseId: string): Promise<ICourse> => {
  const response = await api.patch(`/courses/${courseId}/toggle-active`);
  return response.data;
};

export const toggleCourseEnrollmentApi = async (courseId: string): Promise<ICourse> => {
  const response = await api.patch(`/courses/${courseId}/toggle-enrollment`);
  return response.data;
};

export const getCourseDashboardData = async (): Promise<ICourseDashboardData> => {
  const response = await api.get("courses/dashboard");

  return response.data;
};
