export const learnerScenerios = [
  {
    emoji: "🏫",
    description:
      "Okulda öğrendiğin biraz gramer/kelime bilgin var ama hiç işe yaramıyor",
  },
  {
    emoji: "📲",
    description:
      "Bir aplikasyon ile ilerletmeye çalışıyorsun ama yeterli olmuyor. ",
  },
  {
    emoji: "👨‍🏫",
    description: "Haftada 1-2 kere özel ders alıyorsun ama çok pahalı.",
  },
  {
    emoji: "🤔",
    description: "Ama Hala Konuşulanları anlamakta zorlanıyorsun.",
  },
  {
    emoji: "😰",
    description: "İngilizce konuşurken hata yapmaktan korkuyorsun.",
  },
  {
    emoji: "🤷‍♀️",
    description:
      "En önemlisi de konuşurken sık sık takılıyor ve devam edemiyorsun.",
  },
];

export const faqs = [
  {
    question: "Bu program bir aplikasyon mu?",
    answer:
      "Hayır, program bir öğrenme platformunda, Telegram’da ve Zoom’da konuları çalışma ve pratik yapma üzerine kurulu.",
  },
  {
    question:
      "Bazen işlerim çok yoğun oluyor. Her haftada bir modül bitiremezsem ne olur?",
    answer:
      "Önceden belirlenmiş tempoyu kendi ihtiyaçlarınıza göre esnek hale getirebilirsiniz. Geçmiş modüllere erişim kapanmıyor ve Telegram aktiviteleri için “son tarih” yok. Bu nedenle, iş temponuzun yoğun olduğu haftalardaki aktiviteleri bir sonraki haftada yakalayabilirsiniz.",
  },
  {
    question: "Bu platformda TOEIC, IELTS gibi testlere hazırlanabilir miyim?",
    answer:
      "Evet, ancak her testin kendine özel bir içeriği olduğu için programa başladığınızda destek ekibimize hangi teste hazırlandığınızı belirtmeniz gerekiyor. Biz size uygun kaynakları sizinle paylaşıyoruz ve teste hazırlanmanıza yardımcı oluyoruz. Ancak FluentChatter spesifik olarak test hazırlığına değil, İngilizce’yi akıcı konuşabilme ve anlayabilme becerilerine yöneliktir.",
  },
  {
    question: "Canlı dersler var mı?",
    answer:
      "Evet! Her ay iki kere Zoom’da canlı olarak açıklamalı egzersizler yapıyoruz ve kaçırırsanız “Replay” olarak kaydedilmiş oturumu seyredebiliyorsunuz. Ayrıca Premium abonelik seçtiyseniz, eğitmenlerle ek ücret ödemeden belirli sayıda birebir seans rezervasyonu yapabilirsiniz.",
  },
  {
    question: "Hiç İngilizce bilmiyorum. Bu programı kullanabilir miyim?",
    answer:
      "Elbette. Türkçe açıklamalar ve Türkçe bilen bir ekip olduğu için takıldığın yerlerde anadilinde destek alabilirsin.",
  },
  {
    question: "Bu programın diğer programlara göre avantajı ne?",
    answer:
      "Maksimum pratik yapma imkanı, uygun ve erişilebilir fiyat, günlük hayatın yoğun temposuna uyumlu ve sürekli destek gördüğünüz bir program. ",
  },
  {
    question:
      "Konulari çalismak ve egzersizler yapmak disinda haftada kaç saat aktif pratik yapabilirim?",
    answer:
      "Telegram grubunda sürekli pratik yapabilir, düzenli olarak aldığınız geri bildirimlerle seviyenizi geliştirebilirsiniz. Zoom seansları aracılığıyla haftada üç saat konuşma pratiği yapabilir, dilerseniz bu süreyi artırabilirsiniz.",
  },
  {
    question:
      "Koçlarınız native speaker değiller. Bunun bir dezavantajı varmı?",
    answer:
      "Hayır. Günümüzde İngilizce konuşan dünya nüfusunun sadece %5'inin anadili İngilizce. Koçlarımızın hepsi alanında uzman ve deneyimli, native-level seviyesinde İngilizce konuşan profesyonellerdir. Dil öğretme becerisi native speaker olmaktan bağımsızdır.",
  },
];

export const heroData = {
  headingText: "İngilizce öğrenmenin yeri burası.",
  subheadingText_mobile: "Çatır çatır İngilizce konuşturan online kurs.",
  subheadingText_desktop:
    "Çatır çatır İngilizce konuşturan, Türkçe destekli ilk online İngilizce kursu.",
  button_joinus_text: "Katıl Bize !",
};

export const heroAboutData = {
  headingText:
    "Fluent Chatter.Size Çatır çatır İngilizce konuşmayı öğreten çevrimiçi okul.",
  subheadingText_mobile: "Ingilizce öğreniminde devrim yaratıyoruz.",
  subheadingText_desktop:
    "Profesyonel öğretmenlerin önderlik ettiği canlı çevrimiçi etkinlikler  aracılığıyla dil öğreniminde devrim yaratıyoruz.",
  button_joinus_text: "Katıl Bize !",
};

export const heroCreators = {
  headingText: "Content Creatorlar , Birlikte Kazanalim ",
  subheadingText_mobile: "Tum sosyal medya icerik ureticilerine kapimiz acik.",
  subheadingText_desktop:
    "Tum sosyal medya icerik ureticilerine kapimiz acik.Iletisime gecin birlikte kazanalim.",
  button_joinus_text: "Ulas Bize !",
};

export const heroHowItWorksData = {
  headingText: "Fluent Chatter sistemi sizin için nasıl çalışır?",
  subheadingText_mobile: "Sistemin Nasıl Çalıştığını İnceleyin.",
  subheadingText_desktop:
    "Derste neler olduğunu, nasıl başlayacağınızı ve Fluent Chatter'ın güvenle konuşmanıza nasıl yardımcı olacağını okuyun.",
  button_joinus_text: "Katıl Bize !",
};

export const howitworks_data = {
  headertext: "Fluent Chatter ile her şey değişecek!",
  aspects: [
    {
      title: "Kurs Platformu",
      description:
        "A super modern and intuitive online space where you will discover 26 thematic modules, one module per week. In each module, you will find video lessons, interactive exercises and PDF files to review and practice throughout the week. Everything you need to learn English in a practical way!",
      imageSrc: "/assets/howitworks/platform.png",
    },
    {
      title: "Pratiğini nasıl yapıyorsun?",
      description:
        "Dil öğrenmenin kalbi PRATİK! Platform dışında Telegram Gruplarında ve Zoom üzerinde yapılan canlı Speaking Groups seansları ile diğer platformlara göre çok daha fazla pratik yapma imkânın oluyor.",
      extended: `Platformda öğrendiğiniz dil becerilerini uygulamak için sizin seviyeniz ile 
aynı seviyedeki diğer kullanıcılarla iletişime dayalı aktiviteler var! Bu 
aktiviteler sizin “output” kaynağınız, yani  edindiğiniz bilgileri dile dökme 
fırsatı. Programın kalbidir, programdaki diğer kişilerle birlikte olursunuz ve 
grubunuzun Koçu ile fikir alışverişinde bulunursunuz. Koç, uygulamanızda 
size rehberlik eder ve size özel geri bildirim verir. Haftanın sonunda ise 
grubun koçu genel grup geri bildirimi ile konuya ait püf noktalari hatırlatır. 
Telegram grubunuzda pratik aktivitelerinizi yapmanın yanı sıra 
grubunuzdaki insanlarla sohbet edip bir topluluk oluşturabilirsiniz.`,
      imageSrc: "/assets/howitworks/zoommeet.webp",
    },
    {
      title: "Speaking Groups Seansları",
      description: "Haftada üç kere Zoom'da -Speaking Groups Seanslar",
      list: [
        "Modüllerde ögrenilenleri pratik yapmanın yanı sıra günlük dogal konusma gibi spontane iletisimi amaçlayan ve akıcılıgını gelistirecegin, önceden hazirlanmis ve platformdan PDF olarak indirebilecegin aktiviteler ile koç esliginde 1'er saatlik seanslar.",
        "Bu seanslarin saatleri sabittir ve ögle saatlerinde ya da aksam saatlerindedir. Amaç konusma aktiviteleri ile diğer öğrencilerle fikir alışverişinde bulunmak ve akıcılığınızı pratik etmek!",
        "Seans saatleri ile günlük programanizi çakisabilir. Bu durumda bireysel veya küçük gruplar olusturarak hafta içi veya hafta sonu seans rezervasyonu yapabilirsiniz. Ücretsiz seanslar sınırlı sayıdadır ancak ek ödeme ile dilediginiz kadar seans rezerve edebilirsiniz.",
      ],
      imageSrc: "/assets/howitworks/telegrams.jpg",
    },
  ],
  stepper_steps: [
    {
      title: "Seviyeni Test Et",
      description:
        "Kayıt olmadan önce yapacağın Seviye Tespit Sınavında aldığın skora göre 6 ay süren FluentChatter Academy ya da FluentChatter Club programlarından birine başlıyorsun.",
      tooltip:
        "Kayıt olmadan önce yapacağın Seviye Tespit Sınavında aldığın skora göre 6 ay süren FluentChatter Academy ya da FluentChatter Club programlarından birine başlıyorsun.",
    },
    {
      title: "OnLine Kursa Başla",
      description:
        "Uygulama tamamen online çünkü mekan ve zaman sınırı olmadan herkes İngilizce öğrenebilsin istiyoruz. İngilizce'yi pratik bir şekilde öğrenmek için ihtiyacınız olan her şey!",
      tooltip:
        "Katıldığın programa göre haftada bir modül veya iki haftada bir modül öğreniyorsun.",
    },
    {
      title: "Her Hafta 1 Modül",
      description:
        "Katıldığın programa göre haftada bir modül veya iki haftada bir modül öğreniyorsun. Haftada bir modül olmak üzere 26 tematik modülü keşfedeceğiniz süper modern ve sezgisel bir çevrimiçi alan. Her modülde, hafta boyunca inceleyip pratik yapabileceğiniz video dersler, etkileşimli alıştırmalar ve PDF dosyaları bulacaksınız.",
      tooltip:
        "Katıldığın programa göre haftada bir modül veya iki haftada bir modül öğreniyorsun.",
    },
    {
      title: "Gruplara Katıl Pratik Yap",
      description:
        "Pratik yapmak için Telegram Grup Aktivitelerine ve Zoom Speaking Groups seanslarına katılıyorsun.",
      tooltip:
        "Pratik yapmak için Telegram Grup Aktivitelerine ve Zoom Speaking Groups seanslarına katılıyorsun.",
    },
  ],
};

export const OurWay_data = {
  header: "Günlük Yaşam Temalarıyla İngilizce Öğrenin",
  list: [
    `İngilizceyi ders kitabından öğretmiyoruz. Kendi uzman müfredatımızla size yerel biri gibi İngilizce konuşmayı öğretiyoruz.`,
    
    `Öğretmenleriniz motivasyonunuzu korumanıza yardımcı olacak ve
            güvenli ve destekleyici bir ortamda büyüyebilmeniz için size
            bireysel geri bildirimler verecektir.`,
  ],
};

export const courses_data = {
  heading: "Kurslar ve Icerikleri",
  courses: [
    {
      title: "A1-A2 Seviyesi Kursu",
      duration: "26 Hafta",
      modules: "26 Modul",
      ModuleDuration: "Haftada 1 Modul",
      teachers: "Bilingual - Turkce Konusan Ogretmenler",
      descriptions: [
        "Konuşma ve anlama başta olmak üzere tüm dil becerileri",
        "Kendi hayatınıza adapte edebileceğin esnek bir tempo",
        "Telegramda grup halinde bolca aktivite",
        "Zoom uzerinden Konuşma Partikleri",
      ],
      cards: [
        {
          imageURL: "/assets/courses/video.png",
          oneLinerText: "Anlatimli Videolar",
        },
        {
          imageURL: "/assets/courses/quiz.png",
          oneLinerText: "Interaktif Quizler",
        },
        {
          imageURL: "/assets/courses/telegram.png",
          oneLinerText: "Telegram Gruplari",
        },
        {
          imageURL: "/assets/courses/pdf.png",
          oneLinerText: "Pdf Ders Notlari",
        },

        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom Seansları",
        },
        {
          imageURL: "/assets/courses/turkce.png",
          oneLinerText: "Turkce Anlatim",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Esnek Tempo",
        },
      ],
    },
    {
      title: "B1-B2 Seviyesi Kursu",
      duration: "26 Hafta",
      modules: "13 Modul",
      ModuleDuration: "2 Haftada 1 Modul",
      teachers: "Native - Anadili Ingilizce Olan Ogretmenler",
      descriptions: [
        "Anadili İngilizce olan uzmanlarla farkli konularda yapilan söylesiler.",
        "Kelime ve telaffuza odaklanan bir program.",
        "Her modul bir hafta Gramer ve Sözlü Anlama,bir hafta Kelime Bilgisi ve Telaffuz.",
        "Her ay farklı bir tema, seyahat, iş görüşmesi, hobi...",
      ],
      cards: [
        {
          imageURL: "/assets/courses/subjects.png",
          oneLinerText: "Farkli Temalar",
        },
        {
          imageURL: "/assets/courses/natives.png",
          oneLinerText: "Native Speakers",
        },
        {
          imageURL: "/assets/courses/video.png",
          oneLinerText: "Anlatimli Videolar",
        },

        {
          imageURL: "/assets/courses/quiz.png",
          oneLinerText: "Interaktif Quizler",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom Seansları",
        },
        {
          imageURL: "/assets/courses/pdf.png",
          oneLinerText: "Pdf Ders Notlari",
        },
        {
          imageURL: "/assets/courses/telegram.png",
          oneLinerText: "Telegram Gruplari",
        },
      ],
    },
    {
      title: "Ozel Dersler",
      duration: "30-45 dk",
      modules: "1 Modul",
      ModuleDuration: "Kurs Boyunca Rezervasyon",
      teachers: "Istege Bagli Native / Bilingual Ogretmenler",
      descriptions: [
        "Randevu alınarak istenilen tarih ve saatte ders.",
        "Ders ucreti sabit , grup olarak katılın ve ücreti bölüşün",
        "Anadili İngilizce olan uzmanlardan istediginiz konuda ders alın",
        "İster bir konu üzerinde çalışıyor olun, ister sadece pratik yapın.",
      ],
      cards: [
        {
          imageURL: "/assets/courses/subjects.png",
          oneLinerText: "Konuyu Siz Seçin",
        },
        {
          imageURL: "/assets/courses/practice.png",
          oneLinerText: "Partik Yapın",
        },
        {
          imageURL: "/assets/courses/flexible.png",
          oneLinerText: "Esnek Randevu",
        },
        {
          imageURL: "/assets/courses/splitbill.png",
          oneLinerText: "Ucreti bölüşün",
        },
        {
          imageURL: "/assets/courses/natives.png",
          oneLinerText: "Native Speakers",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom ile katılın",
        },
      ],
    },
  ],
  packages: [
    {
      title: "A1-A2 Seviyesi",
      bgColor: "pink.200",
      color: "purple.800",
      description:
        "26 modülden oluşan ve her hafta 1 modül bitirilen, kendi hayatına adapte edebileceğin esnek bir tempoda konuşma ve anlama başta olmak üzere tüm dil becerileri geliştirilebilen bir program.",
      features: [
        "Türkçe ve İngilizce konu anlatım videoları,",
        "Interaktif quizler",
        "Pratik PDF ders notları",
        "Telegram grupları Pratik Aktiviteleri-A1/A2",
        "Zoom üzerinde Konuşma Seansları (Speaking Groups) -A1/A2",
      ],
    },
    {
      title: "B1-B2 Seviyesi",
      bgColor: "pink.600",
      color: "gray.300",
      description:
        "13 modülden oluşan ve 2 haftada bir modül tamamlanan, her ay anadili İngilizce olan uzmanlarla farklı bir temanın işlendiği, kelime ve telaffuza odaklanan bir programdır.",
      features: [
        "Her ay farklı bir tema, seyahat, iş görüşmesi, hobi...",
        "Anadili İngilizce olan uzmanlarla farkli konularda yapilan söylesiler ile tüm dil becerilerinizi gelistirin.",
        "Her modulde br hafta gramer ve sözlü anlama, bir hafta kelime bilgisi ve telaffuz çalisilir.",
        "Telegram grupları Pratik Aktiviteleri-B1/B2",
        "Zoom üzerinde Konuşma Seansları (Speaking Groups) -B1/B2",
      ],
    },
    {
      title: "Ozel Dersler",
      bgColor: "purple.700",
      color: "white",
      description:
        "Belirleyeceğiniz saatte, seçtiğiniz konu, tema veya alanda birebir veya grup özel dersleri ve canlı dersler.",
      features: [
        "Randevu alınarak istenilen tarih ve saatte ders alın.",
        "Bir gruba katılın ve kurs ücretinizi bölüşün",
        "Anadili İngilizce olan uzmanlardan ders alın",
        "İster bir konu üzerinde çalışıyor olun, ister sadece pratik yapın.",
      ],
    },
  ],
};

export const sampleClass_data = {
  classes: [
    {
      imageUrl: "/assets/studentsuccess/personal-detail.png.webp",
      title: "Kendinizi Tanıtın",
      description:
        "Kendinizi tanıtmak için basit ve günlük kelimeleri öğrenin.",
    },
    {
      imageUrl: "/assets/studentsuccess/hobbies-and-free-time.png.webp",
      title: "Hobiler ve boş zamanlar",
      description:
        "Boş zamanlarınız hakkında konuşmak için yapıları ve kelimeleri öğrenin.",
    },
    {
      imageUrl: "/assets/studentsuccess/the-indefinite-article.png.webp",
      title: "İş Görüşmesi",
      description:
        "Bir iş görüşmesinde resmi konuşmayı nasıl kullanacağınızı öğrenin",
    },
    {
      imageUrl: "/assets/studentsuccess/how-much-does-it-cost.png.webp",
      title: "Fiyatı ne kadar?",
      description:
        "Yurt dışı seyahatlerinizde en çok kullandığınız ifadeleri öğrenin.",
    },
  ],
};

export const studentStories_data = {
  heading: "Öğrencilerimizin başarı hikayelerinden ilham alın",
  description: `Öğrencilerimizin ana dil adımlarını nasıl hayat değiştiren anlara
            dönüştürdüğünü dinleyin. Onların hikayelerinin sizi kendi dilinizi
            kazanmanız için motive etmesine izin verin.`,
  stories: [
    {
      name: "Savannah",
      imageSrc: "/assets/studentsuccess/Savannah.png",
      videoId: 1003996563,
    },
    {
      name: "Jean Marc Lecomte",
      imageSrc: "/assets/testimonialAvatars/jean marc lecomte_photo.jpg",
      description:
        "Kelime haznesini  nasil gelistirdigini ve artik yabanci ulkelerde nasil guvenle akici ingilizce konustugunu anlatıyor!",
      videoId: 1003996563,
    },
    {
      name: "Veronika",
      imageSrc: "/assets/studentsuccess/Veronika.png.webp",
      videoId: 1003996563,
    },
    {
      name: "Olivera",
      imageSrc: "/assets/studentsuccess/Olivera.png.webp",
      description:
        "Sıfır İngilizceden kesintisiz sohbetlere: Olivera, Fluent Chatter'ın Paris'te dil becerilerini nasıl dönüştürdüğünü anlatıyor!",
      videoId: 1003996563,
    },
  ],
};

export const teachers_data = {
  heading: "İngilizce öğretmenlerimizden bazılarıyla tanışın",
  teachers: [
    {
      name: "Seda Buche",
      imageUrl: "/assets/teachers/Seda.png",
      origin: "France",
      teachLanguages: "English",
      speakLanguages: "English, French and Turkish",
    },
    {
      name: "John Doe",
      imageUrl: "/api/placeholder/150/150",
      origin: "Ireland",
      teachLanguages: "English",
      speakLanguages: "English, Gaelic",
    },
    {
      name: "Jane Feather",
      imageUrl: "/api/placeholder/150/150",
      origin: "U.K.",
      teachLanguages: "English",
      speakLanguages: "English, French, and German",
    },
  ],
};

export const testimonials_data = {
  heading: "Öğrencilerimiz Konuşuyor",
  seubheading: "Dünyanın her yerindeki öğrencilerle çalışıyoruz",
  testimonials: [
    {
      testimonialHeading: "",
      testimonialText: `I have been working in the finance department of an international company.
       During my work, I had to attend meetings in English all the time. To improve my spoken and written English on a daily basis, 
       I found Fluent Chatter. 
       I have a better vocabulary and a better knowledge of grammar. 
       Above all, I'm no longer afraid to speak during meetings with foreign colleagues and I have self-confidence when I speak in English.`,
      avatar: {
        name: "Yan Madern",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/Yan_Madern_photo.jpeg",
      },
    },
    {
      testimonialHeading: "",
      testimonialText: `I was always frustrated when I went to foreign countries or when I met native spoken English people.
       I couldn't chat with them. I have better vocabulary and grammar knowledge since I have studied with FluentChatter. 
      I am more confident because I have improved my English fluency. I am more motivated than ever before to make more progress.`,
      avatar: {
        name: "Jean Marc Lecomte",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/jean marc lecomte_photo.jpg",
      },
    },
    {
      testimonialHeading: "",
      testimonialText: `When I started with Fluent Chatter,
       I didn't really have confidence in myself. Now I'm much more comfortable,
       I find my words more easily and I've learned new words and have more confidence in myself. 
      A great experience that I recommend`,
      avatar: {
        name: "Sydney B.",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/Sydney B.jpg",
      },
    },
  ],
};

export const whyus_data = {
  heading: "Neden Fluent Chatter?",
  features: [
    {
      title: "Evinizin Konforunda Eğitim Alın",
      description:
        "Etkileşimli sınıflarımızda özgüveninizi artırın, özel öğretmenlerimizle gündelik konusmalarda gerçek dünya dilini uygulayın.",
      imageSrc: "/assets/whyus1.webp",
    },
    {
      title: "Tematik Moduller",
      description:
        "26 tematik modül, haftada bir modül. Her modülde, hafta boyunca inceleyip pratik yapabileceğiniz video dersler, etkileşimli alıştırmalar ve PDF dosyaları bulacaksınız.",
      imageSrc: "/assets/whyus2.webp",
    },
    {
      title: "Pratik Yap : Gercek Insanlarla Konuş",
      description:
        "Dil öğrenmenin kalbi PRATİK ! Platform dışında Telegram Gruplarında ve Zoom üzerinde yapılan canlı Speaking Groups seansları ile diğer platformlara göre çok daha fazla pratik yap!",
      imageSrc: "/assets/whyus3.webp",
    },
  ],
};

export const pricing_data = {
  heading: "Kurs Ucretlerimiz & Paketler",
  plans: [
    {
      type: "Basic",
      price: "15.000 TL",
      features: [
        "5 class credits",
        "No extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credits",
      ],
      color: "pink.500",
    },
    {
      type: "Super",
      price: "16.500 TL",
      features: [
        "10 class credits",
        "5 extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credits",
      ],
      color: "teal.500",
    },
    {
      type: "Premium",
      price: "18.000 TL",
      features: [
        "15 class credits",
        "10 extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credits",
      ],
      color: "orange.500",
    },
  ],
  describeHeading: "Paket Sistemi & Krediler",
  describeItems: [
    `Kurs Paketleri , kurs seviyesi farketmekizin tum kurslar icin
   (Beginner - Intermediate - Business) gecerlidir.`,
    `Paketlerde sunulan kredileri: Grup Dersleri, Ikili Dersler
   yada ozel dersler icin pakette belirtilen surelerle
   kullanabilirsiniz.`,
    `Grup,Ikili yada Ozel dersler , pakete dahil olan krediler ile
   alinabilir , krediden fazla ders almak icin ek kredi satin
   alabilirsiniz.`,
  ],
};

export const mission_vision_data = {
  vision_heading: "Vizyonumuz",
  vision_text:
    "Amacımız kullanıcıların dil öğreniminde tam potansiyellerine ulaşmalarını sağlamaktır. Dil ögrenmenin beraberinde getirdigi kültürel zenginlik ve kültürler arasi alis-verisi de programimiza dahil ediyoruz. Dili ögrenirken ayni zamanda kendini ifade etme becerisinin gelismesi, dinledigini ya da okudugunu anlama ve etkin iletisim kurma becerileri gibi kisisel gelisimi de hedefleyen kapsamli bir platformu mekan ve zaman siniri olmadan kullanicilara ulastirmayi hedefliyoruz.",
  mission_heading: "Misyonumuz",
  mission_text:
    "Öğrencilere yenilikçi araçlar, uzman rehberliği ve  birbirine destek olan bir topluluk aracılığıyla Ingilizce iletişim kurma becerileri ve özgüveni kazandıran ilgi çekici, erişilebilir ve kişiselleştirilmiş bir öğrenme deneyimi sunmak. Ülkenin neresinde yaşadığınızdan bağımsız olarak kaliteli bir dil öğrenme platformunda kişisel gereksinimlerinize cevap veren, günümüz teknolojisinin sunduğu tüm olanaklardan yararlanarak benzersiz bir dil öğrenme deneyimi sunmak için çalışıyoruz. Dayanışma ve birlikte başarma duygusuyla birbirini motive eden ve destek olan gruplar ile bu deneyimi daha da özel hale getirmeyi amaçlıyoruz.",
};
