import AnimatedCard from "@/components/common/AnimatedCard";
import InfoCard from "@/components/common/InfoCard";
import ReusableModal from "@/components/common/ReusableModal";
import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { getCourseTypes } from "@/features/admin/course/adminCourseApi";
import { clearError, setCurrentCourseType } from "@/features/admin/course/adminCourseSlice";
import { ICourseType } from "@/features/admin/course/adminCourseTypes";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { Box, CardBody, CardHeader, Text, useToast } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import CourseTypeForm, { CourseTypeFormData } from "./CourseTypeForm";
import { createCourseTypeThunk, updateCourseTypeThunk } from "@/features/admin/course/adminCourseActions";
import { formMode } from "@/types/globalTypes";
import { fetchData_ReusableTable } from "@/utils/helpers";
 


const CourseTypeList: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState(formMode.VIEW);

  const { currentCourseType, error } = useAppSelector((state) => state.adminCourse);
  const dispatch = useAppDispatch();
  const toast = useToast();
  

  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const columns = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "name", header: "Type Name" },
    {
      accessorKey: "isActive",
      header: "isActive",
      cell: (context: CellContext<ICourseType, unknown>) => {
        const cellValue = context.getValue();

        return cellValue === true ? (
          <Text as="b" color={"red.600"}>
            ACTIVE
          </Text>
        ) : (
          "Passive"
        );
      },
    },
    { accessorKey: "description", header: "Description" },
  ];

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occured !",
        description: error as string,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [error, toast]);
 
  const handleViewType = (courseType: ICourseType) => {
    dispatch(setCurrentCourseType(courseType));
    //navigate(`/admin/courses/types/modules/${courseType.id}`)
    setMode(formMode.VIEW);
    setShowModal(true);
  };

  const handleCreateType = () => {
    dispatch(clearError());
    dispatch(setCurrentCourseType(null));
    setMode(formMode.CREATE);
    setShowModal(true);
  };

  const handleEditType = (courseType: ICourseType) => {
    dispatch(clearError());
    dispatch(setCurrentCourseType(courseType));
    setMode(formMode.EDIT);
    setShowModal(true);
  };

  const onFormModalClose = (data: CourseTypeFormData) => {
    switch (mode) {
      case formMode.VIEW:
        //do nothing
        break;
      case formMode.CREATE:
        dispatch(createCourseTypeThunk({ ...data, id: "" }));
        setRefreshTrigger((prev) => prev + 1);
        break;
      case formMode.EDIT:
        if (currentCourseType) {
          dispatch(updateCourseTypeThunk({ ...data, id: currentCourseType.id }));
          setRefreshTrigger((prev) => prev + 1);
        }
        break;
    }

    setShowModal(false);
  };
  return (
    <Box p={5}>
      <AnimatedCard>
        <CardHeader bg={"pink.400"} color={"white"} p={3}>
          <Text>Course Types</Text>
        </CardHeader>
        <CardBody>
          <InfoCard title="Course Types List" image="/assets/infocards/LevelTest.png">
            <Text>
              - Course Types are core data for creating courses. Before create a course you must define the course types , and create modules for each course
              type.
            </Text>
            <Text>
              - Then you can use course types to create courses , so when you create a course with the same type of course , you do not need repeat all module
              defining process. System automatically knows which modules will be in the course using the 'course type' definition.
            </Text>
            <Text>
              - Course types cant be deleted after they have been created , since they are used by current or past courses. But you can toggle the status of a
              course type from active to passive or vise versa.
            </Text>
          </InfoCard>

          <ReusableTable
            key={refreshTrigger} // just to trigger a refetch using state
            columns={columns}
            fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<ICourseType>(options, getCourseTypes), [])}
            initialPageSize={10}
            tableTitle="Course Types"
            onEdit={handleEditType}
            onCreate={handleCreateType}
            onView={handleViewType}
          />
          <ReusableModal
            isOpen={showModal}
            headerBg="purple.500"
            title={mode === formMode.CREATE ? "Create New Course Type" : "Course Type"}
            onClose={() => setShowModal(false)}
          >
            <CourseTypeForm mode={mode} onClose={(data: CourseTypeFormData) => onFormModalClose(data)} />
          </ReusableModal>
        </CardBody>
      </AnimatedCard>
    </Box>
  );
};

export default CourseTypeList;
