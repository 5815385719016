// useCurrentUser.ts
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@/hooks/useRedux';
import { fecthUser } from '@/features/users/userActions'; // Adjust the import path as needed

export const useCurrentUser = () => {
  const dispatch = useAppDispatch();
  const { user: authUser } = useAppSelector((state) => state.auth);
  const { isLoading, error, currentUser } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (authUser) {
      dispatch(fecthUser(authUser.id));
    }
  }, [dispatch, authUser]);

  return { isLoading, error, currentUser };
};