import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import SuccessStoryCard from "./SuccessStoryCard";

import { studentStories_data } from "@/constants/dataPublicPages";

const StudentStories = () => {
  const stories = studentStories_data.stories;
  return (
    <Box py={16} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h2" size="xl" textAlign="center">
           {studentStories_data.heading}
          </Heading>
          <Text textAlign="center" fontSize="lg">
            {studentStories_data.description}
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
            {stories.map((story, index) => (
              <SuccessStoryCard key={index} {...story} />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};
export default StudentStories;
