import React from "react";
import {
  Box,
  
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import { heroCreators } from "@/constants/dataPublicPages";

// Import the images
import mobileBackground from "/assets/hero-mobile.jpg";
import desktopBackground from "/assets/hero-desktop_about.jpg";

const HeroContentCreators: React.FC = () => {
  const headingSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const textSize = useBreakpointValue({ base: "md", md: "xl" });
  const contentWidth = useBreakpointValue({ base: "100%", md: "40%" });

  // Responsive subheading text
  const subheadingText = useBreakpointValue({
    base: heroCreators.subheadingText_mobile,
    md: heroCreators.subheadingText_desktop
  });

  return (
    <Box
      position="relative"
      height={{ base: "50vh", md: "70vh" }}
      bg="gray.100"
      backgroundImage={{
        base: `url(${mobileBackground})`,
        md: `url(${desktopBackground})`,
      }}
      backgroundSize="cover"
      backgroundAttachment="fixed"
      backgroundRepeat="no-repeat"
    >
      <Container maxW="container.2xl" height="100%">
        <Flex
          height="100%"
          width="100%"
          justify="start"
          align="start"
          direction={{ base: "column", md: "row" }}
          pt={{ base: 8, md: 0 }}
          pb={{ base: 8, md: 0 }}
        >
          <VStack  
            mt={{ base: 0, md: 20 }}
            ml={{ base: 0, md: 10 }}
            align={{ base: "center", md: "flex-start" }}
            spacing={4}
            width={contentWidth}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              as="h2"
              size={headingSize}
              colorScheme="purple"
              color="purple.700"
            >
            {heroCreators.headingText}
            </Heading>
            <Text fontSize={textSize} colorScheme="pink" color="pink.600">
              {subheadingText}
            </Text>
            
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeroContentCreators;
