import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

interface ClassCardProps {
  imageUrl: string;
  title: string;
  description: string;
}

const SampleClassCard: React.FC<ClassCardProps> = ({
  imageUrl,
  title,
  description,
}) => (
  <Card>
    <CardBody>
      <Image
        src={imageUrl}
        alt={title}
        borderRadius="lg"
        objectFit="cover"
        h="200px"
        w="100%"
      />
      <Stack mt="6" spacing="3">
        <Heading size="md">{title}</Heading>
        <Text>{description}</Text>
      </Stack>
    </CardBody>
    <CardFooter>
      <Button leftIcon={<DownloadIcon />} colorScheme="blue" width="full">
        Dersi Indir
      </Button>
    </CardFooter>
  </Card>
);

export default SampleClassCard;
