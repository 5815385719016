import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

export interface IModalButton {
  text: string;
  onClick: () => void;
  colorScheme?: string;
  isLoading?: boolean;
}

interface ReusableModalProps {
  isOpen: boolean;
  onClose?:  () => void 
  title: string;
  size?: string;
  headerBg? : string ;
  headerColor? : string ;
  children: React.ReactNode;
  buttons?: IModalButton[]  ;
}

const ReusableModal: React.FC<ReusableModalProps> = ({
  isOpen,
  onClose,
  title,
  size = 'xl',
  headerBg ='blue.500',
  headerColor='white',
  children,
  buttons,
}) => {
  return (
    <Modal isOpen={isOpen}  onClose={ onClose ?  onClose : ()=>{} } size={size} isCentered >
      <ModalOverlay />
      <ModalContent >
        <ModalHeader bg={headerBg} color={headerColor} >{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          {buttons && buttons.map((button, index) => (
            <Button
              key={index}
              colorScheme={button.colorScheme}
              onClick={button.onClick}
              isLoading={button.isLoading}
              mr={index < buttons.length - 1 ? 3 : 0}
            >
              {button.text}
            </Button>
          ))}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReusableModal;