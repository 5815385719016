import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import OverlayBox from './OverlayBox';

interface ErrorAlertOverlayProps {
  error: string | null;
}

const ErrorAlertOverlay: React.FC<ErrorAlertOverlayProps> = ({ error }) => {
  if (!error) return null;

  return (
    <OverlayBox>
      <Alert 
        status="error" 
        variant="subtle" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        textAlign="center" 
        maxWidth="90%"
        width="400px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Error
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {error}
        </AlertDescription>
      </Alert>
    </OverlayBox>
  );
};

export default ErrorAlertOverlay;