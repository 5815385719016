import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { AudioPlayerRef } from "@/components/common/AudioPlayer";
import CountDownClock from "@/components/common/CountDownClock";
import { Box, Text, VStack } from "@chakra-ui/react";

type Props = { audioUrl: string | undefined; onAudioEnded: () => void };

const AudioQuestionPlayer: React.FC<Props> = ({ audioUrl, onAudioEnded }) => {
  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const [isAudioEnded, setIsAudioEnded] = useState(false);
  const audioPlayerRef = useRef<AudioPlayerRef>(null);

  useEffect(() => {
    //reset the component , so ut tsrat counting down again
    setIsCountdownComplete(false);
    setIsAudioEnded(false);
  }, [audioUrl]);

  const handleCountdownEnd = () => {
    setIsCountdownComplete(true);
    //play it :
    audioPlayerRef.current?.play();
  };

  const handleAudioEnded = () => {
    setIsAudioEnded(true);
    onAudioEnded();
  };

  return (
    <Box p={5} border={"2px solid pink"}>
      <VStack>
        <Text align={"center"} fontSize={"small"}>
          This is a Listening Question. Please listen to the audio carefully, it
          will play only once!
        </Text>
        {!isCountdownComplete && !isAudioEnded && (
          <CountDownClock countDownFrom={3} onEnd={handleCountdownEnd} />
        )}
        <AudioPlayer
          ref={audioPlayerRef}
          src={audioUrl!}
          autoplay={false}
          onEnded={handleAudioEnded}
          isPausable={false}
          slideable={true}
          disabled={true}
        />
        {isAudioEnded && (
          <Text align={"center"} fontSize={"small"}>
            Audio file ended. Please answer the question.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default AudioQuestionPlayer;
