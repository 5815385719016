import {
    Box,
    Container,
    Heading,
    SimpleGrid,
   
  } from '@chakra-ui/react';
import TeacherCard from './TeacherCard';
import { teachers_data } from '@/constants/dataPublicPages';

  const TeachersSection: React.FC = () => {
    const teachers = teachers_data.teachers
  
    return (
      <Box py={16} bg="gray.50">
        <Container maxW="container.xl">
          <Heading as="h2" size="2xl" mb={8} textAlign="center">
          {teachers_data.heading}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
            {teachers.map((teacher, index) => (
              <TeacherCard key={index} {...teacher} />
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    );
  };
  
  export default TeachersSection;
