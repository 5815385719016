import React from 'react';
import { Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend,
  Filler,
  ChartData, 
  ChartOptions 
} from 'chart.js';
import { Card, CardBody, Heading } from '@chakra-ui/react';

ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend,
  Filler
);

const SoldCourseCountsChart: React.FC = () => {
  const data: ChartData<'line'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Beginner',
        data: [20, 25, 30, 28, 35, 40],
        borderColor: 'rgb(136, 132, 216)',
        backgroundColor: 'rgba(136, 132, 216, 0.5)',
        fill: true,
      },
      {
        label: 'Intermediate',
        data: [15, 18, 20, 22, 25, 30],
        borderColor: 'rgb(130, 202, 157)',
        backgroundColor: 'rgba(130, 202, 157, 0.5)',
        fill: true,
      },
      {
        label: 'Advanced',
        data: [10, 12, 15, 18, 20, 25],
        borderColor: 'rgb(255, 198, 88)',
        backgroundColor: 'rgba(255, 198, 88, 0.5)',
        fill: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Sold Course Counts (Monthly)',
      },
      filler: {
        propagate: true
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>Sold Course Counts (Monthly)</Heading>
        <Line data={data} options={options} />
      </CardBody>
    </Card>
  );
};

export default SoldCourseCountsChart;