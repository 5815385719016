import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Header from "@/components/public/Header";

import GlobalStyles from "@/styles/GlobalStyles";
import Footer from "@/components/public/Footer";

const PublicLayout: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Header  />

        <Box as="main" flex={1}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PublicLayout;
