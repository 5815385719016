import React, { useEffect, useState } from "react";
import { Box, Heading, Link, SimpleGrid, Stack, useToast } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import OngoingCoursesProgress from "@/components/admin/courses/coursedashboard/OngoingCoursesProgress";
import IncomingCoursesList from "@/components/admin/courses/coursedashboard/IncomingCoursesList";
import StatCard from "@/components/admin/stats/StatCard";
import { FaChalkboardTeacher, FaClipboard, FaSchool, FaUserTie } from "react-icons/fa";
import { getCourseDashboardData } from "@/features/admin/course/adminCourseApi";
import { ICourseDashboardData } from "@/features/admin/course/adminCourseTypes";

const AdminCourseDashboardPage: React.FC = () => {
  const toast = useToast();
  const [data, setData] = useState<ICourseDashboardData | null>(null);
  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const response = await getCourseDashboardData();
        setData(response);
      } catch (error) {
        toast({
          title: "Error loading dashboard data",
          description: error instanceof Error ? error.message : "Failed to load dashboard data",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    loadDashboardData();
  }, [toast]);
  if (!data) {
    return <Box>Dahsboard Data Failed !</Box>;
  } 
  return (
    <Box p={5}>
      <Heading mb={5}>Course Management Dashboard</Heading>
      <SimpleGrid columns={{ base: 1, lg: 4 }} spacing={5}>
        <StatCard
          label="Courses Types"
          value={data.summary.courseTypesCount}
          icon={<FaClipboard />}
          change={0}
          bg={"pink.600"}
          boxShadow="dark-lg" // Added shadow
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Link as={RouterLink} to="/admin/courses/types" color="white" mt={1} display="inline-block">
            View Course Types
          </Link>
        </StatCard>
        <StatCard
          label="Modules"
          value={data.summary.modulesCount}
          icon={<FaChalkboardTeacher />}
          change={0}
          bg={"purple.600"}
          boxShadow="dark-lg" // Added shadow
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Link as={RouterLink} to="/admin/courses/modules" color="white" mt={1} display="inline-block">
            View Modules
          </Link>
        </StatCard>
        <StatCard
          label="Courses"
          value={data.summary.coursesCount}
          icon={<FaSchool />}
          change={0}
          bg={"red.600"}
          boxShadow="dark-lg" // Added shadow
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Link as={RouterLink} to="/admin/courses/courselist" color="white" mt={1} display="inline-block">
            View All Courses
          </Link>
        </StatCard>
        <StatCard
          label="Coaches"
          value={data.summary.coachesCount}
          icon={<FaUserTie />}
          change={0}
          bg={"orange.600"}
          boxShadow="dark-lg" // Added shadow
          _hover={{
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Link as={RouterLink} to="/admin/coaches" color="white" mt={1} display="inline-block">
            View Coaches
          </Link>
        </StatCard>
      </SimpleGrid>

      <Box mt={5}>
        <Stack direction={{ base: "column", lg: "row" }} spacing={{ base: 4, lg: 5 }} w="full" minH={{ base: "auto", lg: "400px" }} align="stretch">
          <Box w={{ base: "100%", lg: "66.66%" }} minH={{ base: "400px", lg: "auto" }}>
            <OngoingCoursesProgress data={data.ongoingCourses} />
          </Box>

          <Box w={{ base: "100%", lg: "33.33%" }} minH={{ base: "400px", lg: "auto" }}>
            <IncomingCoursesList data={data.upcomingCourses} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default AdminCourseDashboardPage;
