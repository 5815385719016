import React from "react";
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  Card,
  CardBody,
  StatHelpText,
  Flex,
  StatArrow,
  BoxProps,
} from "@chakra-ui/react";

interface StatCardProps extends BoxProps {
  label: string;
  value: number;
  icon: React.ReactElement;
  change: number;
  bg: string;
  color?: string;
  children?: React.ReactNode;
}

const StatCard: React.FC<StatCardProps> = ({
  label,
  value,
  icon,
  change,
  bg = "green.600",
  color = "white",
  children = null,
  ...rest  // Destructure remaining props
}) => (
  <Card variant={"filled"} colorScheme="pink" bg={bg} color={color}   {...rest}  >
    <CardBody>
      <Stat>
        <Flex alignItems={"flex-start"}>
          <Box as="span" fontSize="5xl" mr={2} color={color}>
            {icon}
          </Box>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={"medium"} isTruncated>
              {label}
            </StatLabel>
            <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
              {value}
            </StatNumber>
            <StatHelpText>
              <StatArrow type={change >= 0 ? "increase" : "decrease"} />
              {change}
            </StatHelpText>
          </Box>
        </Flex>
        {children && (
          <Box mt={1} mb={1}>
            {children}
          </Box>
        )}
      </Stat>
    </CardBody>
  </Card>
);

export default StatCard;
