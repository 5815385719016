import { Box, Container, Flex, VStack, HStack, Text, Heading, SimpleGrid, Button, Link, Icon } from '@chakra-ui/react';
import { FaLinkedin, FaTwitter, FaYoutube, FaFacebook, FaInstagram } from 'react-icons/fa';


const Footer: React.FC = () => {
    return (
      <Box bg="purple.900" color="white" py={16}>
        <Container maxW="container.xl">
          <SimpleGrid columns={{ base: 1, md: 2, lg: 5 }} spacing={8}>
            <VStack align="start" spacing={4}>
              <Heading as="h4" size="md">Fluent Chatter</Heading>
              <Text fontSize="sm">
                A Complete Different Approach to Learn English Online , Focus on the Language spoken in the streets of London, Practice with Native Spreakers 
              </Text>
            </VStack>
            
            <VStack align="start" spacing={2}>
              <Heading as="h4" size="md" mb={2}>Company</Heading>
              
              <Link href="/about">About us</Link>
              <Link href="/howitworks">Our platform</Link>
              <Link href="/contanct">Contact us</Link>
              
              
            </VStack>
            
            <VStack align="start" spacing={2}>
              <Heading as="h4" size="md" mb={2}>Language</Heading>
              <Link href="/courses">English</Link>
              <Link href="/courses">Business English</Link>
              <Link href="/courses">Tourist English</Link>
              <Link href="/courses">Interview English</Link>
              
            </VStack>
            
            <VStack align="start" spacing={2}>
              <Heading as="h4" size="md" mb={2}>How it works</Heading>
              <Link href="/howitworks">Our platform</Link>
              <Link href="/resources">Resources</Link>
              <Link href="/pricing">Prices</Link>
            </VStack>
            
            <VStack align="start" spacing={2}>
              <Heading as="h4" size="md" mb={2}>Support</Heading>
              <Link href="/faq" >FAQ</Link>
              <Link href="/contanct">Contact us</Link>
              <Link href="/creators">Content Creators</Link>
              
              <Button colorScheme="blue" size="sm" mt={2} isDisabled={true}>Become a teacher</Button>
            </VStack>
          </SimpleGrid>
          
          <Flex justify="space-between" align="center" mt={16} flexDir={{ base: "column", md: "row" }}>
            <Text fontSize="sm">© 2024 Fluent Chatter, Ceres Innovation Initiative , Waterford City, Ireland- All Rights Reserved</Text>
            <HStack spacing={4} mt={{ base: 4, md: 0 }}>
              <Link href="imprint">Imprint</Link>
              <Link href="/privacy">Privacy Terms</Link>
          
            </HStack>
          </Flex>
          
          <HStack spacing={4} justify="center" mt={8}>
            <Icon as={FaLinkedin} boxSize={6} />
            <Icon as={FaTwitter} boxSize={6} />
            <Icon as={FaYoutube} boxSize={6} />
            <Icon as={FaFacebook} boxSize={6} />
            <Icon as={FaInstagram} boxSize={6} />
          </HStack>
        </Container>
      </Box>
    );
  };


  export default Footer