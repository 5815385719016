import React, {  useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
 
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Outlet } from "react-router-dom";
import AdminHeader from "@/components/admin/AdminHeader";
import AdminSidebar from "@/components/admin/AdminSidebar";
import ReusableBreadcrumb from "@/components/common/ReusableBreadcrumb";

const AdminLayout: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(false);
 

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const adminLabelsMap = {
    
    admin: "Dashboard",
    "level-tests": "Level Tests",

    // Add more mappings as needed
  };

  return (

    <Box>
    <AdminHeader onSidebarToggle={onOpen} />
    <Box display="flex">
      {/* Desktop Sidebar */}
      <Box
        width={isCollapsed ? "60px" : "250px"}
        bg="purple.600"
        minH="calc(100vh - 60px)"
        transition="width 0.3s"
        position="relative"
        display={{ base: "none", lg: "block" }}
      >
        <AdminSidebar isCollapsed={isCollapsed} />
        <IconButton
          size={"sm"}
          colorScheme="purple"
          aria-label="Toggle Sidebar"
          icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          onClick={toggleSidebar}
          position="absolute"
          right="-15px"
          top="20px"
          zIndex="1"
        />
      </Box>
      
      {/* Main Content Area */}
      <Box flex={1} p={4} width={{ base: "100%", lg: `calc(100% - ${isCollapsed ? "60px" : "250px"})` }}>
        <ReusableBreadcrumb hideBelow='md'
          rootLabel="Admin"
          rootPath="/admin"
          labelsMap={adminLabelsMap}
          separator=">"
          mb={4}
        />
        <Outlet />
      </Box>
    </Box>

    {/* Mobile Drawer */}
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Admin Navigation</DrawerHeader>
          <DrawerBody>
            <AdminSidebar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  </Box>
   
  );
};

export default AdminLayout;
