import React from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import FeatureCard from "../common/FeatureCard";
import { whyus_data } from "@/constants/dataPublicPages";

// Define the prop types for FeatureCard
const WhyUs: React.FC = () => {
  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")}>
      <Container maxW="container.xl" centerContent>
        <Heading as="h1" size="xl" mt={8} mb={8}>
          {whyus_data.heading}
        </Heading>
        <SimpleGrid columns={[1, null, 3]} spacing={8} mb={16}>
          {whyus_data.features.map((f) => (
            <FeatureCard
            key={f.title}
              title={f.title}
              description={f.description}
              imageSrc={f.imageSrc}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default WhyUs;
