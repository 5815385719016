 
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  useColorModeValue,
  Box,
  Center,
  Text,
} from '@chakra-ui/react';
import { ViewIcon, EditIcon, DeleteIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { flexRender, Table as ReactTable } from '@tanstack/react-table';

// Define the props for the TableBody component
interface TableBodyProps<T extends object> {
  table: ReactTable<T>;
  enableSorting: boolean;
  onView?: (row: T) => void;
  onEdit?: (row: T) => void;
  onDelete?: (row: T) => void;
}

function TableBody<T extends object>({
  table,
  enableSorting,
  onView,
  onEdit,
  onDelete,
}: TableBodyProps<T>) {

  // Use Chakra UI's color mode values for consistent theming
  const bgColor = useColorModeValue('white', 'gray.800');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'gray.200');
  const iconHoverColor = useColorModeValue('blue.500', 'blue.300');

  // Calculate the minimum height
  const ESTIMATED_ROW_HEIGHT = 40; // Adjust this value based on your actual row height
  const TABLE_HEADER_HEIGHT = 40; // Adjust this value based on your table header height
  const EXTRA_PADDING = 20; // Some extra padding for good measure
  const minTableHeight =
    10 * ESTIMATED_ROW_HEIGHT +
    TABLE_HEADER_HEIGHT +
    EXTRA_PADDING;

  return (
    <Box minHeight={`${minTableHeight}px`} position="relative">
      <Table size="sm">
        {/* Table Header */}
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  cursor="pointer"
                  userSelect="none"
                  _hover={{ bg: hoverBgColor }}
                  fontSize="xs"
                >
                  <Flex alignItems="center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {/* Sorting indicators */}
                    {enableSorting && (
                      header.column.getIsSorted() === 'desc' 
                        ? <TriangleDownIcon ml={1} fontSize="xs" />
                        : <TriangleUpIcon ml={1} fontSize="xs" />
                    )}
                  </Flex>
                </Th>
              ))}
              {/* Add an extra header for action buttons if they exist */}
              {(onView || onEdit || onDelete) && <Th>Actions</Th>}
            </Tr>
          ))}
        </Thead>
        {/* Table Body */}
        <Tbody>
          {table.getRowModel().rows.map((row, index) => (
            <Tr key={row.id} bg={index % 2 === 0 ? bgColor : hoverBgColor}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id} fontSize="xs">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  
                </Td>
              ))}
              {/* Action buttons */}
              {(onView || onEdit || onDelete) && (
                <Td>
                  <Flex justifyContent="space-around">
                    {onView && (
                      <IconButton
                        aria-label="View"
                        icon={<ViewIcon />}
                        size="sm"
                        variant="ghost"
                        color={iconColor}
                        _hover={{ color: iconHoverColor }}
                        onClick={() => onView(row.original)}
                      />
                    )}
                    {onEdit && (
                      <IconButton
                        aria-label="Edit"
                        icon={<EditIcon />}
                        size="sm"
                        variant="ghost"
                        color={iconColor}
                        _hover={{ color: iconHoverColor }}
                        onClick={() => onEdit(row.original)}
                      />
                    )}
                    {onDelete && (
                      <IconButton
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        size="sm"
                        variant="ghost"
                        color={iconColor}
                        _hover={{ color: 'red.500' }}
                        onClick={() => onDelete(row.original)}
                      />
                    )}
                  </Flex>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Display message when no data is available */}
      {table.getRowModel().rows.length === 0 && (
        <Center position="absolute" top="0" left="0" right="0" bottom="0">
          <Text>No data available</Text>
        </Center>
      )}
    </Box>
  );
}

export default TableBody;