 // src/utils/authUtils.ts

import { AuthState } from './authtypes';

export const isAuthenticated = (state: AuthState): boolean => {
  return !!state.token;
};

export const hasRole = (state: AuthState, role: 'learner' | 'admin' | 'coach'): boolean => {
  return state.user?.role === role;
};

 