import { Control, Controller, FieldValues, Path, UseFormSetValue, UseFormWatch, PathValue } from "react-hook-form";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  InputProps
} from "@chakra-ui/react";
import { LucideIcon } from "lucide-react";

interface ControlledTimeInputProps<T extends FieldValues> extends Omit<InputProps, "onChange" | "value"> {
  hourFieldName: Path<T>;     // Field name for hours in the form
  minuteFieldName: Path<T>;   // Field name for minutes in the form
  control: Control<T>;        // Form control from react-hook-form
  setValue: UseFormSetValue<T>; // setValue from react-hook-form
  watch: UseFormWatch<T>;     // watch from react-hook-form
  leftIcon?: LucideIcon;
  rightIcon?: LucideIcon;
  isReadOnly?: boolean;
}

export function ControlledTimeInput<T extends FieldValues>({ 
  hourFieldName, 
  minuteFieldName, 
  control, 
  setValue,
  watch,
  leftIcon,
  rightIcon,
  isReadOnly,
  ...rest 
}: ControlledTimeInputProps<T>) {
  // Watch both hour and minute values to maintain the input value
  const hour = watch(hourFieldName) as number;
  const minute = watch(minuteFieldName) as number;

  // Format numbers to ensure two digits
  const formatTimeNumber = (num: number): string => 
    num?.toString().padStart(2, '0') || '00';

  // Convert hour and minute to time string
  const getTimeString = (hour: number, minute: number): string => 
    `${formatTimeNumber(hour)}:${formatTimeNumber(minute)}`;

  return (
    <Controller
      name={hourFieldName} // We only need to register one field for Controller
      control={control}
      render={({ field: { ref } }) => (
        <InputGroup>
          {leftIcon && (
            <InputLeftElement>
              <Icon as={leftIcon} />
            </InputLeftElement>
          )}

          <Input
            type="time"
            ref={ref}
            value={getTimeString(hour, minute)}
            onChange={(e) => {
              const [hours, minutes] = e.target.value.split(':');
              setValue(hourFieldName, parseInt(hours) as PathValue<T, Path<T>>);
              setValue(minuteFieldName, parseInt(minutes) as PathValue<T, Path<T>>);
            }}
            isReadOnly={isReadOnly}
            paddingLeft={leftIcon ? "40px" : undefined}
            paddingRight={rightIcon ? "40px" : undefined}
            {...rest}
          />

          {rightIcon && (
            <InputRightElement>
              <Icon as={rightIcon} />
            </InputRightElement>
          )}
        </InputGroup>
      )}
    />
  );
}