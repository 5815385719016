import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ICourseType } from "@/features/admin/course/adminCourseTypes";
import { Box, Button,  FormControl, FormErrorMessage, FormLabel,   HStack, Input, Switch, VStack } from "@chakra-ui/react";
import { useAppSelector } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
//Define the form data structure :
export type CourseTypeFormData = Omit<ICourseType, "id">;

// Define the validation schema
const schema = yup.object().shape({
  name: yup.string().min(3, "Course Type Name must be at least 3 characters").required("Name is required"),
  description: yup.string().required("Description is required"),
  isActive: yup.bool().required("Status is required"),
});

interface Props {
  mode : formMode
  onClose: (data: CourseTypeFormData) => void;
}
const CourseTypeForm: React.FC<Props> = ({ mode, onClose }) => {
  const { currentCourseType, error ,isLoading} = useAppSelector((state) => state.adminCourse);

  //react hook form  init:
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CourseTypeFormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });

  useEffect(() => {
    //set  the react form states to currentCourseType
    if (currentCourseType && (mode=== formMode.EDIT || mode===formMode.CREATE) ) {
      setValue("name", currentCourseType.name);
      setValue("description", currentCourseType.description);
      setValue("isActive", currentCourseType.isActive);
    }
  }, [currentCourseType, mode, setValue]);

  const onSubmit = async (data: CourseTypeFormData) => {
    onClose(data);
  };

  return (
   
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4} m={10}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Course Type Name</FormLabel>
              <Input id="name" {...register("name")} size={"lg"} variant={"flushed"} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel htmlFor="name">Description</FormLabel>
              <Input id="description" {...register("description")} size={"lg"} variant={"flushed"} />
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.isActive}>
              <HStack spacing={5}>
                <FormLabel htmlFor="isActive"> Is Active ? </FormLabel>
                <Switch id="isActive" {...register("isActive")} colorScheme="purple" />
              </HStack>
            </FormControl>
            {mode !==formMode.VIEW  && (
              <Button type="submit" colorScheme="pink" width="full" isLoading={isLoading} mt={4}>
                {mode === formMode.CREATE && 'Create'}
                {mode === formMode.EDIT && 'Update'}
              </Button>
            )}
            {/*Show the state error */ }
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </VStack>
        </Box>
      
  );
};

export default CourseTypeForm;
