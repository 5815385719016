import React, { useCallback } from "react";
import { CardBody, CardHeader, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { createNewLevelTest } from "@/features/admin/levelTest/adminLevelTestActions";
import { useAppDispatch } from "@/hooks/useRedux";
import { LevelTest } from "@/features/admin/levelTest/adminLevelTestTypes";
import AnimatedCard from "@/components/common/AnimatedCard";
import { getAllLevelTests } from "@/features/admin/levelTest/adminLevelTestApi";
import InfoCard from "@/components/common/InfoCard";
import { setCurrentTest } from "@/features/admin/levelTest/adminLevelTestSlice";
import { CellContext } from "@tanstack/react-table";

import { fetchData_ReusableTable } from "@/utils/helpers";

//import DataDisplayArea from "@/components/common/DataDisplayArea";

const LevelTest_List: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  //const [refreshTrigger, setRefreshTrigger] = useState(0);

  const columns = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "version", header: "Version" },
    {
      accessorKey: "isActive",
      header: "Status",
      cell: (context: CellContext<LevelTest, unknown>) => {
        const cellValue = context.getValue();

        return cellValue === true ? (
          <Text as="b" color={"red.600"}>
            ACTIVE
          </Text>
        ) : (
          "Passive"
        );
      },
    },
    { accessorKey: "createdAt", header: "Created At" },
    { accessorKey: "updatedAt", header: "Updated At" },
  ];

  const handleCreateTest = async () => {
    try {
      const newTest = await dispatch(createNewLevelTest({ isActive: false })).unwrap();
      toast({
        title: "Success",
        description: "Level test created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`/admin/level-tests/edit/${newTest.id}`);
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to create level test: ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleViewTest = (test: LevelTest) => {
    if (test) {
      dispatch(setCurrentTest(test));

      navigate(`/admin/level-tests/edit/${test.id}`);
    }
  };

  return (
    <AnimatedCard>
      <CardHeader bg={"pink.400"} color={"white"} p={3}>
        <Text>Level Tests</Text>
      </CardHeader>
      <CardBody>
        <InfoCard title="Level Test List" image="/assets/infocards/LevelTest.png">
          <Text>- There can be only one active level test in all application at a time.</Text>
          <Text>- However you can create multiple level tests and activate them when you wish.</Text>
          <Text>- If you do so , all other level test will be deactivated automaticaly.</Text>
        </InfoCard>

        <ReusableTable
          // key={refreshTrigger} // just to trigger a refetch using state
          columns={columns}
          fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<LevelTest>(options, getAllLevelTests), [])}
          initialPageSize={10}
          tableTitle="Level Tests"
          onCreate={handleCreateTest}
          onView={handleViewTest}
        />
      </CardBody>
    </AnimatedCard>
  );
};

export default LevelTest_List;
