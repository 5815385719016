// This file contains functions for making API calls related to authentication
// These functions can be imported and used in other parts of the app, like in the auth actions
// They abstract away the details of the API calls, making them easier to use and change if needed

// Import the axios instance we've set up with base URL and interceptors
import api from "@/utils/api";

// Import the types we'll use for the request payload and response data
import {
  AuthResponse,
  EmailVerificationData,
  LoginCredentials,
  RegisterUserData,
  RegisterUserResponse,
} from "./authtypes";

// Function to log in a user
export const loginUser = async (
  credentials: LoginCredentials
): Promise<AuthResponse> => {
  // Make a POST request to the login endpoint
  // The <AuthResponse> tells TypeScript that we expect the response data to match the AuthResponse type
  const response = await api.post<AuthResponse>("/auth/login", credentials);

  // Return just the data part of the response
  return response.data;
};

// Function to refresh the authentication token
export const refreshToken = async (refresh_token : string): Promise<AuthResponse> => {
  // Make a POST request to the refresh token endpoint
  // We don't need to send any data for this request !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const response = await api.post<AuthResponse>("/auth/refresh", {refreshToken : refresh_token} );

  // Return the new token data
  return response.data;
};

// Function to log out a user
export const logoutUser = async (): Promise<void> => {
  // Make a POST request to the logout endpoint
  // We don't expect any meaningful data in response, so we use void
  await api.post("/auth/logout");
};

// Function to Register the user
export const register = async (userData: RegisterUserData): Promise<RegisterUserResponse> => {
  const response = await api.post<RegisterUserResponse>(
    "/auth/register",
    userData
  );
  const registerResult: RegisterUserResponse = {
    responseCode: response.status,
    email: userData.email,
    message: response.data.message,
  };
  return registerResult;
};

// Function to Verify Email
export const verifyEmailApi = async (
  verifyData: EmailVerificationData
): Promise<string> => {
  const response = await api.post<{ message: string }>("/auth/verify-email", {
    email: verifyData.email,
    verificationCode: verifyData.verificationCode,
  });
  return response.data.message;
};

// Function to Resend Verification Code
export const resendVerificationCodeApi = async (
  registered_email: string
): Promise<string> => {
  const response = await api.post<{ message: string }>(
    "/auth/resend-verification",
    { email: registered_email }
  );
  return response.data.message;
};

export const requestPasswordResetApi = async (email: string): Promise<string> => {
  const response = await api.post<{ message: string }>('/auth/request-password-reset', { email });
  return response.data.message;
};

export const resetPasswordApi = async (token: string, newPassword: string): Promise<string> => {
  const response = await api.post<{ message: string }>('/auth/reset-password', { token, newPassword });
  return response.data.message;
};