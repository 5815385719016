import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface OverlayBoxProps extends BoxProps {
  children: React.ReactNode;
}

const OverlayBox: React.FC<OverlayBoxProps> = ({ children, ...props }) => (
  <Box
    position="absolute"
    top="0"
    left="0"
    right="0"
    bottom="0"
    backgroundColor="rgba(255, 255, 255, 0.8)"
    display="flex"
    justifyContent="center"
    alignItems="center"
    zIndex={1}
    
    {...props}
  >
    {children}
  </Box>
);

export default OverlayBox;