import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
 
  Icon,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Define the prop types for PricePackage
interface CoursePackageProps {
  title: string;
  description: string;
  features: string[];
  bgColor : string;
  color: string;
}

const CoursePackage: React.FC<CoursePackageProps> = ({
  title,
  description,
  features,
  bgColor,
  color,
}) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    p={6}
    bg= {bgColor}  //{isHighlighted ? "pink.500" : "pink.200"}
    color= {color}
  >
    <Heading size="lg" mb={2}>
      {title}
    </Heading>
    <Text mb={4}>{description}</Text>
    <VStack align="start" spacing={2}>
      {features.map((feature, index) => (
        <HStack key={index}>
          <Icon as={CheckIcon} />
          <Text>{feature}</Text>
        </HStack>
      ))}
    </VStack>
    {/* <Button
      mt={4}
      colorScheme={isHighlighted ? "white" : "pink"}
      variant={isHighlighted ? "outline" : "solid"}
    >
      Learn more
    </Button> */}
  </Box>
);

export default CoursePackage;
