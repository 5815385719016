import React, { useEffect, useState } from "react";
import AnimatedCard from "@/components/common/AnimatedCard";
import InfoCard from "@/components/common/InfoCard";
import { Text, CardBody, CardHeader, Card, HStack, FormLabel, Select, useToast } from "@chakra-ui/react";
import ModuleList from "@/components/admin/courses/ModuleList";
 
import { getCourseTypes } from "@/features/admin/course/adminCourseApi";
import { ICourseType } from "@/features/admin/course/adminCourseTypes";

const ModuleListPage: React.FC = () => {
  const [courseTypes, setCourseTypes] = useState<ICourseType[] | null>(null);
 
  const [selectedCourseType, setSelectedCourseType] = useState<string>("");
  const toast = useToast();
  //get the course types on Load :
  useEffect(() => {
    const getcourseTypeList = async () => {
      try {
        const courseTypeList: ICourseType[] = await getCourseTypes();
        setCourseTypes(courseTypeList);
      } catch (error) {
        const errorMessage = (error as Error).message;
        
        toast({
          title: "Error fetching course types",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    getcourseTypeList();
  }, [toast]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourseType(event.target.value);
  };

  return (
    <AnimatedCard>
      <CardHeader bg="pink.400" color="white" p={3}>
        <Text>Module List</Text>
      </CardHeader>
      <CardBody>
        <InfoCard title={`Modify Module List`} image="/assets/infocards/LevelTest.png">
          <HStack>
            <FormLabel flex={1}>Course Type: </FormLabel>
            <Select flex={2} placeholder="Select a course type" value={selectedCourseType} onChange={handleSelectChange}>
              {courseTypes &&
                courseTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
            </Select>
          </HStack>
          <Text mt={2}>Use the module list to add new moduls, to modify them or to remove them from this course type.</Text>
        </InfoCard>

        <Card flex="1" w={{ base: "100%", md: "auto" }}>
          <CardHeader bg="blue.500" color="white" p={3}>
            <Text>Modules</Text>
          </CardHeader>
          <CardBody>{selectedCourseType && <ModuleList courseTypeId={selectedCourseType} />}</CardBody>
        </Card>
      </CardBody>
    </AnimatedCard>
  );
};

export default ModuleListPage;
