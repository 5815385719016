// src/features/levelTest/levelTestActions.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LevelTest, UserTestAttempt } from './levelTestTypes';
import api from '@/utils/api';
import { setCurrentTest, setTestResult } from './levelTestSlice';

// Async thunk for fetching the level test
export const fetchLevelTest = createAsyncThunk<LevelTest, void, { rejectValue: string }>(
  'levelTest/fetchLevelTest',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Make API call to fetch the level test
      const response = await api.get<LevelTest>('/level-tests/active');
      // Dispatch action to update the current test in the state
      dispatch(setCurrentTest(response.data));
      // Return the fetched test data
      return response.data;
    } catch (error) {
      // Handle errors, differentiating between Axios errors and others
      if (error instanceof AxiosError && error.response) {
        // If it's an Axios error with a response, return the error message from the response
        return rejectWithValue(error.response.data.message || 'Failed to fetch level test');
      }
      // For other types of errors, return a generic error message
      return rejectWithValue('An unexpected error occurred while fetching the level test');
    }
  }
);

// Async thunk for submitting a completed test attempt
export const submitTestAttempt = createAsyncThunk<UserTestAttempt, UserTestAttempt, { rejectValue: string }>(
  'levelTest/submitTestAttempt',
  async (testAttempt, { dispatch, rejectWithValue }) => {
    try {
      // Make API call to submit the test attempt
      const response = await api.post<UserTestAttempt>('/level-tests/attempt', testAttempt);
      // Dispatch action to update the test result in the state
      dispatch(setTestResult(response.data));
      // Return the submitted test attempt data
      return response.data;
    } catch (error) {
      // Handle errors, differentiating between Axios errors and others
      if (error instanceof AxiosError && error.response) {
        // If it's an Axios error with a response, return the error message from the response
        return rejectWithValue(error.response.data.message || 'Failed to submit test attempt');
      }
      // For other types of errors, return a generic error message
      return rejectWithValue('An unexpected error occurred while submitting the test attempt');
    }
  }
);