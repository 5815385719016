//use this to show an overlay component in such as ErrorAlert Component or DataLoading Component in a component shows data: 
/* example : 

<Box position="relative" minHeight="400px">
      <Heading mb={4}>Level Tests</Heading>
      <OverlayContainer>
        <Box minHeight="400px">
            <Table variant="simple">
                <Thead>
                    .....
            </Table>
        </Box>
        <DataLoadingOverlay isLoading={isLoading} />
        <ErrorAlertOverlay error={error} />
      </OverlayContainer>
</Box>
*/


import React from 'react';
import { Box } from '@chakra-ui/react';

interface OverlayContainerProps {
  children: React.ReactNode;
}

const OverlayContainer: React.FC<OverlayContainerProps> = ({ children }) => (
  <Box position="relative">
    {children}
  </Box>
);

export default OverlayContainer;