import React from "react";
import { Box, Container, Heading, SimpleGrid } from "@chakra-ui/react";

import CoursePackage from "./CoursePackage";
import { courses_data } from "@/constants/dataPublicPages";

 

// Define the prop types for FeatureCard
const Courses: React.FC = () => {
  return (
    <Box bg="pink.100" pt={8} pb={20}>
      <Container maxW="container.xl" mt={8} centerContent>
        <Heading as="h2" size="xl" mb={8}>
         {courses_data.heading}
        </Heading>
        <SimpleGrid columns={[1, null, 3]} spacing={8} role="grid">
          {courses_data.packages.map((course) => (
            <CoursePackage {...course}  key={course.title} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Courses;
