// @/components/learner/leveltest/LevelTestInfoCard.tsx

import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  VStack,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { fetchLevelTest } from "@/features/levelTest/levelTestActions";
import AnimatedCard from "@/components/common/AnimatedCard";


interface LevelTestInfoCardProps {
  onStartTest: () => void;
}

const LevelTestInfoCard: React.FC<LevelTestInfoCardProps> = ({
  onStartTest,
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioTested, setIsAudioTested] = useState(false);
  const { currentTest, isLoading } = useAppSelector((state) => state.levelTest);

  // Function to handle the start of the test
  const handleStartTest = async () => {
    if (!isAudioTested) {
      toast({
        title: "Audio Test Required",
        description: "Please test your audio before starting the test.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!currentTest) {
      try {
        // If no test is loaded, fetch a new test from the server
        await dispatch(fetchLevelTest()).unwrap();
        onStartTest();
      } catch (error) {
        // If there's an error fetching the test, show an error toast notification
        toast({
          title: "Failed to start test",
          description: error as string,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      onStartTest();
    }
  };

  return (
    <Flex
      id="margincollapse"
      minHeight={"100%"}
      backgroundImage="/assets/leveltest/backgroundInfoCard.jpg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      //bgGradient="linear(to-r, #FCE5FF, #F687B3)" //  #FF6B3D
      //bgColor={'pink.100'}

      justify="center"
      alignItems="center"
    >
      <AnimatedCard minW={"50%"} opacity={"0.8"}>
        <CardHeader>
          <Heading size="md" color="pink.500">
            Level Test Information
          </Heading>
          <Divider />
        </CardHeader>
        <CardBody>
          <VStack spacing={4} align="stretch">
            <Text>This test consists of 50 questions:</Text>
            <Text>• 40 Text Questions (Grammar & Vocabulary)</Text>
            <Text>• 10 Listening Questions</Text>
            <Text fontWeight="bold">Time Limits:</Text>
            <Text>• Text Questions: 30 seconds each</Text>
            <Text>• Listening Questions: 20 seconds each</Text>
            <Alert status="info">
              <AlertIcon />
              Please test your audio before starting the test.
            </Alert>
            <Box>
              <audio
                ref={audioRef}
                controls
                src="/assets/leveltest/speakerTest.wav"
                onPlay={() => setIsAudioTested(false)}
                onEnded={() => setIsAudioTested(true)}
              >
                Your browser does not support the audio element.
              </audio>
            </Box>
            {isAudioTested && (
              <Box>
                <Alert status="success">
                  <AlertIcon boxSize="40px" mr={0} />
                  <Box ml={4}>
                    <AlertTitle>Audio test completed.</AlertTitle>
                    If you did hear the test audio you're ready to start the
                    test.
                  </Box>
                </Alert>
                <Alert status="warning" mt={1}>
                  <AlertIcon />
                  If you couldn't hear , please check your speaker and sound
                  settings and try playing the test audio again.
                </Alert>
              </Box>
            )}
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme="pink"
            onClick={handleStartTest}
            isLoading={isLoading}
            width="full"
            isDisabled={!isAudioTested}
          >
            Start Test
          </Button>
        </CardFooter>
      </AnimatedCard>
    </Flex>
  );
};

export default LevelTestInfoCard;
