import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, IconButton, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, Flex } from '@chakra-ui/react';
import { FaPlay, FaPause } from 'react-icons/fa';

interface ReusableAudioProps {
  src: string;
  autoplay: boolean;
  isPausable?: boolean;
  slideable?: boolean;
  disabled?: boolean;
  onEnded?: () => void;
}

export interface AudioPlayerRef {
  play: () => void;
}

const ReusableAudio = forwardRef<AudioPlayerRef, ReusableAudioProps>(({ 
  src, 
  autoplay = false,
  isPausable = true, 
  slideable = true,
  disabled = false,
  onEnded 
}, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  
  useImperativeHandle(ref, () => ({
    play: () => {
      if (audioRef.current) {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  }));
 
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    }

    const setAudioTime = () => setCurrentTime(audio.currentTime);

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    }
  }, []);
 
  const handlePlayPause = () => {
    if (!audioRef.current || disabled) return;

    if (isPlaying && isPausable) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }

  const handleSliderChange = (value: number) => {
    if (!audioRef.current || !slideable || disabled) return;
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  }

  const handleAudioEnded = () => {
    setIsPlaying(false);
    if (onEnded) {
      onEnded();
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  return (
    <Box 
    borderWidth="1px" 
    borderRadius="lg" 
    p={4} 
    maxWidth="400px"
    opacity={disabled ? 0.5 : 1}
    pointerEvents={disabled ? 'none' : 'auto'}
  >
    <audio 
      ref={audioRef} 
      src={src} 
      autoPlay={autoplay}
      onEnded={handleAudioEnded}
    />
      <Flex align="center" mb={2}>
        <IconButton
          aria-label={isPlaying ? 'Pause' : 'Play'}
          icon={isPlaying && !isPausable ? <FaPlay /> : (isPlaying ? <FaPause /> : <FaPlay />)}
          onClick={handlePlayPause}
          isDisabled={isPlaying && !isPausable || disabled}
          mr={4}
        />
        <Text fontSize="sm" width="70px">
          {formatTime(currentTime)} / {formatTime(duration)}
        </Text>
      </Flex>
      <Slider
        aria-label="audio-progress"
        value={currentTime}
        min={0}
        max={duration}
        onChange={handleSliderChange}
        focusThumbOnChange={false}
        isDisabled={!slideable || disabled}
      >
        <SliderTrack bg="gray.200">
          <SliderFilledTrack bg="pink.500" />
        </SliderTrack>
        <SliderThumb boxSize={3} />
      </Slider>
      </Box>
  );
});

export default ReusableAudio;