import React from "react";
import {
  Flex,
  Heading,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  Show,
  Hide,
  LinkBox,
  LinkOverlay,
  Image,
 
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import MobileNav from "./MobileNav";
import ButtonLink from "../common/ButtonLink";

 

const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      py={4}
      px={8}
      bg="pink.500" // Dark pink background (same as the "Join Us" button)
      color="white" // White text for contrast
      boxShadow="md"
      //important
      position="sticky" top={0} zIndex="sticky" width="100%"
      
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"-.1rem"} color="white">
          <LinkBox>
            <LinkOverlay href="/">
              <Image src="/assets/logo.png" objectFit="cover" h="40px" />
            </LinkOverlay>
          </LinkBox>
        </Heading>
      </Flex>

      <Show above="md">
        <HStack spacing={4}>
          <LinkBox>
            <Menu>
              <LinkOverlay href="/howitworks">
                <MenuButton
                  as={Button}
                  colorScheme="whiteAlpha"
                  variant="ghost"
                >
                  Nasıl Çalışıyor?
                </MenuButton>
              </LinkOverlay>
            </Menu>
          </LinkBox>
          <LinkBox>
            <LinkOverlay href="/courses">
              <Menu>
                <MenuButton
                  as={Button}
                  colorScheme="whiteAlpha"
                  variant="ghost"
                >
                  Kurslar
                </MenuButton>
              </Menu>
            </LinkOverlay>
          </LinkBox>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="whiteAlpha"
              variant="ghost"
            >
              Menu
            </MenuButton>
            <MenuList bg="pink.500" borderColor="pink.600" color="white">
              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/"
              >
                Ana Sayfa
              </MenuItem>
              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/about"
              >
                Hakkımızda
              </MenuItem>
              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/courses"
              >
                Kurs Icerikleri
              </MenuItem>
              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/pricing"
              >
                Fiyatlar ve Kurs Paketleri
              </MenuItem>

              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/contact"
              >
                Bize Ulaşın
              </MenuItem>
              <MenuItem
                bg="pink.500"
                _hover={{ bg: "pink.600" }}
                as="a"
                href="/faq"
              >
                Sık Sorulan Sorular
              </MenuItem>
            </MenuList>
          </Menu>
          <ButtonLink
            linkTo="/auth/login"
            text="Log In"
            variant="outline"
            colorScheme="whiteAlpha"
          />

          <ButtonLink
            linkTo="/auth/register"
            text="Bize Katılın"
            bg='pink.800'
          />
        </HStack>
      </Show>

      <Hide above="md">
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          colorScheme="whiteAlpha"
          variant="ghost"
        />
      </Hide>

      <MobileNav isOpen={isOpen} onClose={onClose} data-testid="mobile-nav" />
    </Flex>
  );
};

export default Header;
