import {
  Box,
  VStack,
  Heading,
  Text,
  UnorderedList,
  ListItem,
 
  Container,
} from "@chakra-ui/react";

const PrivacyTermsSection = () => {
  return (
    <Box margin="auto" padding={8}>
      <Container maxW="container.xl">
      <VStack spacing={4} align="stretch">
        <Heading as="h1" size="lg" fontSize="24px">Privacy Terms of FluentChatter.com</Heading>
        
        <Text fontSize="sm">
          Last updated: {new Date().toLocaleDateString('en-GB')}
        </Text>
        
        <Text fontSize="sm">
          We are committed to protecting your personal data and respecting your privacy rights. This Privacy Policy explains how we collect, use, and safeguard your information when you use our services.
        </Text>
        
        <Heading as="h2" size="md" fontSize="20px">1. Data Collection and Use</Heading>
        <Text fontSize="sm">
          We collect personal data that you provide directly to us, such as when you create an account, use our services, or contact us for support. This may include:
        </Text>
        <UnorderedList pl={5} fontSize="sm">
          <ListItem>Name and contact information</ListItem>
          <ListItem>Account credentials</ListItem>
          <ListItem>Payment information</ListItem>
          <ListItem>Communication data</ListItem>
          <ListItem>Usage data related to our services</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="md" fontSize="20px">2. Legal Basis for Processing</Heading>
        <Text fontSize="sm">
          We process your personal data based on one or more of the following legal grounds:
        </Text>
        <UnorderedList pl={5} fontSize="sm">
          <ListItem>Your consent</ListItem>
          <ListItem>Performance of a contract with you</ListItem>
          <ListItem>Compliance with a legal obligation</ListItem>
          <ListItem>Our legitimate interests, provided they do not override your fundamental rights and freedoms</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="md" fontSize="20px">3. Data Sharing and Transfers</Heading>
        <Text fontSize="sm">
          We may share your personal data with:
        </Text>
        <UnorderedList pl={5} fontSize="sm">
          <ListItem>Service providers and business partners who support our operations</ListItem>
          <ListItem>Legal authorities when required by law</ListItem>
          <ListItem>Other parties with your consent</ListItem>
        </UnorderedList>
        <Text fontSize="sm">
          Any transfers of personal data outside the European Economic Area (EEA) will be subject to appropriate safeguards as prescribed by data protection legislation.
        </Text>
        
        <Heading as="h2" size="md" fontSize="20px">4. Your Rights</Heading>
        <Text fontSize="sm">
          Under applicable data protection laws, you have the following rights:
        </Text>
        <UnorderedList pl={5} fontSize="sm">
          <ListItem>Right to access your personal data</ListItem>
          <ListItem>Right to rectification of inaccurate data</ListItem>
          <ListItem>Right to erasure ('right to be forgotten')</ListItem>
          <ListItem>Right to restrict processing</ListItem>
          <ListItem>Right to data portability</ListItem>
          <ListItem>Right to object to processing</ListItem>
          <ListItem>Right to withdraw consent at any time</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="md" fontSize="20px">5. Data Security</Heading>
        <Text fontSize="sm">
          We implement appropriate technical and organisational measures to protect your personal data against unauthorised or unlawful processing, accidental loss, destruction, or damage.
        </Text>
        
        <Heading as="h2" size="md" fontSize="20px">6. Data Retention</Heading>
        <Text fontSize="sm">
          We retain your personal data only for as long as necessary to fulfil the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </Text>
        
        <Heading as="h2" size="md" fontSize="20px">7. Changes to This Policy</Heading>
        <Text fontSize="sm">
          We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
        </Text>
        
        <Heading as="h2" size="md" fontSize="20px">8. Contact Us</Heading>
        <Text fontSize="sm">
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default PrivacyTermsSection;
