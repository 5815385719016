
import { logout } from "@/features/auth/authActions";

import { useAppDispatch } from "@/hooks/useRedux";
import {
  Box,
  Grid,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Progress,
  Card,
  CardBody,
  CardHeader,
  Badge,
} from "@chakra-ui/react";

import { FaBook, FaChartBar } from "react-icons/fa"; //FaClipboardList
import { useNavigate } from "react-router-dom";

const LearnerDashboard = () => {
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
 
  const enrolledCourses = [
    { id: 1, title: "React Fundamentals", progress: 75 },
    { id: 2, title: "Advanced JavaScript", progress: 40 },
  ];

  const availableCourses = [
    { id: 3, title: "Node.js Basics", level: "Beginner" },
    { id: 4, title: "Python for Data Science", level: "Intermediate" },
  ];

  //logout temp :
  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      // Handle successful logout (e.g., redirect to login page)
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error("Logout failed:", error);
    }
  };

  const onTakeLevelTest_Click = () => {
    navigate("/learner/takeleveltest");
  };


  return (
    <Box p={5}>
      <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={6}>
        {/* Left Column - User Profile */}
        <VStack spacing={6} align="stretch">
          
          <Card>
            <CardHeader>
              <Heading size="md">Quick Actions</Heading>
            </CardHeader>
            <CardBody>
              <VStack spacing={4}>
                <Button
                  leftIcon={<FaChartBar />}
                  colorScheme="purple"
                  width="100%"
                  onClick={onTakeLevelTest_Click}
                >
                  Take Level Test
                </Button>
                <Button leftIcon={<FaBook />} colorScheme="teal" width="100%">
                  Browse Courses
                </Button>
                <Button onClick={handleLogout}>Logout</Button>
              </VStack>
            </CardBody>
          </Card>
        </VStack>

        {/* Right Column - Courses */}
        <VStack spacing={6} align="stretch">
          <Card>
            <CardHeader>
              <Heading size="md">Enrolled Courses</Heading>
            </CardHeader>
            <CardBody>
              {enrolledCourses.map((course) => (
                <HStack key={course.id} justifyContent="space-between" mb={4}>
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">{course.title}</Text>
                    <Progress
                      value={course.progress}
                      width="200px"
                      size="sm"
                      colorScheme="green"
                    />
                  </VStack>
                  <Button size="sm" colorScheme="blue">
                    Continue
                  </Button>
                </HStack>
              ))}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading size="md">Available Courses</Heading>
            </CardHeader>
            <CardBody>
              {availableCourses.map((course) => (
                <HStack key={course.id} justifyContent="space-between" mb={4}>
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">{course.title}</Text>
                    <Badge colorScheme="blue">{course.level}</Badge>
                  </VStack>
                  <Button size="sm" colorScheme="green">
                    Enroll
                  </Button>
                </HStack>
              ))}
            </CardBody>
          </Card>
        </VStack>
      </Grid>
    </Box>
  );
};

export default LearnerDashboard;
