import {
  Flex,
  Box,
  Container,
  Heading,
  useColorModeValue,
  Text,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import PricingPlan from "./PricePackage";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { pricing_data } from "@/constants/dataPublicPages";

const PricingSection: React.FC = () => {
  const plans = pricing_data.plans;

  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")} pt={2} pb={8}>
      <Container maxW="container.xl" mt={8} centerContent>
        <Heading as="h2" size="xl" mb={8}>
          {pricing_data.heading}
        </Heading>
        <Flex justifyContent="center" alignItems="center" p={8}>
          <Flex flexWrap="wrap" justifyContent="center" gap={8}>
            {plans.map((plan) => (
              <PricingPlan key={plan.type} {...plan} />
            ))}
          </Flex>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          p={0}
          bg="white"
          borderRadius="lg"
          boxShadow="md"
        >
          <VStack align="stretch">
            <Box
              h="40px"
              bg="purple.800"
              color="white"
              borderTopRadius="lg"
              textAlign="center"
              pt={2}
            >
              <Text fontSize="md">{pricing_data.describeHeading}</Text>
            </Box>
            <Box p={3}>
              {pricing_data.describeItems.map((itm, idx) => (
                <HStack key={idx}>
                  <Icon as={ArrowRightIcon} color={"purple.500"} />
                  <Text fontSize="sm">{itm}</Text>
                </HStack>
              ))}
            </Box>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default PricingSection;
