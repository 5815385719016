import React from 'react';
import { VStack, Text, Button, Box, Avatar, HStack } from '@chakra-ui/react';

interface MessageListProps {
  onItemClick: (id: string) => void;
}

const MessageList: React.FC<MessageListProps> = ({ onItemClick }) => {
  // TODO: Replace with actual messages data
  const messages = [
    { id: '1', sender: 'John Doe', preview: 'Hey, how are you?' },
    { id: '2', sender: 'Jane Smith', preview: 'Dont forget about the meeting' },
  ];

  return (
    <VStack spacing={2} align="stretch" p={2}>
      {messages.map((message) => (
        <Box key={message.id} p={2} _hover={{ bg: 'gray.100' }} cursor="pointer" onClick={() => onItemClick(message.id)}>
          <HStack>
            <Avatar size="sm" name={message.sender} />
            <Box>
              <Text fontWeight="bold">{message.sender}</Text>
              <Text fontSize="sm">{message.preview}</Text>
            </Box>
          </HStack>
        </Box>
      ))}
      <Button size="sm" colorScheme="blue">Show All Messages</Button>
    </VStack>
  );
};

export default MessageList;