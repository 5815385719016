import { toggleTestActive } from "@/features/admin/levelTest/adminLevelTestActions";
import {
  Box,
  Text,
  useToast,
  CardBody,
  CardHeader,
  Card,
    Stack,
  
} from "@chakra-ui/react";
import AnimatedCard from "@/components/common/AnimatedCard";
import InfoCard from "@/components/common/InfoCard";
import LevelTest_Questions from "./LevelTest_Questions";
import LevelTest_View from "./LevelTest_View";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { clearError } from "@/features/admin/levelTest/adminLevelTestSlice";

interface Admin_LevelTest_EditProps {
  testId: string;
}

const LevelTest_Edit: React.FC<Admin_LevelTest_EditProps> = ({ testId }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const {
    currentTest: test,
    isLoading,
    error,
  } = useAppSelector((state) => state.adminLevelTest);


  const handleToggleActive = async () => {
    if (test) {
      try {
        dispatch(clearError());
        await dispatch(toggleTestActive(test.id)).unwrap();
        toast({
          title: "Test updated",
          description: `Test ${test.version} is now ${
            !test.isActive ? "active" : "inactive"
          }.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error updating test",
          description: (error as Error).message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  if (!test) return <Box>Test not found</Box>;
  if (test?.id !== testId) return <Box>Wrong Test Id!</Box>;

  return (
    <AnimatedCard>
      <CardHeader bg="pink.400" color="white" p={3}>
        <Text>Edit Level Test</Text>
      </CardHeader>
      <CardBody>
        <InfoCard
          title="Edit Level Test"
          image="/assets/infocards/LevelTest.png"
        >
          <Text color={test.isActive ? "red" : "inherit"} mb={2}>
            {test.isActive
              ? "This is the Only Active Test at the moment. If you deactivate it without activating any other level test, users will not be able to get a level test!"
              : "There can be only one active level test in all application at a time, hence if you set this level test as 'active' all other level tests will be deactivated automatically."}
          </Text>
          <Text>
            Use the questions list to add new questions, to modify them or to
            remove them from this test.
          </Text>
        </InfoCard>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 4, md: 3 }}
          mt={4}
        >
          <LevelTest_View
            test={test}
            toggleActive={handleToggleActive}
            isLoading={isLoading}
            error={error}
          />
          <Card flex="1" w={{ base: "100%", md: "auto" }}>
            <CardHeader bg="blue.500" color="white" p={3}>
              <Text>Questions</Text>
            </CardHeader>
            <CardBody>
              <LevelTest_Questions testId={testId} />
            </CardBody>
          </Card>
        </Stack>
      </CardBody>
    </AnimatedCard>
  );
};

export default LevelTest_Edit;