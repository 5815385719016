import React from "react";
import {
  Box,
  Text,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  useSteps,
  StepIcon,
  StepNumber,
} from "@chakra-ui/react";

import { howitworks_data } from "@/constants/dataPublicPages";

interface StepInfo {
  title: string;
  description: string;
  tooltip: string;
}

const steps: StepInfo[] = howitworks_data.stepper_steps;

const HowItWorksStepper: React.FC = () => {
  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  return (
    <Box>
      <Stepper
        index={activeStep}
        orientation="vertical"
        colorScheme="yellow"
        //height="400px"
        // width={{ md: "60%" }}
        gap="0"
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <Box>
              {/* Wrapping StepIndicator in a Box */}
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
            </Box>

            <Box>
              <StepTitle>{step.title}</StepTitle>
              <Text
                mt={1}
                mb={1}
                fontSize="xs"
                colorScheme="pink"
                color="pink.100"
              >
                {step.description}
              </Text>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HowItWorksStepper;
