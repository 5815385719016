import FAQSection from "@/components/public/FAQSection";
import HeroHowItWorks from "@/components/public/HeroHowItWorks";
import HowItWorksSection from "@/components/public/HowItWorks";
import OurWay from "@/components/public/OurWay";
import { Box } from "@chakra-ui/react";
import React from "react";

const HowItWorks: React.FC = () => (
  <>
    <HeroHowItWorks />
    <HowItWorksSection />
    <Box color="gray.600" mt={12} mb={8}>
      <OurWay color="gray.600"/>
    </Box>
    <FAQSection/>
  </>
);

export default HowItWorks;
