// src/features/levelTest/levelTestSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Answer, LevelTest, UserTestAttempt } from "./levelTestTypes";
 

// Define the shape of the level test state
interface LevelTestState {
  currentTest: LevelTest | null; // The current test being taken
  startedAt: number | null;
  completedAt: number | null;
  currentQuestionIndex: number; // Index of the current question
  userAnswers: { [questionId: string]: Answer }; // User's answers, keyed by question ID
  testProgress: number; // Progress through the test (0-100)
  isLoading: boolean; // Loading state for async operations
  error: string | null; // Error message, if any
  testResult: UserTestAttempt | null; // Result of the completed test
}

// Initial state for the level test slice
const initialState: LevelTestState = {
  currentTest: null,
  startedAt: null,
  completedAt: null,
  currentQuestionIndex: 0,
  userAnswers: {},
  testProgress: 0,
  isLoading: false,
  error: null,
  testResult: null,
};

// Create the level test slice
const levelTestSlice = createSlice({
  name: "levelTest",
  initialState,
  reducers: {
    // Set the current test and reset related state
    setCurrentTest: (state, action: PayloadAction<LevelTest>) => {
      state.currentTest = action.payload;
      state.currentQuestionIndex = 0;
      state.userAnswers = {};
      state.testProgress = 0;
    },
    //Update test attempt start date
    setStartTime: (state, action: PayloadAction<number>) => {
      state.startedAt = action.payload;
    },
    //Update test attempt completed date
    setCompletedTime: (state, action: PayloadAction<number>) => {
      state.completedAt = action.payload;
    },
    // Update the current question index and recalculate progress
    setCurrentQuestionIndex: (state, action: PayloadAction<number>) => {
      state.currentQuestionIndex = action.payload;
      state.testProgress =
        (action.payload / (state.currentTest?.questions.length ?? 1)) * 100;
    },
    // Record a user's answer for a specific question
    setUserAnswer: (
      state,
      action: PayloadAction<{ questionId: string;  answer : Answer}>
    ) => {
      state.userAnswers[action.payload.questionId] = action.payload.answer;
    },
    // Set the final test result
    setTestResult: (state, action: PayloadAction<UserTestAttempt>) => {
      state.testResult = action.payload;
    },

    // Reset the test state (e.g., when starting a new attempt)
    resetTest: (state) => {
      state.currentQuestionIndex = 0;
      state.userAnswers = {};
      state.testProgress = 0;
      state.testResult = null;
    },
  },
});

// Export action creators
export const {
  setCurrentTest,
  setCurrentQuestionIndex,
  setUserAnswer,
  setTestResult,
  setStartTime,
  setCompletedTime,
  resetTest,
} = levelTestSlice.actions;

// Export the reducer
export default levelTestSlice.reducer;
