import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { getModuleList } from "@/features/admin/course/adminCourseApi";
import { clearError, setCurrentModule } from "@/features/admin/course/adminCourseSlice";
import { IModule } from "@/features/admin/course/adminCourseTypes";
import { useAppDispatch } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
import { fetchData_ReusableTable } from "@/utils/helpers";
//import { useAppSelector } from "@/hooks/useRedux";
import { Box } from "@chakra-ui/react";
import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
//Define the form data structure :

interface Props {
  courseTypeId: string;
}

const ModuleList: React.FC<Props> = ({ courseTypeId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const columns = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "order", header: "Module No" },
    { accessorKey: "name", header: "Module Name" },
    { accessorKey: "description", header: "Description" },
  ];

 
  const handleViewModule = (module: IModule) => {
    dispatch(clearError());
    dispatch(setCurrentModule(module));

    navigate(`/admin/courses/modules/${courseTypeId}/${formMode.VIEW}/${module.id}`);
  };

  const handleCreateModule = () => {
    dispatch(clearError());
    dispatch(setCurrentModule(null));

    navigate(`/admin/courses/modules/${courseTypeId}/${formMode.CREATE}/null`);
  };

  const handleEditModule = (module: IModule) => {
    dispatch(clearError());
    dispatch(setCurrentModule(module));

    navigate(`/admin/courses/modules/${courseTypeId}/${formMode.EDIT}/${module.id}`);
  };

  return (
    <Box>
      <ReusableTable
        //  key={refreshTrigger} // just to trigger a refetch using state
        columns={columns}
        fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<IModule>(options, () => getModuleList(courseTypeId)), [courseTypeId])}
        initialPageSize={10}
        tableTitle="Modules"
        onEdit={handleEditModule}
        onCreate={handleCreateModule}
        onView={handleViewModule}
      />
    </Box>
  );
};

export default ModuleList;
