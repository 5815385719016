/*

---------------------- Our Redux Store ------------------
related files : 
- Slices : /src/features/....slice.ts
- Hooks : /src/hooks/useRedux.ts
- API : /src/utils/api.ts
*/

// src/store/store.ts
import { configureStore, combineReducers, Action, ThunkAction } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, Transform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CryptoJS from "crypto-js";
import authReducer from "../features/auth/authSlice";
import levelTestReducer from "../features/levelTest/levelTestSlice";
import userReducer from "../features/users/userSlice";
//admin reducers
import adminLevelTestReducer from "@/features/admin/levelTest/adminLevelTestSlice";
import adminCourseReducer from "@/features/admin/course/adminCourseSlice";
import adminCouchesReducer from "@/features/admin/coaches/adminCoachSlice";
import adminDashboardReducer from '@/features/admin/dashboard/adminDashboardSlice';

// Use the environment variable for the encryption key
const encryptionKey = import.meta.env.VITE_ENCRYPTION_KEY;

if (!encryptionKey) {
  throw new Error("VITE_ENCRYPTION_KEY is not set in the environment variables");
}

const encryptData = (data: unknown): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

const decryptData = (data: string): unknown => {
  const bytes = CryptoJS.AES.decrypt(data, encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    {
      in: (state: unknown): string => encryptData(state),
      out: (state: string): unknown => decryptData(state),
    } as Transform<unknown, string>,
  ],
  whitelist: ["auth"], // only persist auth
};

const rootReducer = combineReducers({
  auth: authReducer,
  levelTest: levelTestReducer,
  adminLevelTest: adminLevelTestReducer,
  adminCourse: adminCourseReducer,
  adminCouch: adminCouchesReducer,
  user: userReducer,
  adminDashboard: adminDashboardReducer,
  // Add other reducers here
});

export type RootState = ReturnType<typeof rootReducer>;

// Use a type assertion here
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
       
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
