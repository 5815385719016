import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import RequestPasswordReset from '@/components/auth/RequestPasswordReset';

const RequestResetPasswordPage: React.FC = () => {
  return (
    <Container maxW="container.md" py={10}>
      <Box boxShadow="md" p={6} rounded="md" bg="white">
        <RequestPasswordReset />
      </Box>
    </Container>
  );
};

export default RequestResetPasswordPage;