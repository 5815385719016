import {
    
    Text,
    
    Card,
    CardBody,
    VStack,
  } from '@chakra-ui/react';
  
  interface ScenarioCardProps {
    emoji: string;
    description: string;
  }
  
  const ScenarioCard: React.FC<ScenarioCardProps> = ({ emoji, description }) => (
    <Card variant="outline" h="100%">
      <CardBody>
        <VStack spacing={4} align="center">
          <Text fontSize="4xl">{emoji}</Text>
          <Text textAlign="center" fontSize="sm">{description}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
  
  export default ScenarioCard