import CourseList from "@/components/admin/courses/CourseList";
 

const CourseListPage: React.FC = () => {
  return (
     
      <CourseList />
    
  );
};

export default CourseListPage;
