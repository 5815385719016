import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
  countDownFrom: number;
  color?: string;
  size?: string;
  thickness?: string;
  onEnd: () => void;
};

const CountDownClock = ({
  countDownFrom,
  onEnd,
  color = "blue.400",
  size = "50px",
  thickness = "8px",
}: Props) => {
  const [val, setVal] = useState(countDownFrom);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (val > 0) {
      timer = setInterval(() => {
        setVal((prev) => prev - 1);
      }, 1000);
    }
    if (val == 0) {
      onEnd();
    }

    return () => clearInterval(timer);
  }, [val,onEnd]);

  return (
    <CircularProgress
      max={countDownFrom}
      value={val}
      color={color}
      size={size}
      thickness={thickness}
    >
      <CircularProgressLabel>{val}</CircularProgressLabel>
    </CircularProgress>
  );
};

export default CountDownClock;
