import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  useToast,
  Flex,
  Text,
  Link,
  Show,
} from "@chakra-ui/react";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { registerUser } from "@/features/auth/authActions";

// Define the form data structure
interface RegisterFormData {
  username: string;
  email: string;
  password: string;
}

// Define the validation schema
const schema = yup.object().shape({
  username: yup
    .string()
    .min(3, "Username must be at least 3 characters")
    .required("Username is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const RegisterForm: React.FC = () => {
 

  //react hook form  init:
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { isLoading, error } = useAppSelector((state) => state.auth);

  //clear the error in store only for first render ( since the persistencey is on , we dont want to see the last error when first load)
  /*  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]); */

  const onSubmit = async (data: RegisterFormData) => {
    try {
      const registerResponse = await dispatch(registerUser(data)).unwrap();
      switch (registerResponse.responseCode) {
        case 201:
          //if registration succeed , go to email verification page
          navigate("/auth/verify-email");
          break;
        case 202:
          //if user already registered but not verified yet , warn and then goto verification page:
          toast({
            title: "Need Email Verification",
            description: registerResponse.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          navigate("/auth/verify-email");
          break;
      }
    } catch (error) {
      toast({
        title: "Registration failed",
        description: error as string,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box h={"80vh"}>
        <Flex height={"100%"}>
          <Show above="md">
            <Box
              bgColor={"purple"}
              bgImage="url('/assets/signup.jpeg')"
              bgSize={"cover"}
              flex="2"
            ></Box>
          </Show>
          <Box flex={1.2} overflowY={"auto"}>
            <Card
              maxWidth={["90vw", "100%"]}
              margin="auto"
              mt={[10, 0]}
              mb={[10, 0]}
              height={[null, "100%"]}
            >
              <CardHeader>
                <Heading size={"lg"} ml={8} mt={10}>
                  Sign Up
                </Heading>
              </CardHeader>

              <CardBody>
                <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={4} m={10}>
                    <FormControl isInvalid={!!errors.username}>
                      <FormLabel htmlFor="username">Username</FormLabel>
                      <Input
                        id="username"
                        {...register("username")}
                        size={"lg"}
                        variant={"flushed"}
                      />
                      <FormErrorMessage>
                        {errors.username?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input
                        id="email"
                        type="email"
                        {...register("email")}
                        size={"lg"}
                        variant={"flushed"}
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        id="password"
                        type="password"
                        {...register("password")}
                        size={"lg"}
                        variant={"flushed"}
                      />
                      <FormErrorMessage>
                        {errors.password?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <Button
                      type="submit"
                      colorScheme="pink"
                      width="full"
                      isLoading={isLoading}
                      mt={4}
                    >
                      Sign Up
                    </Button>
                    <Text color={"purple"} as={"b"}>
                      Already have an account ?
                      <Link href="/auth/login"> Log In</Link>
                    </Text>

                    {error && <FormErrorMessage>{error}</FormErrorMessage>}
                  </VStack>
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default RegisterForm;
