// @/components/learner/leveltest/QuestionCard.tsx

import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  VStack,
  Text,
  Progress,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { Answer, Question } from "@/features/levelTest/levelTestTypes";
import AudioQuestionPlayer from "./AudioQuestionPlayer";

// Define the props for the QuestionCard component
interface QuestionCardProps {
  question: Question; // The current question to display
  onAnswer: (answer: Answer) => void; // Callback function when an answer is submitted
  timeLimit: number; // Time limit for the question in seconds
  index: number;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  onAnswer,
  timeLimit,
  index,
}) => {
  // State to keep track of remaining time for the question
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  //for audio question state to keep if audio has been listened by the user
  const [isAudioEnded, setIsAudioEnded] = useState(false);

  // State to store the selected answer option
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );



  const createAnswer = useCallback(() => {
    return {
      selectedOptionId: selectedOption || "",
      questionId: question.id,
      correctOptionId: question.correctOptionId,
      timeSpent: timeLimit,
    };
  },[question.correctOptionId, question.id, selectedOption, timeLimit]);

  useEffect(() => {
    //reset when question changes
    setTimeLeft(timeLimit);
    setSelectedOption(undefined);
    setIsAudioEnded(false);
  }, [question, timeLimit]);

  // Effect to handle timers for audio countdown and question time limit
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAudioEnded || question.type === "text") {
      // Set up a timer that updates every second (1000 milliseconds)
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          // Check if the time has run out (1 second or less remaining)
          if (prevTime <= 1) {
            // Stop the timer to prevent further updates
            clearInterval(timer);
            // Submit the current answer (or an empty string if no option selected)
            onAnswer(createAnswer());
            // Set the time left to 0
            return 0;
          }
          // If time hasn't run out, decrement the time by 1 second
          return prevTime - 1;
        });
      }, 1000);
    }

    // Cleanup function to clear the interval when component unmounts or dependencies change
    return () => clearInterval(timer);
  }, [createAnswer, isAudioEnded, onAnswer, question.type, selectedOption]);

  //handler for when audio part of eustion played and ended :
  const handleAudioEnded = useCallback(() => {
    setIsAudioEnded(true);
  }, []);

  // Handler for when an option is selected
  const handleOptionSelect = (optionId: string) => {
    setSelectedOption(optionId);
  };

  // Handler for submitting the answer
  const handleSubmit = () => {
    onAnswer(createAnswer());
  };

  return (
    <Card w={"95%"} m="auto" mt={8} mb={8} scrollBehavior={"auto"}>
      <CardHeader>
        <Heading size="md" color="pink.500">
          Question - {index}
        </Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing={4} align="stretch">
          <Box p={5} border={"2px solid purple"} bg={'pink.200'}>
            <Text>{question.text}</Text>
          </Box>
          {question.type === "listening" && (
            <AudioQuestionPlayer
              audioUrl={question.audioUrl}
              onAudioEnded={handleAudioEnded}
            />
          )}
         
            <RadioGroup onChange={handleOptionSelect} value={selectedOption} ml={5} isDisabled={question.type !== "text" && !isAudioEnded }>
              <VStack align="stretch">
                {question.options.map((option) => (
                  <Radio key={option.id} value={option.id} colorScheme="pink">
                    {option.text}
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
          
          <Button
            colorScheme="pink"
            onClick={handleSubmit}
            isDisabled={
              !selectedOption ||
              (question.type === "listening" && !isAudioEnded)
            }
          >
            Submit Answer
          </Button>
          <Progress value={(timeLeft / timeLimit) * 100} colorScheme="pink" />
          <Text textAlign="center">Time left: {timeLeft} seconds</Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default QuestionCard;
