import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import ResetPassword from '@/components/auth/ResetPassword';

const ResetPasswordPage: React.FC = () => {
  return (
    <Container maxW="container.md" py={10}>
      <Box boxShadow="md" p={6} rounded="md" bg="white">
        <ResetPassword />
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;