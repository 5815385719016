import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";
import {
  Box,
  Card,
  CardBody,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getUserDistribution } from "@/features/users/userApi";
import DataDisplayArea from "@/components/common/DataDisplayArea";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface IUserDistributionData {
  totalUsers: number;
  usersWithLevelTest: number;
  usersWithCourseEnrollment: number;
}

const UserDistributionChart: React.FC = () => {
  const [distributionData, setDistributionData] =
    useState<IUserDistributionData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUserDistribution = async () => {
    setLoading(true);
    const result = await getUserDistribution();
    if ("error" in result) {
      setError(result.error);
    } else {
      setDistributionData(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDistribution();
  }, []);

  const chartData: ChartData<"pie"> | null = distributionData
    ? {
        labels: ["No Level Test", "Taken Level Test", "Course Enrollment"],
        datasets: [
          {
            data: [
              distributionData.totalUsers - distributionData.usersWithLevelTest,
              distributionData.usersWithLevelTest,
              distributionData.usersWithCourseEnrollment,
            ],
            backgroundColor: ["#0088FE", "#00C49F", "#FFBB28"],
            hoverBackgroundColor: ["#0077E0", "#00B48F", "#FFAA18"],
          },
        ],
      }
    : null;

  const options: ChartOptions<"pie"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc: number, data: number) => acc + data,
              0
            );
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>
          User Distribution
        </Heading>
        <DataDisplayArea isLoading={loading} error={error}>
          <VStack spacing={4} align="stretch">
            <Heading size="md">User Distribution</Heading>
            {chartData && (
              <Box height="300px">
                <Pie data={chartData} options={options} />
              </Box>
            )}
            <HStack justifyContent="space-between">
              <Text>Total Users: {distributionData?.totalUsers}</Text>
              <Text>
                Users with Level Test: {distributionData?.usersWithLevelTest}
              </Text>
              <Text>
                Users with Course Enrollment:{" "}
                {distributionData?.usersWithCourseEnrollment}
              </Text>
            </HStack>
          </VStack>
        </DataDisplayArea>
      </CardBody>
    </Card>
  );
};

export default UserDistributionChart;
