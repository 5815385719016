import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICourse, ICourseType, IModule } from "./adminCourseTypes";
import { AxiosError } from "axios";
import { createCourseApi, createCourseTypeApi, createModuleApi, toggleCourseActiveApi, toggleCourseEnrollmentApi, updateCourseApi, updateCourseTypeApi, updateModuleApi } from "./adminCourseApi";

export const createCourseTypeThunk = createAsyncThunk("adminCourse/createCourseType", async (courseTypeData: ICourseType, { rejectWithValue }) => {
  try {
    return await createCourseTypeApi(courseTypeData);
  } catch (error) {
    //If the API returns a status code other than 201 (or any 2xx), Axios will throw an error.
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Course Type failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateCourseTypeThunk = createAsyncThunk("adminCourse/updateCourseType", async (courseTypeData: ICourseType, { rejectWithValue }) => {
  try {
    return await updateCourseTypeApi(courseTypeData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Course Type failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createModuleThunk = createAsyncThunk("adminCourse/createModule", async (moduleData: IModule, { rejectWithValue }) => {
  try {
    return await createModuleApi(moduleData);
  } catch (error) {
    //If the API returns a status code other than 201 (or any 2xx), Axios will throw an error.
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Module failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateModuleThunk = createAsyncThunk("adminCourse/updateModule", async (moduleData: IModule, { rejectWithValue }) => {
  try {
    return await updateModuleApi(moduleData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Module failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createCourseThunk = createAsyncThunk("adminCourse/createCourse", async (courseData: ICourse, { rejectWithValue }) => {
  try {
    return await createCourseApi(courseData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Course failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateCourseThunk = createAsyncThunk("adminCourse/updateCourse", async (courseData: ICourse, { rejectWithValue }) => {
  try {
    return await updateCourseApi(courseData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Course failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const toggleCourseActiveThunk = createAsyncThunk("adminCourse/toggleCourseAtive", async ( courseId:string, { rejectWithValue }) => {
  try {
    return await toggleCourseActiveApi(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Toggle  Course Active failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});




export const toggleCourseEnrollmentThunk = createAsyncThunk("adminCourse/toggleCourseEnrollment", async ( courseId:string, { rejectWithValue }) => {
  try {
    return await toggleCourseEnrollmentApi(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Toggle Course Enrollment failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});
