import React from "react";
import { Card, CardHeader, CardBody, Stack, Heading, Text, List, ListItem, Divider, Alert, AlertDescription, useToast, HStack, FormLabel, Spinner, Switch } from "@chakra-ui/react";

import { enumCourseStatus, ICourse } from "@/features/admin/course/adminCourseTypes";
import { useConfirmDialog } from "@/components/common/ConfirmationModal/useConfirmDialog";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { toggleCourseActiveThunk } from "@/features/admin/course/adminCourseActions";
import { unwrapResult } from "@reduxjs/toolkit";

interface StatusSectionProps {
  currentCourse?: ICourse | null;
}

const StatusSection: React.FC<StatusSectionProps> = ({ currentCourse }) => {
  const { isLoading } = useAppSelector((state) => state.adminCourse);
  const dispatch = useAppDispatch();
  const toast = useToast();
  let canToogleActivation = false;

  const { confirm, ConfirmationDialog } = useConfirmDialog();

  const handleToggleActive = async () => {
    if (currentCourse) {
      try {
        const userConfirmed = await confirm(`Are you sure you want to ${currentCourse?.isActive ? " deactivate " : " re-activate "}  the course?`);
        if (userConfirmed) {
          // User clicked Yes
          // Get the result from the thunk
          const result = await dispatch(toggleCourseActiveThunk(currentCourse.id));
          const updatedCourse = unwrapResult(result);
          console.log(currentCourse);
          toast({
            title: "Course Active Status Toggled.",
            description: `Course activeness is now :  ${updatedCourse.isActive}`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        // User clicked No - do nothing
      } catch (error) {
        toast({
          title: "Error toggling course active !",
          description: error instanceof Error ? error.message : "Failed to toogle course!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  if (currentCourse) {
    canToogleActivation = currentCourse.status === enumCourseStatus.Upcoming && currentCourse.currentEnrollments === 0;
  }
  return (
    <Stack spacing={6}>
      <Card>
        <CardHeader bg={canToogleActivation ? "green.500" : "red.500"} py={3}>
          <Heading size="sm" color="white">
            Status
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack spacing={4}>
            {currentCourse && (
              <>
                <Text fontSize={"small"}>A course can be deactived / re-activated only if : </Text>
                <List fontSize={"small"} spacing={2} ml={5}>
                  <ListItem>- The status is not completed or ongoing. </ListItem>
                  <ListItem>- There are no enrolled learners to the course. </ListItem>
                </List>
                <Divider />

                {canToogleActivation ? (
                  <>
                    <HStack spacing={5}>
                      <FormLabel htmlFor="isActive"> Is Active ? </FormLabel>
                      {isLoading ? <Spinner color="red.500" /> : <Switch id="isActive" colorScheme="purple" isChecked={currentCourse.isActive} onChange={handleToggleActive} />}
                    </HStack>

                    <ConfirmationDialog />
                  </>
                ) : (
                  <Alert status={canToogleActivation ? "success" : "error"}>
                    <AlertDescription>
                      This course status is <b>{currentCourse.status.toUpperCase()}</b> and there are <b>{currentCourse.currentEnrollments}</b> enrollments to this course.
                    </AlertDescription>
                  </Alert>
                )}
              </>
            )}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default StatusSection;
