import PrivacyTermsSection from "@/components/public/PrivacyTermsSection";

 
const PrivacyTerms: React.FC = () => {
  return (
    <>
    <PrivacyTermsSection/>
   
    </>
  );
};

export default PrivacyTerms;
