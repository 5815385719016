import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  
  Select,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { Trophy, Book } from "lucide-react";
import { UseFormRegister, FieldErrors, Control } from "react-hook-form";
import { ICourseType } from "@/features/admin/course/adminCourseTypes";
import { CourseFormData } from "../courseFormConstants";
import { formMode } from "@/types/globalTypes";
import { ControlledNumberInput } from "@/components/common/ReusableControlledNumberInput";

interface PrerequisitesSectionProps {
  register: UseFormRegister<CourseFormData>;
  control: Control<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
  courseTypes: ICourseType[];
  currentTypeId?: string;
}

const PrerequisitesSection: React.FC<PrerequisitesSectionProps> = ({ register, errors, mode, courseTypes, currentTypeId, control }) => {
  return (
    <Card>
      <CardHeader bg="cyan.500" py={3}>
        <Heading size="sm" color="white">
          Prerequisites
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.prerequisites?.levelTestScore}>
            <FormLabel>Required Level Test Score</FormLabel>

            <ControlledNumberInput name="prerequisites.levelTestScore" control={control} min={1} isReadOnly={mode === formMode.VIEW} leftIcon={Trophy} />

            <FormErrorMessage>{errors.prerequisites?.levelTestScore?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.prerequisites?.requiredCourseTypeId}>
            <FormLabel>Required Previous Course</FormLabel>
            <Select {...register("prerequisites.requiredCourseTypeId")} icon={<Book />} isReadOnly={mode === formMode.VIEW}>
              <option value="">No prerequisite course required</option>
              {courseTypes
                .filter((type) => type.id !== currentTypeId)
                .map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{errors.prerequisites?.requiredCourseTypeId?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default PrerequisitesSection;
