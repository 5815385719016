//import ImageCardSimple from "@/components/common/ImageCardSimple";
//import CoursePlatform from "@/components/public/CoursePlatform";
 
import PricingSection from "@/components/public/Pricing";

import CoursePlatform from "@/components/public/CoursePlatform";

import { Box } from "@chakra-ui/react";
import React from "react";
import CourseDetailsSection from "@/components/public/CourseDetailsSection";
import FAQSection from "@/components/public/FAQSection";

const CourseDetails: React.FC = () => {
  return (
    <>
      <CourseDetailsSection />

      <Box bgGradient="linear(to-r, #FF3366, #F687B3)" p={8}>
        <CoursePlatform />
      </Box>
      <PricingSection />
      <FAQSection/>
    </>
  );
};

export default CourseDetails;
