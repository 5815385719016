import {
  Container,
  Flex,
  Heading,
  Spacer,
  VStack,
  Image,
} from "@chakra-ui/react";
import ProcessStepper from "./ProcessStepper";

import { howitworks_data } from "@/constants/dataPublicPages";

const CoursePlatform: React.FC = () => {
  const aspects = howitworks_data.aspects;

  return (
    <Container maxW="container.xl" centerContent mb={10} color="white">
      
      <Flex wrap="wrap">
        <VStack
          align="flex-start"
          spacing={6}
          maxW={{ base: "100%", lg: "45%" }}
          mt={{ base: 12, lg: 0 }}
        >
          <Heading as="h2" size="xl">
            {aspects[0].title}
          </Heading>
          <ProcessStepper />
        </VStack>
        <Spacer />
        <VStack
          align="flex-start"
          spacing={6}
          maxW={{ base: "100%", lg: "45%" }}
          mt={{ base: 12, lg: 0 }}
          color="white"
        >
          <Image
            src={aspects[0].imageSrc}
            alt="Platform"
            borderRadius="lg"
            objectFit="cover"
            w="100%"
          />
        </VStack>
      </Flex>
    </Container>
  );
};

export default CoursePlatform;
