import GradientWaveSection from "@/components/common/GradientWaveSection";
import HeroAbout from "@/components/public/HeroAbout";
import MissionVisionSection from "@/components/public/MissionVisionSection";
import OurWay from "@/components/public/OurWay";
import StudentStories from "@/components/public/StudentStories";
import TeachersSection from "@/components/public/Teachers";
import WithSpeechBubbles from "@/components/public/Testimonials";
import WhyUs from "@/components/public/WhyUs";
import React from "react";

const About: React.FC = () => (
  <>
    <HeroAbout />
    <MissionVisionSection />
    <TeachersSection />
    <WithSpeechBubbles />
    <GradientWaveSection>
      <OurWay color="white" />
    </GradientWaveSection>
    <WhyUs />
    <StudentStories />
  </>
);

export default About;
