import AnimatedCard from "@/components/common/AnimatedCard";
import InfoCard from "@/components/common/InfoCard";
import ReusableTable from "@/components/common/ReusableTable/ReusableTable";
import { getCoachList } from "@/features/admin/coaches/adminCoachApi";
import { ICoachUser } from "@/features/admin/coaches/adminCoachTypes";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
import { CardHeader, CardBody, Text, VStack, useToast } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { fetchData_ReusableTable } from "@/utils/helpers";
import ReusableModal from "@/components/common/ReusableModal";
import { clearCoachUser, clearError, setCurrentCoach } from "@/features/admin/coaches/adminCoachSlice";
import CoachForm, { CoachFormData } from "./CoachForm";
import { createCoachThunk, updateCoachThunk } from "@/features/admin/coaches/adminCoachActions";

const CoachList: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState(formMode.VIEW);

  const { currentCoach, error } = useAppSelector((state) => state.adminCouch);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occured !",
        description: error as string,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const columns = [
    { accessorKey: "userId", header: "User Id" },
    { accessorKey: "user.username", header: "Coach User Name" },
    { accessorKey: "user.email", header: "Coach e-mail" },

    {
      accessorKey: "isActive",
      header: "isActive",
      cell: (context: CellContext<ICoachUser, unknown>) => {
        const cellValue = context.getValue();
        return cellValue === true ? (
          <Text as="b" color={"red.600"}>
            {" "}
            ACTIVE
          </Text>
        ) : (
          "Passive"
        );
      },
    },
    {
      accessorKey: "isNative",
      header: "isNative",
      cell: (context: CellContext<ICoachUser, unknown>) => {
        const cellValue = context.getValue();
        return cellValue === true ? "YES" : "NO";
      },
    },
    {
      accessorKey: "isZoomOnly",
      header: "Zoom Only Coach",
      cell: (context: CellContext<ICoachUser, unknown>) => {
        const cellValue = context.getValue();
        return cellValue === true ? "Zoom Only Coach" : "Permanent";
      },
    },
  ];

  const handleCreateCoach = () => {
    dispatch(clearError());
    dispatch(setCurrentCoach(null));
    setMode(formMode.CREATE);
    setShowModal(true);
  };

  const handleViewCoach = (coach : ICoachUser) => { 
    dispatch(clearError());
    dispatch(setCurrentCoach(coach));
    setMode(formMode.VIEW);
    setShowModal(true);

   }
  const handleEditCoach = (coach: ICoachUser) => {
    dispatch(clearError());
    dispatch(setCurrentCoach(coach));
    setMode(formMode.EDIT);
    setShowModal(true);
  };

  const onFormModalSubmit = async (data: CoachFormData) => {
    switch (mode) {
      case formMode.VIEW:
        //do nothing
        break;
      case formMode.CREATE:
        await dispatch(createCoachThunk({ ...data, id: "" }));
        setRefreshTrigger((prev) => prev + 1);
        break;
      case formMode.EDIT:
        if (currentCoach) {
          dispatch(updateCoachThunk({ ...data, id: currentCoach.id }));
          setRefreshTrigger((prev) => prev + 1);
        }
        break;
    }

    setShowModal(false);
  };

  const onModalClose = () => {
    setShowModal(false);
    //if modal closes without submit , clear the slice state
    dispatch(clearCoachUser());
  };

  return (
    <AnimatedCard>
      <CardHeader bg="pink.400" color="white" p={3}>
        <Text>Coach List</Text>
      </CardHeader>
      <CardBody>
        <InfoCard title={`Coaches List`} image="/assets/infocards/LevelTest.png">
          <VStack></VStack>
          <Text mt={2}>Use the coach list to add new coaches or modify couches</Text>
        </InfoCard>
      </CardBody>
      <ReusableTable
        key={refreshTrigger} // just to trigger a refetch using state
        columns={columns}
        fetchData={useCallback((options: IFetchDataOptions) => fetchData_ReusableTable<ICoachUser>(options, getCoachList), [])}
        initialPageSize={10}
        tableTitle="Coach List"
        onCreate={handleCreateCoach}
        onView={handleViewCoach}
        onEdit={handleEditCoach}
      />
      <ReusableModal isOpen={showModal} headerBg="purple.500" title={mode === formMode.CREATE ? "Create New Coach" : "Edit Coach"} onClose={onModalClose}>
        <CoachForm mode={mode} onClose={(data: CoachFormData) => onFormModalSubmit(data)} />
      </ReusableModal>
    </AnimatedCard>
  );
};

export default CoachList;
