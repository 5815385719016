import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  Link,
  useColorModeValue,
  Container,
  useBreakpointValue,
  Stack,
  StackDirection,
} from "@chakra-ui/react";

const CreatorSection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("navy", "white");

  const stackDirection = useBreakpointValue({
    base: "column",
    lg: "row",
  }) as StackDirection;
  const imageWidth = useBreakpointValue({ base: "100%", md: "400px" });
  const headingSize = useBreakpointValue({ base: "lg", md: "xl" });

  return (
    <Box bg={bgColor} p={8} borderRadius="lg" shadow="md" m={10}>
      <Container maxW="container.xl">
        <Stack direction={stackDirection} spacing={8} align="start">
          <Image
            src="/assets/others/creator.png"
            alt="Content creator filming"
            borderRadius="md"
            maxW={imageWidth}
            objectFit="cover"
          />
          <VStack align="stretch" spacing={4}>
            <Heading as="h1" size={headingSize} color={textColor}>
              Welcome to Fleunt Chatter's content creator programme
            </Heading>
            <Text color={textColor}>
              FluentChatter'da klasik online kursularin cok disinda , gercek
              hayat temalirinin islendigi dersler ve gercek ogretmenlerle pratik
              yapmak uzerine kurulu bir sistem kullaniyoruz.
            </Text>
            <Text color={textColor}>
              Hata yapmaktan korkan , fiziksel olarak iyi kursalara erisimi
              olamayan , yogun calisma hayati icinde kursalara gun icinde zaman
              ayirma sansi olmayan binlerce insana esnek bir zamanlama ile
              tumuyle online ama gercek insanlarla birarada pratik yapma imkani
              saglayarak ingilizce ogretiyoruz. Eger sizde bu cabamizi
              desteklemek ve takipcilerinize Fluent Chatter'i tanitmak
              isterseniz bize ulasin. Bizi YouTube, Instagram, TikTok, Facebook
              gibi platformlarda anlatin ve bize getireceginiz her ogrenci icin
              komisyon kazanin.
            </Text>
            <Text color={textColor}>Bize uilasmak icin lutfen yazin :</Text>
            <Link
              href="mailto:info@fluentchatter.com"
              color="blue.500"
              fontWeight="bold"
            >
              creator@fluentchatter.com
            </Link>
          </VStack>
        </Stack>
      </Container>
    </Box>
  );
};

export default CreatorSection;
