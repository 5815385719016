import React from "react";
import { Box, Text, Badge, HStack, Flex, CardHeader, Card, CardBody, CircularProgress, CircularProgressLabel, Divider, keyframes, usePrefersReducedMotion, useToast } from "@chakra-ui/react";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { useAppDispatch } from "@/hooks/useRedux";
import { useNavigate } from "react-router-dom";
import { getCourseById } from "@/features/admin/course/adminCourseApi";
import { clearError } from "@/features/auth/authSlice";
import { setCurrentCourse } from "@/features/admin/course/adminCourseSlice";
import { formMode } from "@/types/globalTypes";

interface upcomingCourses {
  count: number;
  courses: [
    {
      id: string;
      name: string;
      startDate: Date;
      daysLeft: number;
      enrolled: number;
      enrollmentPercentage: number;
    }
  ];
}
interface IncomingCoursesListProps {
  data: upcomingCourses;
}

const ScaleUpAnimation = keyframes`
  from { 
    opacity: 0;
    transform: scale(0.2);
  }
  to { 
    opacity: 1;
    transform: scale(1);
  }
`;

const IncomingCoursesList: React.FC<IncomingCoursesListProps> = ({ data }) => {

  const toast = useToast();
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();
  const handleCourseSelect = async (courseId: string) => {
    try {
      const response = await getCourseById(courseId);
      dispatch(clearError());
      dispatch(setCurrentCourse(response));
      navigate(`/admin/courses/courseform/${formMode.VIEW}/${courseId}`);
    } catch (error) {
      toast({
        title: "Error loading course data",
        description: error instanceof Error ? error.message : "Failed to load course data",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const courses = data.courses;

  //animation:
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${ScaleUpAnimation} 1s ease-in-out forwards`;

  return (
    <Card bg="white" borderRadius="lg" boxShadow="dark-lg" overflow="hidden">
      <CardHeader bg="purple.600" p={3}>
        <Flex align="center" justify="space-between">
          <Text color="white">Upcoming Courses</Text>
          <Badge bg="purple.200" color="purple.800" borderRadius="full" px={2} py={0.5}>
            {courses.length} COURSES
          </Badge>
        </Flex>
      </CardHeader>

      <CardBody p={0}>
        <Box h="100%" overflowY="auto">
          {courses.map((course, index) => {
           
            return (
              <Box key={course.id} p={3}>
                <Card
                  borderRadius="lg"
                  boxShadow="dark-lg"
                  overflow="hidden"
                  p={5}
                  _hover={{
                    backgroundColor: "purple.100",
                  }}
                  animation={animation}
                  sx={{
                    animationDelay: `${50 + index * 200}ms`, // Sequential delay based on index
                  }}
                  cursor={"pointer"}
                  onClick={() => handleCourseSelect(course.id)}
                >
                  <Text fontWeight="bold" fontSize="md" color="purple.700" mb={1}>
                    {course.name}
                  </Text>

                  <Divider borderColor={"gray.200"} />

                  <Flex justify="space-between" align="center" gap={4} height={"50px"}>
                    <HStack spacing={4} color="gray.600" fontSize="sm" mt={5}>
                      <HStack spacing={1}>
                        <FaCalendarAlt />
                        <Text>{new Date(course.startDate).toLocaleDateString()}</Text>
                      </HStack>

                      <Badge colorScheme={course.daysLeft <= 7 ? "red" : "purple"} variant="subtle" borderRadius="full" px={2} py={1} fontSize="xs" textTransform="uppercase">
                        {course.daysLeft} days left
                      </Badge>
                    </HStack>

                    <Divider orientation="vertical" mt={4} />

                    <HStack mt={5}>
                      <FaUsers />
                      <Text>{course.enrolled} enrolled</Text>
                      <CircularProgress value={course.enrolled} color="green.400" size="60px">
                        <CircularProgressLabel>{`${course.enrollmentPercentage}%`} </CircularProgressLabel>
                      </CircularProgress>
                    </HStack>
                  </Flex>
                </Card>
              </Box>
            );
          })}
        </Box>
      </CardBody>
    </Card>
  );
};

export default IncomingCoursesList;
