import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface GradientWaveSectionProps {
  children: ReactNode;
}

const GradientWaveSection: React.FC<GradientWaveSectionProps> = ({
  children,
}) => {
  return (
    <Box>
      <Box position="relative" overflow="hidden" minH={{base:'50px',md:'250px'}}>
        {/* Gradient Background */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgGradient="linear(to-r, #FF3366, #F687B3)" //  #FF6B3D
        /> 

        {/* Wave SVG */}
        <svg
          viewBox="0 0 1440 320"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "auto",
          }}
        >
          <path d="M0,160L48,144C96,128,192,96,288,90.7C384,85,480,107,576,128C672,149,768,171,864,165.3C960,160,1056,128,1152,117.3C1248,107,1344,117,1392,122.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z" />
        </svg>

        {/* Content can be added here */}
      </Box>
      <Box pb={10}
        //border="2px solid blue"
        bgGradient="linear(to-r, #FF3366, #F687B3)"
        minHeight="300px"
      >
        {children}
      </Box>
    </Box>
  );
};

export default GradientWaveSection;
