import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      html, body {
        margin: 0;
        padding: 0;
        height: 100%;
      }
      #root {
        min-height: 100%;
      }
    `}
  />
);

export default GlobalStyles;