import { extendTheme } from "@chakra-ui/react";
import "@fontsource/roboto";
import '@fontsource/dancing-script'

const theme = extendTheme({
  breakpoints: {
    sm: "30em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "80em", // 1280px
  },

  /* fonts: {
    heading: '"Dancing Script"',
    body: 'Roboto, sans-serif',
  }, */
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
  /*fonts: {
    heading: '"Avenir Next", sans-serif',
    body: '"Open Sans", sans-serif',
  },*/
  // Add more customizations as needed
});

export default theme;
