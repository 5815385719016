import { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Heading, 
  Text, 
  VStack, 
  HStack, 
  Icon, 
 
  Table,
  Tbody,
  Tr,
  Td,
  Badge,
  Divider,
  Tooltip
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Shield, LogIn, Home, AlertTriangle, Lock } from 'lucide-react';
import api from '@/utils/api';

interface SecurityInfo {
  ipAddress: string;
  timestamp: string;
  requestPath: string;
  method: string;
  protocol: string;
  secure: boolean;
  userAgent: string;
}
 

const Unauthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [securityInfo, setSecurityInfo] = useState<SecurityInfo | null>(null);
  
  // Client-side device detection
  const browser = navigator.userAgent;
  const deviceType = /Mobile|Android|iPhone/i.test(browser) ? 'Mobile Device' : 'Desktop';
  const os = navigator.platform;
  
  // Colors
  const bgGradient = "linear(to-r, red.600, red.700)";
  const textColor =  'gray.700' 
  const tableBg = 'red.50'
  
  useEffect(() => {
    const logUnauthorizedAccess = async () => {
      try {
        const response = await api.get('/auth/log-unauthorized-attempt');
        setSecurityInfo(response.data);
      } catch (error) {
        console.error('Failed to log unauthorized access:', error);
        // Set fallback data if the request fails
        setSecurityInfo({
          ipAddress: 'IP Logged',
          timestamp: new Date().toISOString(),
          requestPath: location.pathname,
          method: 'GET',
          protocol: window.location.protocol.replace(':', ''),
          secure: window.location.protocol === 'https:',
          userAgent: navigator.userAgent
        });
      }
    };

    logUnauthorizedAccess();
  }, [location.pathname]);

  return (
    <Container maxW="container.xl" h="100vh" centerContent>
      <VStack 
        spacing={6} 
        justify="center" 
        align="center" 
        h="full"
        px={4}
      >
        {/* Warning Icon and Status Code */}
        <Box position="relative" w="full" maxW="lg" textAlign="center">
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Icon as={AlertTriangle} boxSize={8} color="red.600" />
              <Icon as={Lock} boxSize={8} color="red.600" />
            </HStack>
            <Heading
              fontSize={{ base: '3xl', md: '4xl' }}
              fontWeight="bold"
              color="red.600"
            >
              Security Alert: Access Denied
            </Heading>
          </VStack>
        </Box>

        {/* Access Denied Message */}
        <Text
          fontSize="lg"
          color={textColor}
          textAlign="center"
          fontWeight="medium"
          maxW="lg"
        >
          This security incident has been logged and reported to system administrators.
        </Text>

        {/* Security Information Table */}
        <Box 
          w="full" 
          maxW="lg" 
          bg={tableBg} 
          borderRadius="md" 
          p={4}
          borderWidth="1px"
          borderColor="red.200"
          boxShadow="sm"
        >
          <VStack align="stretch" spacing={3}>
            <HStack>
              <Icon as={Shield} color="red.500" />
              <Text fontWeight="bold" color={textColor}>Security Incident Details:</Text>
            </HStack>
            <Table size="sm" variant="simple">
              <Tbody>
                <Tr>
                  <Td fontWeight="medium">Time of Incident:</Td>
                  <Td>
                    {securityInfo?.timestamp ? new Date(securityInfo.timestamp).toLocaleString() : 'Loading...'}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="medium">IP Address:</Td>
                  <Td>
                    <Badge colorScheme="red">{securityInfo?.ipAddress || 'Loading...'}</Badge>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="medium">Device Info:</Td>
                  <Td>{deviceType} ({os})</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="medium">Connection:</Td>
                  <Td>
                    <Tooltip label={`Protocol: ${securityInfo?.protocol?.toUpperCase() || 'N/A'}`}>
                      <Badge colorScheme={securityInfo?.secure ? "green" : "yellow"}>
                        {securityInfo?.secure ? "Secure" : "Insecure"} Connection
                      </Badge>
                    </Tooltip>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="medium">Attempted Access:</Td>
                  <Td color="red.500">
                    <Text noOfLines={1}>
                      {securityInfo?.requestPath || location.pathname}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="medium">Request Method:</Td>
                  <Td>
                    <Badge colorScheme="purple">
                      {securityInfo?.method || 'GET'}
                    </Badge>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </VStack>
        </Box>

        <Divider />

        {/* Action Buttons */}
        <HStack spacing={4} pt={2}>
          <Button
            size="lg"
            bgGradient={bgGradient}
            color="white"
            leftIcon={<LogIn size={20} />}
            _hover={{
              bgGradient: "linear(to-r, red.700, red.800)",
              transform: "translateY(-2px)",
              boxShadow: "lg"
            }}
            onClick={() => navigate('/auth/login')}
            transition="all 0.2s"
          >
            Sign In
          </Button>
          <Button
            size="lg"
            variant="outline"
            colorScheme="red"
            leftIcon={<Home size={20} />}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg"
            }}
            onClick={() => navigate('/')}
            transition="all 0.2s"
          >
            Home
          </Button>
        </HStack>

        {/* Warning Footer */}
        <Text
          fontSize="sm"
          color="red.500"
          textAlign="center"
          fontWeight="medium"
        >
          Multiple unauthorized access attempts may result in IP blocking and account suspension.
        </Text>
      </VStack>
    </Container>
  );
};

export default Unauthorized;