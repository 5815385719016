import React from "react";
import { useNavigate } from "react-router-dom";
import {
 
  Heading,
  Input,
  Button,
  Text,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Alert,
  AlertIcon,
  HStack,
  Card,
  CardBody,
  CardHeader,
  Divider,
  CardFooter,
  VStack,
 
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

import {
  verifyEmail,
  resendVerificationCode,
} from "@/features/auth/authActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UnlockIcon } from "@chakra-ui/icons";

// Define the validation schema
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  verificationCode: yup
    .string()
    .required("Verification code is required")
    .min(6, "Code must be at least 6 characters"),
});

type FormData = yup.InferType<typeof schema>;

const EmailVerification: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  //get auth slice state from redux
  const { isLoading, error, registrationMessage, registeredEmail } =
    useAppSelector((state) => state.auth);

  //init react hook form :
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      await dispatch(verifyEmail(data)).unwrap();
      toast({
        title: "Email Verified",
        description: "Your email has been successfully verified.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/auth/login");
    } catch (error) {
      toast({
        title: "Email Verification failed !",
        description: error as string,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleResendCode = async () => {
    try {
      

      await dispatch(resendVerificationCode(registeredEmail!)).unwrap();
      toast({
        title: "Code Resent",
        description: "A new verification code has been sent to your email.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Sending new verification code failed !",
        description: error as string,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Card
        maxWidth={["90vw", "500px"]}
        margin="auto"
        mt={[10, 20]}
        mb={[10, 20]}
      >
        <CardHeader bg={"pink.500"} color={"white"}>
          <HStack spacing={"20"}>
            <UnlockIcon boxSize={8} color={"white"} />
            <Heading>Email Verification</Heading>
          </HStack>
        </CardHeader>
        <Divider color={"gray.200"} />
        <CardBody>
          <Alert status="success"  mb={5}>
            <AlertIcon />
            {registrationMessage}
          </Alert>
          <VStack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="email" isInvalid={!!errors.email} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                placeholder="Enter your email"
                {...register("email")} // Register this input with react-hook-form
                disabled={isLoading} // If login action state is isloading disable the field
                value={registeredEmail || ""}
              />
              {/* Display error message if email is invalid */}
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.verificationCode} mb={4}>
              <FormLabel htmlFor="verificationCode">
                Verification Code
              </FormLabel>
              <Input
                id="verificationCode"
                placeholder="Enter 6-digit code"
                {...register("verificationCode")}
              />
              <FormErrorMessage>
                {errors.verificationCode && errors.verificationCode.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              colorScheme="pink"
              width="100%"
              type="submit"
              isLoading={isLoading}
              mb={4}
            >
              Verify Email
            </Button>
          </VStack>

          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </CardBody>
        <Divider color={"gray.200"} />
        <CardFooter justify={'center'}>
          <VStack>
          <Text textAlign="center" mb={4}>
            Didn't receive the code?
          </Text>
          <Button
            colorScheme="gray"
            width="100%"
            onClick={handleResendCode}
            isLoading={isLoading}
          >
            Resend Verification Code
          </Button>
          </VStack>
        </CardFooter>
      </Card>
    </>
  );
};

export default EmailVerification;
