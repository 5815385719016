import ImprintSection from "@/components/public/ImprintSection"; 
 
const Imprint: React.FC = () => {
  return (
    <>
    <ImprintSection/>
   
    </>
  );
};

export default Imprint;
