import  { useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

export const useConfirmDialog = () => {
  // Modal open/close state
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Store the question text with  a ref, so we dont loose it between renders
  const questionRef = useRef("");
  // Store the function to resolve the promise , so we dont loose it between renders
  const resolveRef = useRef<((value: boolean) => void) | null>(null);
  
  // 4. Function to show the dialog
  const confirm = (question: string) => {
    // Store the question
    questionRef.current = question;
    // Open the modal
    onOpen();
    // Return a promise that resolves when user clicks a button
    return new Promise<boolean>((resolve) => {
      //save the resolve function to use on yes/no clicked later  
      resolveRef.current = resolve;
    });
  };
  
  // 5. Handle Yes button click
  const handleYes = () => {
    resolveRef.current?.(true);  // Resolve the promise with true
    onClose();                   // Close the modal
  };
  
  // 6. Handle No button click
  const handleNo = () => {
    resolveRef.current?.(false); // Resolve the promise with false
    onClose();                   // Close the modal
  };
  
  // 7. The actual modal component
  const ConfirmationDialog = () => (
    <Modal 
      isOpen={isOpen} 
      onClose={handleNo}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          {questionRef.current}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleYes}>
            Yes
          </Button>
          <Button variant="ghost" onClick={handleNo}>
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return { confirm, ConfirmationDialog };
};