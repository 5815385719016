// src/features/admin/levelTest/adminLevelTestSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminLevelTestState, LevelTest, Question } from './adminLevelTestTypes';

const initialState: AdminLevelTestState = {
 
  currentTest: null,
  questions: [],
  isLoading: false,
  error: null,
};

const adminLevelTestSlice = createSlice({
  name: 'adminLevelTest',
  initialState,
  reducers: {
     
    setCurrentTest: (state, action: PayloadAction<LevelTest>) => {
      state.currentTest = action.payload;
    },
    setQuestions: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload;
    },
    addQuestion: (state, action: PayloadAction<Question>) => {
      state.questions.push(action.payload);
    },
    updateQuestion: (state, action: PayloadAction<Question>) => {
      const index = state.questions.findIndex(q => q.id === action.payload.id);
      if (index !== -1) {
        state.questions[index] = action.payload;
      }
    },
    removeQuestion: (state, action: PayloadAction<string>) => {
      state.questions = state.questions.filter(q => q.id !== action.payload);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  
  setCurrentTest,
  setQuestions,
  addQuestion,
  updateQuestion,
  removeQuestion,
  setLoading,
  setError,
  clearError
} = adminLevelTestSlice.actions;

export default adminLevelTestSlice.reducer;