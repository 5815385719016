import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Button, ButtonProps, Link as ChakraLink } from "@chakra-ui/react";

interface Props extends Omit<ButtonProps, 'as'>  {
  text: string;
  colorScheme?: string;
  size?: string;
  variant?: string;
  linkTo: string;
};

const ButtonLink = ({
  text,
  linkTo,
  colorScheme = "pink",
  size = "lg",
  variant = "solid",
  ...props
}: Props) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate(linkTo);
    window.scrollTo(0, 0);
  };

  return (
    <ChakraLink as={RouterLink} to={linkTo} style={{ textDecoration: "none" }}>
      <Button
        onClick={handleClick}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...props}
      >
        {text}
      </Button>
    </ChakraLink>
  );
};

export default ButtonLink;
