import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";

// Define the prop types for FeatureCard
interface FeatureCardProps {
  title: string;
  description: string;
  imageSrc: string;
}
const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, imageSrc }) => (
    <Box borderWidth="1px" borderRadius="lg" p={4} bg='white'>
      <Image src={imageSrc} alt={title} mb={4} />
      <Heading size="md" mb={2}>{title}</Heading>
      <Text>{description}</Text>
    </Box>
  );
  
export default FeatureCard;
