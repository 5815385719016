import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import { heroHowItWorksData } from "@/constants/dataPublicPages";

// Import the images
import mobileBackground from "/assets/hero-mobile.jpg";
import desktopBackground from "/assets/hero-desktop_howitworks.webp";

const Hero: React.FC = () => {
  const headingSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const textSize = useBreakpointValue({ base: "md", md: "xl" });
  const contentWidth = useBreakpointValue({ base: "100%", md: "40%" });

  // Responsive subheading text
  const subheadingText = useBreakpointValue({
    base: heroHowItWorksData.subheadingText_mobile,
    md: heroHowItWorksData.subheadingText_desktop
  });

  return (
    <Box
      position="relative"
      height={{ base: "50vh", md: "70vh" }}
      bg="gray.100"
      backgroundImage={{
        base: `url(${mobileBackground})`,
        md: `url(${desktopBackground})`,
      }}
      backgroundSize="cover"
      backgroundAttachment="fixed"
      backgroundRepeat="no-repeat"
    >
      <Container maxW="container.2xl" height="100%">
        <Flex
          height="100%"
          width="100%"
          justify="start"
          align="start"
          direction={{ base: "column", md: "row" }}
          pt={{ base: 8, md: 0 }}
          pb={{ base: 8, md: 0 }}
        >
          <VStack  
            mt={{ base: 0, md: 20 }}
            ml={{ base: 0, md: 10 }}
            align={{ base: "center", md: "flex-start" }}
            spacing={4}
            width={contentWidth}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              as="h2"
              size={headingSize}
              colorScheme="purple"
              color="purple.700"
            >
             {heroHowItWorksData.headingText}
            </Heading>
            <Text fontSize={textSize} colorScheme="pink" color="pink.600">
              {subheadingText}
            </Text>
            <Flex width="100%" justify="start">
              <Button colorScheme="pink" size="lg">
                Katıl Bize !
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Hero;
