import React from "react";
import {
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Flex,
  List,
  ListItem,
  ListIcon,
  Card,
  Divider,
  Badge,
} from "@chakra-ui/react";
import AnimatedCard from "@/components/common/AnimatedCard";
import { useAppSelector } from "@/hooks/useRedux";
import ButtonLink from "@/components/common/ButtonLink";
import {
  MdOutlineRadioButtonChecked,
  MdCheckCircle,
  MdOutlineError,
  MdFlagCircle,
  MdAccessTimeFilled,
  MdAccessTime,
} from "react-icons/md";

const LevelTestResult: React.FC = () => {
  let testDuration: string = '';
  let start: Date = new Date();
  let end: Date = new Date();

  const bgColor = useColorModeValue("white", "gray.800");
  

  const { testResult } = useAppSelector(
    (state) => state.levelTest
  );

  const MAX_SCORE = (testResult?.answers.length as number * 10) * (100 - 0);

  if (testResult?.startedAt && testResult?.completedAt) {
    start = new Date(testResult.startedAt);
    end = new Date(testResult.completedAt);
    const diffMins: number = end.getTime() - start.getTime();
    const testDuration_mins = Math.floor(diffMins / 60000);
    testDuration = `${testDuration_mins} minutes and ${ testDuration_mins % 60 } seconds`
  }

  return (
    <Flex
      id="margincollapse"
      minHeight={"100%"}
      backgroundImage="/assets/leveltest/backgroundInfoCard.jpg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      //bgGradient="linear(to-r, #FCE5FF, #F687B3)" //  #FF6B3D
      //bgColor={'pink.100'}

      justify="center"
      alignItems="center"
    >
      <AnimatedCard maxWidth="3xl" width="full">
        <VStack
          spacing={6}
          align="stretch"
          p={6}
          bg={bgColor}
          borderRadius="lg"
          boxShadow="xl"
        >
          <Heading size="lg" textAlign="center" color="green.500">
            Level Test Completed , Here your test result :
          </Heading>
          <Divider />
          <Card align={"center"} p={4}>
            <List spacing={3} fontSize={"small"} color={"gray.500"}>
              <ListItem>
                <ListIcon as={MdFlagCircle} color="green.500" />
                The Level Test consist of total{" "}
                <b> {testResult?.answers.length} </b>
                questions.
              </ListItem>
              <ListItem>
                <ListIcon as={MdFlagCircle} color="green.500" />
                This level test calculates the score by giving{" "}
                <b>10 points to each </b>
                correct answer, <br /> then it applies a time adjustment to this
                score.
              </ListItem>
              <ListItem>
                <ListIcon as={MdFlagCircle} color="green.500" />
                Maximum Score is <b>{MAX_SCORE}</b> points for this test ,<br />{" "}
                for the case all answers are correct and test completed the test
                at minimum time.
              </ListItem>
            </List>
          </Card>
          <Card align={"center"} p={4}>
            <List spacing={3} color={"purple.500"}>
              <ListItem>
                <ListIcon as={MdAccessTime} color="blue.500" />
                Test started at <b>{start.toLocaleTimeString()}</b> completed at{" "}
                <b>{end.toLocaleTimeString()}</b>
              </ListItem>
              <ListItem>
                <ListIcon as={MdAccessTimeFilled} color="blue.500" />
                You have completed the test in <b>{testDuration}</b>
              </ListItem>
              <ListItem color="orange.500">
                <ListIcon as={MdOutlineRadioButtonChecked} />
                You have selected an option for{" "}
                <b>
                  {
                    testResult?.answers.filter((a) => a.selectedOptionId !== "")
                      .length
                  }
                  {" out of "}
                  {testResult?.answers.length}{" "}
                </b>
                questions.
              </ListItem>
              <ListItem color="green.500">
                <ListIcon as={MdCheckCircle} />
                You have answered{" "}
                <b>
                  {
                    testResult?.answers.filter(
                      (a) => a.selectedOptionId == a.correctOptionId
                    ).length
                  }{" "}
                  {" out of "}
                  {testResult?.answers.length}{" "}
                </b>
                questions correctly.
              </ListItem>
              <ListItem color={"red.500"}>
                <ListIcon as={MdOutlineError} />
                You have answered{" "}
                <b>
                  {
                    testResult?.answers.filter(
                      (a) => a.selectedOptionId !== a.correctOptionId
                    ).length
                  }{" "}
                  {" out of "}
                  {testResult?.answers.length}{" "}
                </b>
                questions wrong.
              </ListItem>
            </List>
          </Card>
          <Card p={7}>
            <Text
              fontSize="xl"
              textAlign="center"
              fontWeight="bold"
              color={'green.500'}
            >
              Your Score: {Math.floor(testResult?.score as number)} points. ( % {Math.round((testResult?.score as number  * 100)/ MAX_SCORE)} )
            </Text>
            <Text textAlign="center" color={'gray'}  fontSize="lg" mt={3}>
              Based on your performance, your estimated language level is:{" "}
              <Badge  fontSize="3xl" color={'green.600'}   fontWeight="bold" bg={'green.100'} p={2}> {testResult?.level} </Badge>
            </Text>
          </Card>

          <ButtonLink
            colorScheme="blue"
            variant="outline"
            linkTo="/learner"
            text="Go to Dashboard"
          />
        </VStack>
      </AnimatedCard>
    </Flex>
  );
};

export default LevelTestResult;
