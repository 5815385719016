import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from 'framer-motion';

const MotionModalContent = motion(ModalContent);

const logErrorToService = (error: Error, info: { componentStack: string }) => {
  // Implement your error logging logic here
  console.error("Logged error:", error, info);
};

const useErrorBoundary = () => {
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const errorHandler = (error: ErrorEvent) => {
      setError(error.error);
      logErrorToService(error.error, {
        componentStack: error.error.stack || "",
      });
    };

    window.addEventListener("error", errorHandler);

    return () => window.removeEventListener("error", errorHandler);
  }, []);

  return {
    error,
    resetError: () => setError(null),
  };
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const { error, resetError } = useErrorBoundary();

  if (error) {
    return (
      <AnimatePresence>
      <Modal isOpen={true} onClose={resetError} isCentered size='xl'>
        <ModalOverlay
          bg="blackAlpha.500" // This creates a semi-transparent overlay
          backdropFilter="blur(10px)" // This adds a blur effect to the background
          backgroundImage="url('/assets/errorboundary.jpeg')" // Replace with your image path
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        />
       <MotionModalContent
  bg="rgba(255, 255, 255, 0.8)"
  backdropFilter="blur(5px)"
  boxShadow="xl"
  initial={{ opacity: 0, y: -20 }}
  animate={{ opacity: 1, y: 0 }}
  exit={{ opacity: 0, y: 20 }}
  transition={{ duration: 0.6 }}
>
          <ModalHeader
            bg="rgba(229, 62, 62, 0.5)"
            color="white"
            borderTopRadius="md"
          >
            Unexpected Application Error!
          </ModalHeader>
          <ModalBody>
            <Text mb={4}>
              We're sorry, something went wrong. Please try again.
            </Text>
            
              <Box
                my={4}
                p={4}
                borderWidth={1}
                borderRadius="md"
                whiteSpace="pre-wrap"
                maxHeight="200px"
                overflowY="auto"
                bg="rgba(255, 255, 255, 0.6)"
              >
                <Text fontWeight="bold">{error.toString()}</Text>
                {process.env.NODE_ENV === "development" && (
                <Text mt={2}>{error.stack}</Text>)}
              </Box>
            
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={resetError} opacity={'0.6'}
   >
              Try Again
            </Button>
            <Button variant="ghost" onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </ModalFooter>
        </MotionModalContent>
      </Modal>
      </AnimatePresence>
    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
