import {
  Box,
  VStack,
  Heading,
  Text,
  Link,
  Container,
 
  Image,
  useBreakpointValue,
  StackDirection,
  Stack,
} from "@chakra-ui/react";

const ImprintSection = () => {
  const stackDirection = useBreakpointValue({
    base: "column",
    lg: "row",
  }) as StackDirection;

  return (
    <Box margin="auto" padding={8}>
      <Container maxW="container.xl">
        <Stack direction={stackDirection} spacing={8} align="start">
          <Image
            src="/assets/logobig.png"
            alt="logo"
            borderRadius="md"
            objectFit="cover"
          />

          <VStack spacing={6} align="stretch" mt={20}>
            <Heading as="h1" size="xl" mb={4} color={"purple.600"}>
              Legal
            </Heading>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Fluent Chatter Company
              </Heading>
              <Text fontSize="sm">some france address</Text>
              <Text fontSize="sm">10101 Paris</Text>
              <Text fontSize="sm">France</Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Contact
              </Heading>
              <Text fontSize="sm">
                Email:{" "}
                <Link href="mailto:info@fluentchatter.com" color="blue.500">
                  info@fluentchatter.com
                </Link>
              </Text>
              <Text fontSize="sm">Phone: +xx (0)x0 1234 5678</Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Management
              </Heading>
              <Text fontSize="sm">Managing Directors: Seda Buchet</Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Commercial Register
              </Heading>
              <Text fontSize="sm">Registered at binevenue-paris</Text>
              <Text fontSize="sm">Registration number: REG 987654</Text>
              <Text fontSize="sm">VAT ID: FR987654321</Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Online Dispute Resolution
              </Heading>
              <Text fontSize="sm">
                The European Commission provides a platform for online dispute
                resolution (OS):&nbsp;
                <Link
                  href="https://ec.europa.eu/consumers/odr"
                  isExternal
                  color="blue.500"
                >
                  https://ec.europa.eu/consumers/odr
                </Link>
              </Text>
              <Text fontSize="sm">
                Our email address can be found above in this imprint.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Liability for Content
              </Heading>
              <Text fontSize="sm">
                As a service provider, we are responsible for our own content on
                these pages in accordance with Article 6 of the Law for Trust in
                the Digital Economy (LCEN) and Article L. 111-1 of the Consumer
                Code. Pursuant to Articles 6-I-2 and subsequent of the LCEN, we
                are not obligated to monitor transmitted or stored third-party
                information or to investigate circumstances that indicate
                illegal activity.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Liability for Links
              </Heading>
              <Text fontSize="sm">
                Our offer contains links to external websites of third parties,
                on whose contents we have no influence. Therefore, we cannot
                assume any liability for these external contents. The respective
                provider or operator of the pages is always responsible for the
                content of the linked pages.
              </Text>
            </Box>
          </VStack>
        </Stack>
      </Container>
    </Box>
  );
};

export default ImprintSection;
