import React from 'react';
import { VStack, Button, Text, Divider, Tooltip, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaBook, FaEnvelope, FaUser, FaCog } from 'react-icons/fa';

interface LearnerSidebarProps {
  onClose?: () => void;
  isCollapsed?: boolean;
}

const LearnerSidebar: React.FC<LearnerSidebarProps> = ({ onClose, isCollapsed = false }) => {
  const menuItems = [
    { icon: FaHome, label: 'Dashboard', to: '/learner/' },
    { icon: FaBook, label: 'My Courses', to: '/learner/courses' },
    { icon: FaEnvelope, label: 'Messages', to: '/learner/messages' },
    { icon: FaUser, label: 'Profile', to: '/learner/profile' },
    { icon: FaCog, label: 'Settings', to: '/learner/settings' },
  ];

  return (
    <VStack spacing={4} align="stretch" p={4}>
      {!isCollapsed && (
        <>
          <Text fontSize="xl" fontWeight="bold" mb={2}>Menu</Text>
          <Divider />
        </>
      )}
      {menuItems.map((item) => (
        <Tooltip key={item.to} label={isCollapsed ? item.label : ''} placement="right">
          <Box>
            <Button
              colorScheme='pink'
              leftIcon={<item.icon />}
              as={RouterLink}
              to={item.to}
              onClick={onClose}
              justifyContent={isCollapsed ? 'center' : 'flex-start'}
              width="100%"
              px={isCollapsed ? 2 : 4}
            >
              {!isCollapsed && item.label}
            </Button>
          </Box>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default LearnerSidebar;