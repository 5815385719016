import GradientWaveSection from "@/components/common/GradientWaveSection";
import AnnouncementBar from "@/components/public/AnnouncementBar";
import EnglishLevelSection from "@/components/public/EnglishLevel";
import FAQSection from "@/components/public/FAQSection";

import Hero from "@/components/public/Hero";
import OurWay from "@/components/public/OurWay";
//import Pricing from "@/components/public/Pricing";
import SampleClassesSection from "@/components/public/SampleClasses";
import StudentStories from "@/components/public/StudentStories";
import TeachersSection from "@/components/public/Teachers";
import WithSpeechBubbles from "@/components/public/Testimonials";
import WhyUs from "@/components/public/WhyUs";
import { Text } from "@chakra-ui/react";
import React from "react";
 
import HowItWorksSection from "@/components/public/HowItWorks";
import Courses from "@/components/public/Courses";
import PricingSection from "@/components/public/Pricing";
 

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <AnnouncementBar />
      <WhyUs />
      <EnglishLevelSection/>  
      <HowItWorksSection/>
      <PricingSection/>
      <Courses />
      
      <StudentStories />
      <WithSpeechBubbles/>
     
      <GradientWaveSection>
        <OurWay color="white"/>
        <SampleClassesSection />
      </GradientWaveSection>
      <TeachersSection/>
     
      <FAQSection/>

      <Text>Home Page</Text>
    </>
  );
};

export default Home;
