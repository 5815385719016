// src/features/admin/levelTest/adminLevelTestApi.ts

import api from '@/utils/api';
import { LevelTest, Question, CreateLevelTestInput, CreateQuestionInput, UpdateQuestionInput } from './adminLevelTestTypes';

export const getAllLevelTests = async (): Promise<LevelTest[]> => {
  const response = await api.get('level-tests/all');
  return response.data;
};

export const createLevelTest = async (testData: CreateLevelTestInput): Promise<LevelTest> => {
  const response = await api.post('/level-tests', testData);
  return response.data;
};

export const toggleLevelTestActive = async (testId: string): Promise<LevelTest> => {
  const response = await api.put(`/level-tests/${testId}/toggle-active`);
  return response.data;
};

export const getQuestionsForTest = async (testId: string): Promise<Question[]> => {
  const response = await api.get(`/level-tests/${testId}/questions`);
  return response.data;
};

export const createQuestion = async (questionData: CreateQuestionInput): Promise<Question> => {
  const response = await api.post('/level-tests/questions', questionData);
  return response.data;
};

export const updateQuestionApi = async (id: string, questionData: UpdateQuestionInput): Promise<Question> => {
  const response = await api.put(`/level-tests/questions/${id}`, questionData);
  return response.data;
};

export const deleteQuestion = async (questionId: string): Promise<void> => {
  await api.delete(`/level-tests/questions/${questionId}`);
};