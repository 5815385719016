import api from "@/utils/api";
import { ICoach, ICoachUser } from "./adminCoachTypes";
import { IDefaultApiResponse } from "@/types/globalTypes";
import { IExtendedUser } from "@/features/users/userTypes";

export const getCoachList = async (isActive?: boolean): Promise<ICoachUser[]> => {
  const response = await api.get("coaches", { params: isActive ? { isActive: true } : {} });
  return response.data;
};

export const getUserwithEmail = async (email: string): Promise<IExtendedUser> => {
  const response = await api.get(`users/byemail/${email}`);
  return response.data;
};

export const createCoachApi = async (data: ICoach): Promise<IDefaultApiResponse> => {
  const response = await api.post("coaches", data);
  return response.data;
};

export const updateCoachApi = async (data: ICoach): Promise<IDefaultApiResponse> => {
  const response = await api.put(`coaches/${data.userId}`, data);
  return response.data;
};

export const uploadPhoto = async (formdata: FormData): Promise<{ success:boolean,error: string,message:string, url: string }> => {
  const response = await api.post("images/upload", formdata);
  return response.data;
};
