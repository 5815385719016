import AnimatedCard from "@/components/common/AnimatedCard";
import DataDisplayArea from "@/components/common/DataDisplayArea";
import { ImagedBackgroundFlex } from "@/components/common/ImagedBackgroundFlex";
import UserProfile from "@/components/common/UserProfile";
import { fecthUser } from "@/features/users/userActions";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { useEffect } from "react";

const ProfilePage = () => {
  const { user: authUser } = useAppSelector((state) => state.auth);
  const { isLoading, error, currentUser } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();

  //fetch user via redux thunk:
  useEffect(() => {
    if (authUser) {
      dispatch(fecthUser(authUser.id));
    }
  }, [dispatch, authUser]);

  return (
    <ImagedBackgroundFlex>
      <AnimatedCard mt={8} mb={8} maxW={["80%", "75%"]}>
        <DataDisplayArea isLoading={isLoading} error={error}>
          <UserProfile
            user={currentUser}
            showAdminFileds={authUser?.role === "admin"}
          />
        </DataDisplayArea>
      </AnimatedCard>
    </ImagedBackgroundFlex>
  );
};
export default ProfilePage;
