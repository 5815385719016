import CourseTypeList from "@/components/admin/courses/CourseTypeList";
import { Box } from "@chakra-ui/react";

const CourseTypePage: React.FC = () => {
  return (
    <Box p={5}>
      <CourseTypeList />
    </Box>
  );
};

export default CourseTypePage;
