import { Box, Text, VStack, HStack, Button, Icon } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";


interface PricingPlanProps {
  type: string;
  price: string;
  features: string[];
  color: string;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  type,
  price,
  features,
  color,
}) => (
  <Box
    bg="white"
    borderRadius="lg"
    boxShadow="md"
    overflow="hidden"
    position="relative"
    width="300px"
  >
    <Box
      bg={color}
      p={4}
      color="white"
      position="relative"
      _after={{
        content: '""',
        position: "absolute",
        bottom: "-20px",
        left: 0,
        right: 0,
        height: "40px",
        background: "white",
        transform: "skewY(-4deg)",
      }}
    >
      <Text fontWeight="bold" fontSize="xl">
        {type}
      </Text>
      <Text fontSize="3xl" fontWeight="bold">
        ${price}
      </Text>
    </Box>
    <VStack spacing={3} align="stretch" p={6} pt={8}>
      {features.map((feature, index) => (
        <HStack key={index}>
          <Icon
            as={feature.toString().includes("No") ? CloseIcon : CheckIcon}
            color={feature.toString().includes("No") ? "red.500" : "green.500"}
          />
          <Text fontSize="sm">{feature}</Text>
        </HStack>
      ))}
      <Button mt={4} colorScheme={color.split(".")[0]} size="sm">
        Paketi Sec
      </Button>
    </VStack>
 
  </Box>
);

export default PricingPlan;
