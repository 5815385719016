import React from "react";
import { Card, CardHeader, CardBody, FormControl, FormLabel, FormErrorMessage, Input, InputGroup, Stack, Heading } from "@chakra-ui/react";
import { Clock } from "lucide-react";
import { UseFormRegister, FieldErrors, Control } from "react-hook-form";
import { CourseFormData } from "../courseFormConstants";
import { formMode } from "@/types/globalTypes";
import { ControlledNumberInput } from "@/components/common/ReusableControlledNumberInput";

interface ScheduleSectionProps {
  register: UseFormRegister<CourseFormData>;
  control: Control<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
}

const ScheduleSection: React.FC<ScheduleSectionProps> = ({ register, errors, mode, control }) => {
  return (
    <Card>
      <CardHeader bg="orange.500" py={3}>
        <Heading size="sm" color="white">
          Schedule
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.startDate}>
            <FormLabel>Start Date</FormLabel>
            <Input {...register("startDate")} type="date" isReadOnly={mode === formMode.VIEW} />
            <FormErrorMessage>{errors.startDate?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.endDate}>
            <FormLabel>End Date</FormLabel>
            <Input
              {...register("endDate")} //
              type="date"
              isReadOnly={mode === formMode.VIEW}
            />
            <FormErrorMessage>{errors.endDate?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.moduleDuration}>
            <FormLabel>Module Duration (days)</FormLabel>
            <InputGroup>
              <ControlledNumberInput name="moduleDuration" control={control} min={1} isReadOnly={mode === formMode.VIEW} leftIcon={Clock} />
            </InputGroup>
            <FormErrorMessage>{errors.moduleDuration?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ScheduleSection;
