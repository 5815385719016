/*

A common issue with Chakra UI's NumberInput component : 
it doesn't directly support React Hook Form's register method.
To fix this, we need to use React Hook Form's Controller component to properly integrate with Chakra UI's NumberInput. 
But we since this is an input at the end , and used every form , we dont want repeat the same code
Therefore here is a resuable 'controlled' number input component

usage : pass the control coming from useForm hook  of recat hook form 

<ControlledNumberInput
      name="moduleDuration"
      control={control}
      min={1}
      ....
    />

*/

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { LucideIcon } from "lucide-react";

interface ControlledNumberInputProps<T extends FieldValues> extends Omit<NumberInputProps, "onChange" | "value"> {
  name: Path<T>;
  control: Control<T>;
  leftIcon?: LucideIcon;
  rightIcon?: LucideIcon;
  hideSteppers?: boolean;
}

export function ControlledNumberInput<T extends FieldValues>({ name, control, min, max, isReadOnly, leftIcon, rightIcon, hideSteppers, ...rest }: ControlledNumberInputProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <NumberInput value={value} onChange={(_, valueNumber) => onChange(valueNumber)} min={min} max={max} isReadOnly={isReadOnly} width="100%" {...rest}>
          <InputGroup>
            {leftIcon && (
              <InputLeftElement>
                <Icon as={leftIcon} />
              </InputLeftElement>
            )}

            <NumberInputField ref={ref} paddingLeft={leftIcon ? "40px" : undefined} paddingRight={rightIcon || !hideSteppers ? "40px" : undefined} />
            {!hideSteppers && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
            {rightIcon && (
              <InputRightElement>
                <Icon as={rightIcon} />
              </InputRightElement>
            )}
          </InputGroup>
        </NumberInput>
      )}
    />
  );
}
