 import ContentCreatorSection from '@/components/public/CreatorSection'
import HeroContentCreators from "@/components/public/HeroContentCreators";

const ContentCreators: React.FC = () => {
  return (
    <>
      <HeroContentCreators />
      <ContentCreatorSection />
    </>
  );
};

export default ContentCreators;
