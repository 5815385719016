import React from 'react';
import {
  Flex,
  IconButton,
  Text,
  Select,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

// Define the props for the TablePagination component
interface TablePaginationProps {
  pageIndex: number;
  pageSize: number;
  setPageIndex: (value: number | ((prevValue: number) => number)) => void;
  setPageSize: (size: number) => void;
  totalCount: number;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  totalCount,
}) => {
    
  // Calculate the total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <Flex justifyContent="space-between" m={2} alignItems="center">
      <Flex alignItems="center">
        {/* First page button */}
        <IconButton
          onClick={() => setPageIndex(0)}
          isDisabled={pageIndex === 0}
          icon={<ArrowLeftIcon h={3} w={3} />}
          size="sm"
          mr={1}
          aria-label="First Page"
        />
        {/* Previous page button */}
        <IconButton
          onClick={() => setPageIndex((old) => Math.max(old - 1, 0))}
          isDisabled={pageIndex === 0}
          icon={<ChevronLeftIcon h={4} w={4} />}
          size="sm"
          mr={1}
          aria-label="Previous Page"
        />
        {/* Page information */}
        <Text fontSize="xs" mx={2}>
          Page {pageIndex + 1} of {totalPages}
        </Text>
        {/* Next page button */}
        <IconButton
          onClick={() => setPageIndex((old) => Math.min(old + 1, totalPages - 1))}
          isDisabled={pageIndex === totalPages - 1}
          icon={<ChevronRightIcon h={4} w={4} />}
          size="sm"
          ml={1}
          aria-label="Next Page"
        />
        {/* Last page button */}
        <IconButton
          onClick={() => setPageIndex(totalPages - 1)}
          isDisabled={pageIndex === totalPages - 1}
          icon={<ArrowRightIcon h={3} w={3} />}
          size="sm"
          ml={1}
          aria-label="Last Page"
        />
      </Flex>
      {/* Page size selector */}
      <Select
        w={24}
        size="xs"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
          setPageIndex(0);
        }}
      >
        {[10, 20, 30, 40, 50].map((size) => (
          <option key={size} value={size}>
            Show {size}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default TablePagination;