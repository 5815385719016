//chakra imports
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Text,
  Link as ChakraLink,
  Box,
  Flex,
  Show,
 
} from "@chakra-ui/react";

//redux imports
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { login  } from "@/features/auth/authActions";
import { clearError } from "@/features/auth/authSlice";

//Form Validaion
//validation imports :
import { useForm } from "react-hook-form"; // Import useForm hook
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver
import * as yup from "yup"; // Import yup for schema validation
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthResponse } from "@/features/auth/authtypes";

// Define the shape of your form values
interface LoginFormInputs {
  email: string;
  password: string;
}

// Create a validation schema using Yup
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading, error, user, token } = useAppSelector(
    (state) => state.auth
  );
  const toast = useToast();
  const navigate = useNavigate();

  //only on first render , check if already logged in :

  //clear the error in store only for first render ( since the persistencey is on , we dont want to see the last error when first load)
  useEffect(() => {
    dispatch(clearError());
    if (token && user) {
      switch (user.role) {
        case "learner":
          navigate("/learner");
          break;
        case "admin":
          navigate("/admin");
          break;
        default:
          navigate("/");
      }
    }
  }, [dispatch, navigate, token, user]);

  // Initialize React Hook Form with Yup resolver
  const {
    register, // Function to register input with form
    handleSubmit, // Function to handle form submission
    formState: { errors, isSubmitting }, // Object containing form state information
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema), // Use yup for form validation
    mode: "onBlur", // This configures validation to run on blur
    criteriaMode: "all", // Shows all validation errors for a field
  });

  // Form submission handler
  const onLoginFormSubmit = async (data: LoginFormInputs) => {
    try {
      //dispatch login action ( thunk ) for redux auth slice
      const loginResponse: AuthResponse = await dispatch(
        login({ email: data.email, password: data.password })
      ).unwrap();
      //show the Toast as successfull
      toast({
        title: "Login successful",
        status: "success",
        duration: 3000,
        position: "bottom",
        isClosable: true,
      });
      switch (loginResponse.user.role) {
        case "learner":
          navigate("/learner");
          break;
        case "admin":
          navigate("/admin");
          break;
      }
    } catch (error) {
      // Error is handled by the reducer, so we don't need to do anything here
      toast({
        title: error as string,
        status: "error",
        duration: 3000,
        position: "bottom",
        isClosable: true,
      });
   
    }
  };

  return (
    <>
      <Box h={"80vh"}>
        <Flex height={"100%"}>
          <Show above="md">
            <Box
              bgColor={"purple"}
              bgImage="url('/assets/login2.jpeg')"
              bgSize={"cover"}
              flex="2"
            ></Box>
          </Show>
          <Box flex={1.2} overflowY={"auto"}>
            <Card
              maxWidth={["90vw", "100%"]}
              margin="auto"
              mt={[10, 0]}
              mb={[10, 0]}
              height={[null, "100%"]}
            >
              <CardHeader>
                <Heading size={"lg"} ml={8} mt={10}>
                  Login
                </Heading>
              </CardHeader>
              <Divider color={"gray.200"} />
              <CardBody>
                {/* Call HandleSubmit from react-hook-form useform hook, using onLoginFormSubmit handler */}
                <VStack
                  spacing={4}
                  m={10}
                  as="form"
                  onSubmit={handleSubmit(onLoginFormSubmit)}
                >
                  <FormControl id="email" isInvalid={!!errors.email} isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      id="email"
                      placeholder="Enter your email"
                      {...register("email")} // Register this input with react-hook-form
                      disabled={isLoading} // If login action state is isloading disable the field
                      size={"lg"}
                      variant={"flushed"}
                    />
                    {/* Display error message if email is invalid */}
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="password"
                    isInvalid={!!errors.password}
                    isRequired
                  >
                    <FormLabel>Password</FormLabel>
                    <Input
                      id="password"
                      type="password"
                      placeholder="Enter your password"
                      {...register("password")} // Register this input with react-hook-form
                      disabled={isLoading}
                      size={"lg"}
                      variant={"flushed"}
                    />
                    {/* Display error message if password is invalid */}
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  {/* Show error if loading action thunk retruned an error from reducer*/}
                  {error && (
                    <Alert status="error">
                      <AlertIcon />
                      {error}
                    </Alert>
                  )}
                  <Flex
                    width="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <ChakraLink as={ReactRouterLink}  
                      to="/auth/request-password-reset"
                      color={"red.600"}
                      width="48%"
                    >
                      Sifremi Unuttum ?
                    </ChakraLink>
                    <Button
                      type="submit"
                      colorScheme="pink"
                      width="48%"
                      isLoading={isLoading || isSubmitting} // Show loading state when form is submitting
                      loadingText="Logging in"
                      mt={4}
                    >
                      Login
                    </Button>
                  </Flex>
                  <Divider />
                  <Text>
                    Henuz kayitli degil misin ?{" "}
                    <ChakraLink as={ReactRouterLink}  to="/auth/register" color={"purple.600"} >
                      Buraya
                    </ChakraLink>{" "}
                    tiklayarak kaydol
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Login;
