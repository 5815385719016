import React from 'react';
import { 
  Card, CardHeader, CardBody, FormControl, FormLabel, 
  FormErrorMessage, Input, Textarea, Select, Stack, Heading 
} from '@chakra-ui/react';
import { Book } from 'lucide-react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { ICourseType } from '@/features/admin/course/adminCourseTypes'
import { CourseFormData } from '../courseFormConstants';
import { formMode } from '@/types/globalTypes';

interface BasicInfoProps {
  register: UseFormRegister<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  courseTypes: ICourseType[];
  mode: formMode;
}

const BasicInfoSection: React.FC<BasicInfoProps> = ({ 
  register, errors, courseTypes, mode 
}) => {
  return (
    <Card>
      <CardHeader bg="blue.500" py={3}>
        <Heading size="sm" color="white">Basic Information</Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Course Name</FormLabel>
            <Input {...register('name')} isReadOnly={mode === formMode.VIEW} />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.description}>
            <FormLabel>Description</FormLabel>
            <Textarea 
              {...register('description')} 
              rows={4}
              isReadOnly={mode === formMode.VIEW}
            />
            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.typeId}>
            <FormLabel>Course Type</FormLabel>
            <Select 
              {...register('typeId')}
              icon={<Book />}
              isReadOnly={mode === formMode.VIEW}
            >
              <option value="">Select Course Type</option>
              {courseTypes.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.typeId?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default BasicInfoSection;