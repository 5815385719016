import { Card, CardBody, Stack, Image, Heading  } from "@chakra-ui/react";

type Props = { title: string; image: string; children: React.ReactNode };

const InfoCard = ({ title, image, children , ...rest }: Props) => {
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      mt={3}
      mb={3}
      {...rest}
    >
      <Image
        objectFit="cover"
        maxW={{ base: "100%", sm: "200px" }}
        src={image}
        alt="Caffe Latte"
      />

      <Stack w={'full'}>
        <CardBody color={'gray.500'}>
          <Heading size="md" mb={2} color={'gray.600'}>{title}</Heading>

          {children}
        </CardBody>
      </Stack>
    </Card>
  );
};

export default InfoCard;
