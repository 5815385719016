import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Heading,
  Input,
} from "@chakra-ui/react";
import { Euro, Users } from "lucide-react";
import {  FieldErrors, Control } from "react-hook-form";
import { ICourse } from "@/features/admin/course/adminCourseTypes";
import { CourseFormData } from "../courseFormConstants";
import { formMode } from "@/types/globalTypes";
import { ControlledNumberInput } from "@/components/common/ReusableControlledNumberInput";

interface EnrollmentSectionProps {
   
  control: Control<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
  currentCourse?: ICourse | null;
}

const EnrollmentSection: React.FC<EnrollmentSectionProps> = ({  errors, mode, currentCourse, control }) => {
  return (
    <Card>
      <CardHeader bg="teal.500" py={3}>
        <Heading size="sm" color="white">
          Enrollment
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.price}>
            <FormLabel>Price</FormLabel>
            <ControlledNumberInput name="price" control={control} min={1} isReadOnly={mode === formMode.VIEW} leftIcon={Euro} />
            <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.maxEnrollments}>
            <FormLabel>Maximum Enrollments</FormLabel>
            <ControlledNumberInput name="maxEnrollments" control={control} min={1} isReadOnly={mode === formMode.VIEW} leftIcon={Users} />
            <FormErrorMessage>{errors.maxEnrollments?.message}</FormErrorMessage>
          </FormControl>

          {currentCourse && (
            <FormControl>
              <FormLabel>Current Enrollments</FormLabel>
              <Input value={currentCourse.currentEnrollments} isReadOnly bg="gray.50" />
            </FormControl>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default EnrollmentSection;
