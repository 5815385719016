import {
  Badge,
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

type Props = {
  icon: React.ReactElement | undefined;
  badgeNumber: number;
  children: React.ReactNode;
  iconColorScheme : string
  badgeColorScheme : string
};

const PopoverIconButton = ({ icon, badgeNumber, children,iconColorScheme,badgeColorScheme }: Props) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Box position="relative">
          <IconButton
            aria-label="Notifications"
            icon={icon}
            colorScheme={iconColorScheme}
            rounded="full"
          />
          {badgeNumber > 0 && (
            <Badge
              colorScheme={badgeColorScheme}
              borderRadius="full"
              position="absolute"
              top="-2px"
              right="-2px"
              fontSize="0.8em"
            >
              {badgeNumber}
            </Badge>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent>{children}</PopoverContent>
    </Popover>
  );
};

export default PopoverIconButton;
