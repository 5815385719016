import CourseDetailsSection from "@/components/public/CourseDetailsSection";
import CoursePlatform from "@/components/public/CoursePlatform";
import PricingSection from "@/components/public/Pricing";
import { Box } from "@chakra-ui/react";

import React from "react";

const Home: React.FC = () => {
  return (
    <>
      <PricingSection />
      <Box bgGradient="linear(to-r, #FF3366, #F687B3)" p={8}>
        <CoursePlatform />
      </Box>
      <CourseDetailsSection />
    </>
  );
};

export default Home;
