 
import {
  Flex,
  Heading,
  Input,
  Select,
  IconButton,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon, AddIcon } from '@chakra-ui/icons';
import { IReusableTableColumnDef } from './ReusableTable';

// Define the props for the TableHeader component
interface TableHeaderProps<T extends object> {
  tableTitle: string;
  enableSearch: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  searchColumn: string | undefined;
  setSearchColumn: (column: string | undefined) => void;
  handleSearch: () => void;
  columns: Array<IReusableTableColumnDef<T>>;
  onCreate?: () => void;
}

function  TableHeader<T extends object> ( {
  tableTitle,
  enableSearch,
  searchText,
  setSearchText,
  searchColumn,
  setSearchColumn,
  handleSearch,
  columns,
  onCreate,
} : TableHeaderProps<T>) {
  // Use Chakra UI's color mode values for consistent theming
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Flex p={2} bg={bgColor} borderBottomWidth={1} borderColor={borderColor} justifyContent="space-between" alignItems="center">
      {/* Table title */}
      <Heading size="md">{tableTitle}</Heading>
      
      {/* Search and create functionality */}
      <Flex width="50%" justifyContent="flex-end" alignItems="center">
        {enableSearch && (
          <>
            {/* Search input field */}
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              mr={2}
              size="sm"
              width="66%"
            />
            {/* Column selector for search */}
            <Select
              value={searchColumn || ''}
              onChange={(e) => setSearchColumn(e.target.value || undefined)}
              mr={2}
              size="sm"
              width="25%"
            >
              <option value="">All Columns</option>
              {columns.map((col) => (
                !col.notSearchable  && 
                <option key={col.accessorKey} value={col.accessorKey}>
                  {col.header}
                </option>
              ))}
            </Select>
            {/* Search button */}
            <IconButton
              aria-label="Search"
              icon={<SearchIcon />}
              onClick={handleSearch}
              size="sm"
              mr={2}
            />
          </>
        )}
        {/* Create new item button */}
        {onCreate && (
          <Tooltip label="Create new item">
            <IconButton
              aria-label="Create new item"
              icon={<AddIcon />}
              size="sm"
              variant="outline"
              colorScheme="green"
              onClick={onCreate}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default TableHeader;