import { createAsyncThunk } from "@reduxjs/toolkit";
import { IExtendedUser } from "./userTypes";
import api from "@/utils/api";

import { AxiosError } from "axios";

// action thunk for fecting user :
export const fecthUser = createAsyncThunk(
  "user/fecthUser",
  async (userId: string, { rejectWithValue }) => {
    try {
      // Api Call to fetxh the user :
      const response = await api.get<IExtendedUser>("/users/" + userId);

      // Return the fecthed user
      return response.data;
    } catch (error) {
      //Handle errors, differentiating between Axios errors and others
      if (error instanceof AxiosError && error.response) {
        // If it's an Axios error with a response, return the error message from the response
        return rejectWithValue(
          error.response.data.message || "Failed to fetch user"
        );
      }
      // For other types of errors, return a generic error message
      return rejectWithValue(
        "An unexpected error occurred while fetching the user"
      );
    }
  }
);
