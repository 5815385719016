import ActiveCoursesUserCountsChart from "@/components/admin/charts/ActiveCoursesUserCountsChart";
import IncomeByMonthChart from "@/components/admin/charts/IncomeByMonthChart";
import NewUserCountChart from "@/components/admin/charts/NewUserCountChart";
import SoldCourseCountsChart from "@/components/admin/charts/SoldCourseCountsChart";
import UserDistributionChart from "@/components/admin/charts/UserDistributionChart";
 
import { Box, SimpleGrid } from "@chakra-ui/react";

const StatisticPage = () => {
  return (
    <Box>
       
      
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={6}>
        <UserDistributionChart />
        <ActiveCoursesUserCountsChart />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={6}>
        <SoldCourseCountsChart />
        <NewUserCountChart />
      </SimpleGrid>
      <Box mb={6}>
        <IncomeByMonthChart />
      </Box>
    </Box>
  );
};

export default StatisticPage;
