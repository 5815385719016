import LevelTest_Edit from '@/components/admin/leveltest/LevelTest_Edit';
import { useParams } from 'react-router-dom';
 

const EditLevelTestPage: React.FC = () => {
    const { testId } = useParams();
    return (
      <LevelTest_Edit testId={testId!}/>
      
    );
  };

export default EditLevelTestPage