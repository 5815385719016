import React from 'react';
import { Spinner, Text, VStack } from '@chakra-ui/react';
import OverlayBox from './OverlayBox';

interface DataLoadingOverlayProps {
  isLoading: boolean;
}

const DataLoadingOverlay: React.FC<DataLoadingOverlayProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <OverlayBox>
      <VStack spacing={4}>
        <Spinner 
          thickness="4px"
          speed="0.65s"
          emptyColor="pink.100"
          color="pink.500"
          size="xl"
        />
        <Text fontWeight="bold" color="pink.500">Loading...</Text>
      </VStack>
    </OverlayBox>
  );
};

export default DataLoadingOverlay;